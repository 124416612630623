import { createSlice } from '@reduxjs/toolkit';
import { reject, sortBy } from 'lodash';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isUsersLoading: false,
  error: false,
  users: [],
  singleUser: {},
  isEditMode: false,
  permissions: [],
  initialData: {
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    roles: []
  },
  countries: [],
  groups: [],
  dataRestrictions: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    startUsersLoading(state) {
      state.isUsersLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.users = action.payload;
      state.isUsersLoading = false;
      state.isEditMode = false;
    },

    // GET User
    getEditUserSuccess(state, action) {
      state.singleUser = action.payload;
      state.isEditMode = true;
    },

    // GET USER PERMISSIONS
    getUserPermissionsSuccess(state, action) {
      state.permissions = sortBy(action.payload, 'name');
      state.permissions = state.permissions?.filter(
        (permission) => permission?.name != 'supplier-portal-access'
      );
    },

    // GET USER GROUPS
    getUserGroupsSuccess(state, action) {
      state.groups = sortBy(action.payload, 'name');
    },

    //INITIAL user
    initialUser(state, action) {
      state.isLoading = false;
      state.singleUser = state.initialData;
      state.isEditMode = false;
    },

    getCountriesSuccess(state, action) {
      state.countries = action.payload;
      state.isLoading = false;
    },

    getDataRestrictionsSuccess(state, action) {
      state.dataRestrictions = action.payload;
      state.isLoading = false;
    },
    setEditMode(state, action) {
      state.isEditMode = action.payload;
    }
  }
});

export const { setEditMode } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startUsersLoading());
      const response = await axios.get('/user');
      dispatch(slice.actions.getUsersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEditUser(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/user/${userId}`);
      dispatch(slice.actions.getEditUserSuccess(response.data.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export async function createUser(data) {
  let dataset = { ...data };
  try {
    const response = await axios.post('/user', {
      ...dataset
    });
    return response;
  } catch (error) {
    return error;
  }
}

// ----------------------------------------------------------------------

export async function updateUser(data) {
  let dataset = { ...data };
  let userId = dataset.id;
  try {
    const response = await axios.put(`/user/${userId}`, {
      ...dataset
    });
    return response;
  } catch (error) {
    return error;
  }
}

// ----------------------------------------------------------------------

export function setInitialUser() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.initialUser());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserPermissions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/user/roles');
      dispatch(slice.actions.getUserPermissionsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserGroups() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/user/groups');
      dispatch(slice.actions.getUserGroupsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCountries() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/settings/countries');
      dispatch(slice.actions.getCountriesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDataRestrictions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/user/filters');
      dispatch(slice.actions.getDataRestrictionsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
