import mock from '../utils/mock';
import { mockImgCover } from '../utils/mockImages';
import faker from 'faker';
import fakeRequest from '../utils/fakeRequest';
import { map, random } from 'lodash';
// ----------------------------------------------------------------------

const ONBOARDING_SCREENS = [
  {
    name: 'Collaborates, connect, communicate and train',
    orderId: 1,
    imageUrl: mockImgCover(10)
  },
  {
    name: 'Elevate the employee experience',
    orderId: 2,
    imageUrl: mockImgCover(9)
  },
  {
    name: 'Learn to grow and thrive together',
    orderId: 3,
    imageUrl: mockImgCover(7)
  }
];

const PILLARS = [
  {
    id: 1,
    name: 'Leadership & culture',
    description: 'Leadership & culture',
    isEnabledForTenant: true
  },
  {
    id: 2,
    name: 'Technology',
    description: 'Technology',
    isEnabledForTenant: true
  },
  {
    id: 3,
    name: 'Career wellbeing',
    description: 'Career wellbeing',
    isEnabledForTenant: true
  },
  {
    id: 4,
    name: 'Physical and emotional wellbeing',
    description: 'Physical and emotional wellbeing',
    isEnabledForTenant: true
  },
  {
    id: 5,
    name: 'Financial wellbeing',
    description: 'Financial wellbeing',
    isEnabledForTenant: true
  },
  {
    id: 6,
    name: 'Environment and ways of working',
    description: 'Environment and ways of working',
    isEnabledForTenant: true
  },
  {
    id: 7,
    name: 'Strategy and change management',
    description: 'Strategy and change management',
    isEnabledForTenant: true
  },
  {
    id: 8,
    name: 'Reward and benefits',
    description: 'Reward and benefits',
    isEnabledForTenant: true
  }
];

const PILLARS_ORGANIZATION = [
  {
    id: 1,
    name: 'Leadership & culture',
    description: faker.lorem.sentences(2),
    pillarId: 1
  },
  {
    id: 2,
    name: 'Technology',
    description: faker.lorem.sentences(2),
    pillarId: 2
  },
  {
    id: 3,
    name: 'Career wellbeing',
    description: faker.lorem.sentences(2),
    pillarId: 3
  },
  {
    id: 4,
    name: 'Physical and emotional wellbeing',
    description: faker.lorem.sentences(2),
    pillarId: 4
  },
  {
    id: 5,
    name: 'Financial wellbeing',
    description: faker.lorem.sentences(2),
    pillarId: 5
  },
  {
    id: 6,
    name: 'Environment and ways of working',
    description: faker.lorem.sentences(2),
    pillarId: 6
  },
  {
    id: 7,
    name: 'Strategy and change management',
    description: faker.lorem.sentences(2),
    pillarId: 7
  },
  {
    id: 8,
    name: 'Reward and benefits',
    description: faker.lorem.sentences(2),
    pillarId: 8
  }
];

let welcomeVideos = {};

const onboardingScreens = {
  data: [...ONBOARDING_SCREENS]
};

const pillars = {
  data: [...PILLARS]
};

const org_pillars = {
  data: [...PILLARS_ORGANIZATION]
};

mock
  .onPost(/^\/api\/settings\/welcome-video\/[^\/]+/)
  .reply(async (request) => {
    await fakeRequest(1000);
    try {
      return [
        200,
        {
          data: {
            id: welcomeVideos.id,
            signedUrl:
              'https://66170bfe-fa85-4266-b792-8ff7c09c2aec.mock.pstmn.io/api/digital-content/media'
          }
        }
      ];
    } catch (error) {
      console.error(error);
      return [500, { message: 'Internal server error' }];
    }
  });

mock.onGet('/api/settings/onboarding').reply(async () => {
  try {
    await fakeRequest(2000);
    return [200, { ...onboardingScreens }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onPost(`/api/settings/onboarding`).reply(async (request) => {
  await fakeRequest(2000);
  const { screens } = JSON.parse(request.data);
  let screenData = [...screens];
  if (screenData.length > 0) {
    onboardingScreens.data = map(screenData, (_screens) => {
      return { ..._screens, imageUrl: mockImgCover(random(1, 10)) };
    });
  } else {
    onboardingScreens.data = [];
  }
  return [200, { ...onboardingScreens }];
});

mock.onGet('/api/settings/meta?type=pillars').reply(async () => {
  try {
    return [200, { ...pillars }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onGet('/api/settings/pillars').reply(async (config) => {
  try {
    return [200, { ...org_pillars }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onPost(`/api/settings/pillars`).reply(async (request) => {
  await fakeRequest(1000);
  try {
    const { newOrganizationalPillars } = JSON.parse(request.data);
    org_pillars.data = [];

    if (org_pillars.data.length === 0) {
      org_pillars.data = [...newOrganizationalPillars];
    }
    pillars.data = map(pillars.data, (_pillars) => {
      let foundOrgPillars = org_pillars.data.find(
        (item) => item.pillarId === _pillars.id
      );
      return !foundOrgPillars
        ? { ..._pillars, isEnabledForTenant: false }
        : { ..._pillars, isEnabledForTenant: true };
    });
    return [200, { ...org_pillars }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock
  .onPost(/^\/api\/settings\/welcome-video\/[^\/]+\/video/)
  .reply(async (request) => {
    return [200, { status: 'success' }];
  });

mock.onGet('/api/settings/welcome-video').reply(async (config) => {
  try {
    return [200, { data: welcomeVideos }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onPost('/api/settings/welcome-video').reply(async (request) => {
  await fakeRequest(1000);
  try {
    const { description } = JSON.parse(request.data);

    const newWelcomeVideo = {
      _id: faker.datatype.number(),
      description,
      coverImagePath:
        'https://test-experienz-media.s3.eu-west-2.amazonaws.com/experienz/digital_content/container_cover_image_e7163a08-223e-4779-b038-f7847582665e.jpeg',
      videoPath: 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4'
    };

    welcomeVideos = {
      ...newWelcomeVideo
    };

    return [
      200,
      {
        data: {
          id: 2,
          signedUrl:
            'https://66170bfe-fa85-4266-b792-8ff7c09c2aec.mock.pstmn.io/api/digital-content/media'
        }
      }
    ];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onDelete('/api/settings/welcome-video').reply(async (request) => {
  await fakeRequest(3000);

  try {
    welcomeVideos = {};
    return [200, { data: welcomeVideos }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});
