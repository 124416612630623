import { createSlice } from '@reduxjs/toolkit';
import { reject } from 'lodash';
// utils
import axios from '../../utils/axios';
import _ from 'lodash';
import {
  getBase64FromUrl,
  convertToBinaryStream
} from '../../utils/convertBase64';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  interestGroups: [],
  post: null,
  hasMore: true,
  index: 0,
  step: 0,
  initialData: {
    title: '',
    shortDescription: '',
    description: '',
    image: '',
    type: 'TEXT',
    date: '',
    link: [],
    content: ''
  }
};

const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET NEWS INFINITE
    getNewsInitial(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
      state.isEditMode = false;
    },

    // GET NEWS
    getEditNewsSuccess(state, action) {
      state.isLoading = false;
      state.singleNews = action.payload;
      state.isEditMode = true;
    },

    initialNews(state, action) {
      state.isLoading = false;
      state.singleNews = state.initialData;
      state.isEditMode = false;
    },

    getMoreNews(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    // GET ALL INTEREST GROUPS
    getAllInterestGroupsSuccess(state, action) {
      state.isLoading = false;
      state.interestGroups = action.payload;
    },

    // DELETE EVENT
    deleteNewsSuccess(state, action) {
      const { newsId } = action.payload;
      const deleteNews = reject(state.posts, {
        id: newsId
      });

      state.isLoading = false;
      state.posts = deleteNews;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreNews } = slice.actions;

// ----------------------------------------------------------------------

export function getNewsInitial() {
  return async (dispatch) => {
    dispatch(slice.actions.initialNews());
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/news');
      dispatch(slice.actions.getNewsInitial(response.data.data));
      return;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export async function createNews(data) {
  let dataset = { ...data };
  try {
    dataset.image = await getBase64FromUrl(dataset.image.preview);
    if (dataset.isMediaUpdated) {
      dataset.mediaContentType = dataset.content.type;
    }

    const response = await axios.post(`/news`, {
      ...dataset
    });
    if (dataset.isMediaUpdated) {
      let blobData = await convertToBinaryStream(
        dataset.content.file,
        dataset.mediaContentType
      );
      const mediaUpload = await fetch(response.data.data.signedUrl, {
        method: 'PUT',
        body: blobData
      });
      if (mediaUpload.status === 200) {
        const mediaUploadResponse = await axios.post(
          `/news/${response.data.data.id}/video`
        );
        return mediaUploadResponse;
      }
    }
    return response;
  } catch (error) {
    return error;
  }
}

// ----------------------------------------------------------------------

export function deleteNews(newsId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/news/${newsId}`);
      dispatch(slice.actions.deleteNewsSuccess({ newsId }));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function getEditNews(newsId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/news/${newsId}`);
      dispatch(slice.actions.getEditNewsSuccess(response.data.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export async function updateNews(data) {
  let dataset = { ...data };
  let newsId = dataset.id;
  try {
    dataset.image = dataset.image.file
      ? await getBase64FromUrl(dataset.image.preview)
      : null;
    if (dataset.isMediaUpdated) {
      dataset.mediaContentType = dataset.content.type;
    }

    const response = await axios.put(`/news/${newsId}`, {
      ...dataset
    });
    if (dataset.isMediaUpdated) {
      let blobData = await convertToBinaryStream(
        dataset.content.file,
        dataset.mediaContentType
      );

      const mediaUpload = await fetch(response.data.data.signedUrl, {
        method: 'PUT',
        body: blobData
      });

      if (mediaUpload.status === 200) {
        const mediaUploadResponse = await axios.post(
          `/news/${response.data.data.id}/video`
        );
        return mediaUploadResponse;
      }
    }
    return response;
  } catch (error) {
    return error;
  }
}

// ----------------------------------------------------------------------

export function setInitialNews() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.initialNews());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllInterestGroups() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/interest-groups');
      dispatch(slice.actions.getAllInterestGroupsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
