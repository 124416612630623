import React, { useEffect, useRef } from 'react';
import styles from './CustomMonthPickerStyles.css';
import { useState } from 'react';
import {
  Box,
  Button,
  Popover,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import calendarFill from '@iconify/icons-eva/calendar-outline';
import arrowLeftFill from '@iconify/icons-eva/arrow-left-fill';
import arrowRightFill from '@iconify/icons-eva/arrow-right-fill';
import { LoadingButton } from '@material-ui/lab';
import { useSelector } from 'react-redux';

const monthNames = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const MonthSelector = ({
  month,
  year,
  onSelectYear,
  startYear,
  endYear
}) => {
  const [yearState, setYearState] = useState(year);
  const [monthState, setMonthState] = useState(month);
  const theme = useTheme();

  useEffect(() => {
    onSelectYear({
      month: monthState,
      year: yearState
    });
  }, [monthState, yearState]);

  const styles = {
    yearNav: {
      display: 'flex',
      alignItems: 'center',
      margin: '0 1px 5px'
    },
    yearButton: {
      flex: '1',
      textAlign: 'center',
      margin: '0 2px'
    },
    yearButtonText: {
      verticalAlign: 'middle',
      fontWeight: 'bold'
    },
    monthContainer: {
      display: 'inline-block',
      padding: '0px',
      width: '5.33%',
      boxSizing: 'border-box'
    },
    monthButton: {
      margin: '1px 1px 1px 1px',
      lineHeight: '1.5em',
      textAlign: 'center',
      width: '80%',
      cursor: 'pointer'
    }
  };

  const onPrevYear = () => {
    setYearState((prevYear) => prevYear - 1);
  };

  const onNextYear = () => {
    setYearState((prevYear) => prevYear + 1);
  };

  const onSelect = (selectedMonth) => {
    setMonthState(selectedMonth);
  };

  const renderMonth = (monthItem) => {
    const selected = month === monthItem && year === yearState;

    return (
      <Button
        sx={{
          cursor: 'pointer',
          borderRadius: 1.5,
          backgroundColor: selected ? '#00B1AA' : theme.palette.common.white,
          color: selected
            ? theme.palette.common.white
            : theme.palette.primary.main
        }}
        style={styles.monthButton}
        onClick={() => onSelect(monthItem)}
        disabled={
          yearState >= new Date()?.getFullYear() &&
          monthItem - 1 > new Date()?.getMonth()
        }
      >
        {monthNames[monthItem]}
      </Button>
    );
  };

  const months = [];
  for (let i = 1; i <= 12; i++) {
    months.push(renderMonth(i));
  }

  return (
    <div>
      <div className="year-nav" style={styles.yearNav}>
        <Button
          small
          onClick={onPrevYear}
          minimal
          disabled={yearState <= startYear?.year}
        >
          <Icon icon={arrowLeftFill}></Icon>
        </Button>
        <div style={styles.yearButton}>
          <span style={styles.yearButtonText} className="block-header">
            {yearState}
          </span>
        </div>
        <Button
          small
          onClick={onNextYear}
          minimal
          disabled={yearState >= new Date()?.getFullYear()}
        >
          <Icon icon={arrowRightFill}></Icon>
        </Button>
      </div>
      <div style={styles.months}>{months}</div>
    </div>
  );
};

const MonthRangePicker = ({ startYear, endYear, onChange, error }) => {
  const theme = useTheme();
  const { selectedDateOption, dashboardDateRanges } = useSelector(
    (state) => state.report
  );
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [hover, setHover] = useState(null);

  const [startState, setStartState] = useState(
    Object.keys(dashboardDateRanges)?.length > 0
      ? {
          month: new Date(dashboardDateRanges?.startDate)?.getMonth() + 1,
          year: new Date(dashboardDateRanges?.startDate)?.getFullYear()
        }
      : { month: 1, year: startYear }
  );
  const [endState, setEndState] = useState(
    Object.keys(dashboardDateRanges)?.length > 0
      ? {
          month: new Date(dashboardDateRanges?.endDate)?.getMonth() + 1,
          year: new Date(dashboardDateRanges?.endDate)?.getFullYear()
        }
      : {
          month: new Date()?.getMonth() + 1,
          year: endYear
        }
  );
  const buttonRef = useRef(null);

  const onSetStart = ({ month, year }) => {
    setStartState({ month, year });
  };

  const onSetEnd = ({ month, year }) => {
    setEndState({ month, year });
  };

  return (
    <Box>
      <Tooltip title="Select a custom date range">
        <Button
          onClick={() => {
            setOpenDatePicker(true);
          }}
          text="Open Month Range Picker"
          ref={buttonRef}
          sx={{
            backgroundColor:
              selectedDateOption == 'custom'
                ? theme.palette.common.white
                : 'transparent',
            borderRadius: 2.5,
            p: 0,
            py: 0.5,
            minWidth: 40,
            ml: 1
          }}
        >
          <Icon
            icon={calendarFill}
            color={
              selectedDateOption == 'custom'
                ? theme.palette.common.black
                : theme.palette.common.white
            }
            height={20}
          />
        </Button>
      </Tooltip>
      <Popover
        id={'month-picker'}
        open={openDatePicker}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        disableRestoreFocus
      >
        <Box sx={{ p: 1 }}>
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                p: 1.5
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Typography variant="caption">Start Date</Typography>
                <MonthSelector
                  month={startState.month}
                  year={startState.year}
                  onSelectYear={onSetStart}
                  startYear={startYear}
                  endYear={endYear}
                />
              </Box>
            </Box>
            <Box
              sx={{
                p: 1.5
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Typography variant="caption">End Date</Typography>
                <MonthSelector
                  month={endState.month}
                  year={endState.year}
                  onSelectYear={onSetEnd}
                  startYear={startYear}
                  endYear={endYear}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              m: 2
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end'
              }}
            >
              {error && (
                <Box>
                  <Typography
                    variant="caption"
                    sx={{ color: theme.palette.error.main }}
                  >
                    {error}
                  </Typography>
                </Box>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                  variant="outlined"
                  sx={{ mr: 2 }}
                  size="small"
                  onClick={() => {
                    setOpenDatePicker(false);
                  }}
                >
                  <Typography variant=" caption"> Cancel</Typography>
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  size="small"
                  onClick={() => {
                    onChange(startState, endState);
                    if (
                      new Date(`${startState.year}-${startState.month}-01`) <
                      new Date(`${endState.year}-${endState.month}-30`)
                    ) {
                      setOpenDatePicker(false);
                    }
                  }}
                >
                  <Typography variant=" caption"> Ok</Typography>
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default MonthRangePicker;
