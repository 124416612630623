import { reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import {
  getBase64FromUrl,
  convertToBinaryStream
} from '../../utils/convertBase64';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  digitalResources: [],
  digitalResourceContainers: [],
  selectedDigitalResourceId: null,
  digitalContent: null,
  contentStats: null,
  initialDigitalResource: {
    id: '',
    title: '',
    shortDescription: '',
    description: '<p></p>',
    image: '',
    type: 'VIDEO',
    date: new Date().toISOString(),
    link: [
      {
        type: 'container',
        value: ''
      }
    ],
    content: '',
    isMediaUpdated: false,
    tags: [],
    languages: []
  },
  initialDigitalResourceContainer: {
    id: '',
    title: '',
    description: '',
    coverImage: ''
  }
};

const slice = createSlice({
  name: 'digitalResources',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DIGITAL RESOURCES
    getDigitalResourcesSuccess(state, action) {
      state.isLoading = false;
      state.digitalResources = action.payload.filter((digitalResource) => {
        digitalResource.container = digitalResource.link[0].value;
        return digitalResource;
      });
    },

    // GET DIGITAL RESOURCE CONTAINERS
    getDigitalResourceContainerSuccess(state, action) {
      state.isLoading = false;
      state.digitalResourceContainers = action.payload;
    },

    // CREATE EVENT
    createDigitalResourceSuccess(state, action) {
      const newDigitalResource = action.payload;
      state.isLoading = false;
      state.digitalResources = [...state.digitalResources, newDigitalResource];
    },

    // UPDATE EVENT
    updateDigitalResourceSuccess(state, action) {
      const digitalResource = action.payload;
      state.isEditMode = false;
      state.isLoading = false;
      state.digitalResources = updateDigitalResource;
    },

    // DELETE EVENT
    deleteDigitalResourceSuccess(state, action) {
      const { digitalResourceId } = action.payload;
      const deleteDigitalResource = reject(state.digitalResources, {
        id: digitalResourceId
      });
      state.isLoading = false;
      state.digitalResources = deleteDigitalResource;
    },

    initialDigitalResource(state, action) {
      state.isLoading = false;
      state.singleDigitalResource = state.initialDigitalResource;
      state.isEditMode = false;
    },

    // GET APP USAGE SUCCESS
    getDigitalContentCountSuccess(state, action) {
      state.isLoading = false;
      let digitalContentCounts = action.payload;
      state.digitalContent = {
        contents: [
          { name: 'Article', data: digitalContentCounts.article },
          { name: 'Video', data: digitalContentCounts.video },
          { name: 'Podcast', data: digitalContentCounts.podcast }
        ],
        total: digitalContentCounts.total,
        overall: 100,
        title: 'Digital Resources Summary'
      };
    },

    // GET CONTENT STATS SUCCESS
    getContentStatsSuccess(state, action) {
      state.isLoading = false;
      state.contentStats = action.payload;
    },

    // GET DIGITAL RESOURCE
    getEditDigitalResourceSuccess(state, action) {
      state.isLoading = false;
      state.singleDigitalResource = action.payload;
      state.isEditMode = true;
    },
    getLanguagesSuccess(state, action) {
      state.isLoading = false;
      state.languages = action.payload;
    },

    getEditDigitalResourceContainerSuccess(state, action) {
      state.isLoading = false;
      state.singleContainer = action.payload;
      state.isEditMode = true;
    },
    deleteDigitalResourceContainerSuccess(state, action) {
      const { containerId } = action.payload;
      const newContainers = reject(state.digitalResourceContainers, {
        id: containerId
      });
      state.isLoading = false;
      state.digitalResourceContainers = newContainers;
    },
    initialDigitalResourceContainer(state, action) {
      state.isLoading = false;
      state.singleContainer = state.initialDigitalResourceContainer;
      state.isEditMode = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getDigitalResources() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/digital-content');
      dispatch(slice.actions.getDigitalResourcesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export async function createDigitalResource(newDigitalResource) {
  let dataSet = { ...newDigitalResource };

  dataSet.image = await getBase64FromUrl(dataSet.image.preview);
  dataSet.mediaContentType = dataSet.content.type;

  try {
    const response = await axios.post(`/digital-content`, {
      ...dataSet
    });
    if (dataSet.isMediaUpdated) {
      let blobData = await convertToBinaryStream(
        dataSet.content.file,
        dataSet.mediaContentType
      );

      const mediaUpload = await fetch(response.data.data.signedUrl, {
        method: 'PUT',
        body: blobData
      });
      if (mediaUpload.status === 200) {
        const mediaUploadResponse = await axios.post(
          `/digital-content/${response.data.data.id}/media`
        );
        return mediaUploadResponse;
      }
    }
    return response;
  } catch (error) {
    return { statusCode: -1 };
  }
}

// ----------------------------------------------------------------------

export async function createDigitalResourceContainer(data) {
  let dataSet = { ...data };
  dataSet.coverImage = await getBase64FromUrl(dataSet.coverImage.preview);

  try {
    const response = await axios.post(`/digital-content/container`, {
      ...dataSet
    });
    return response;
  } catch (error) {
    return error;
  }
}

// ----------------------------------------------------------------------

export function getEditDigitalResource(digitalResourceId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/digital-content/${digitalResourceId}`);
      dispatch(slice.actions.getEditDigitalResourceSuccess(response.data.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export async function updateDigitalResource(updatedDigitalResource) {
  let dataSet = { ...updatedDigitalResource };
  try {
    dataSet.mediaContentType = dataSet.content.type;
    dataSet.image = dataSet.image.file
      ? await getBase64FromUrl(dataSet.image.preview)
      : null;

    const response = await axios.put(`/digital-content/${dataSet.id}`, {
      ...dataSet
    });
    if (dataSet.isMediaUpdated) {
      let blobData = await convertToBinaryStream(
        dataSet.content.file,
        dataSet.mediaContentType
      );
      const mediaUpload = await fetch(response.data.data.signedUrl, {
        method: 'PUT',
        body: blobData
      });
      if (mediaUpload.status === 200) {
        const mediaUploadResponse = await axios.post(
          `/digital-content/${response.data.data.id}/media`
        );
        return mediaUploadResponse;
      }
    }
    return response;
  } catch (error) {
    return error;
  }
}

// ----------------------------------------------------------------------

export function deleteDigitalResource(digitalResourceId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `/digital-content/${digitalResourceId}`
      );
      dispatch(
        slice.actions.deleteDigitalResourceSuccess({ digitalResourceId })
      );
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function getDigitalResourceContainers() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/digital-content/container`);
      dispatch(
        slice.actions.getDigitalResourceContainerSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setInitialDigitalResource() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.initialDigitalResource());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDigitalContentCount(filters) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/digital-content/count', {
        params: { filters }
      });
      dispatch(slice.actions.getDigitalContentCountSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError());
    }
  };
}

// ----------------------------------------------------------------------

export function getContentStats(filters) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/digital-content/top', {
        params: { filters }
      });
      dispatch(slice.actions.getContentStatsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError());
    }
  };
}

//-------------------------------------------------------------------------

export function getLanguages() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/settings/languages');
      dispatch(slice.actions.getLanguagesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEditDigitalResourceContainer(containerId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/digital-content/container/${containerId}`
      );
      dispatch(
        slice.actions.getEditDigitalResourceContainerSuccess(response.data.data)
      );
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function deleteDigitalResourceContainer(containerId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `/digital-content/container/${containerId}`
      );
      dispatch(
        slice.actions.deleteDigitalResourceContainerSuccess({ containerId })
      );
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export async function updateDigitalResourceContainer(updatedContainer) {
  var dataSet = { ...updatedContainer };
  try {
    dataSet.coverImage = dataSet.coverImage.file
      ? await getBase64FromUrl(dataSet.coverImage.preview)
      : null;

    const response = await axios.put(
      `/digital-content/container/${dataSet.id}`,
      {
        ...dataSet
      }
    );
    return response;
  } catch (error) {
    return error;
  }
}

//-----------------------------------------------------------------

export function setInitialDigitalResourceContainer() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.initialDigitalResourceContainer());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
