import faker from 'faker';
import { add, set, sub } from 'date-fns';
import { map, assign, reject } from 'lodash';
// utils
import mock from '../utils/mock';
import newMock from '../utils/mock';
import { mockImgCover } from '../utils/mockImages';
import { oneOf } from 'prop-types';
import { sample } from 'lodash';
import fakeRequest from '../utils/fakeRequest';

// ----------------------------------------------------------------------;

const CONTAINERS = [
  [{ type: 'container', value: 'Physical & Emotional Wellbeing' }],
  [{ type: 'container', value: 'Financial Wellbeing' }],
  [{ type: 'container', value: 'Environment & Ways Of Working' }],
  [{ type: 'container', value: 'Technology' }],
  [{ type: 'container', value: 'Leadership & Culture' }],
  [{ type: 'container', value: 'Strategy & Change Management' }],
  [{ type: 'container', value: 'Career Wellbeing' }],
  [{ type: 'container', value: 'Rewards & Benefits' }]
];

const TYPES = ['ARTICLE', 'VIDEO', 'PODCAST'];

const DIGITAL_RESOURCES_FOR_CONTENT_STATS = [
  'Physical & Emotional Wellbeing',
  'Financial Wellbeing',
  'Environment & Ways Of Working'
];

const DIGITAL_RESOURCE_CONTAINERS = [
  {
    id: 1,
    title: 'Physical & Emotional Wellbeing',
    description:
      'The effect my physical and mental health has on my wellbeing and performance. ',
    coverImage: mockImgCover(10),
    createdAt: faker.date.past(),
    interestGroupId: null
  },
  {
    id: 2,
    title: 'Financial Wellbeing',
    description:
      'My personal finances as they stand today, and my ability to plan and control my future financial situation.',
    coverImage: mockImgCover(9),
    createdAt: faker.date.past(),
    interestGroupId: 1
  },
  {
    id: 3,
    title: 'Environment & Ways Of Working',
    description:
      'The surrounding in the place where I choose to work, and how I interact with others.',
    coverImage: mockImgCover(8),
    createdAt: faker.date.past(),
    interestGroupId: 1
  },
  {
    id: 4,
    title: 'Technology',
    description:
      'The technology and communication channels which I use in my role.',
    coverImage: mockImgCover(7),
    createdAt: faker.date.past(),
    interestGroupId: 1
  },
  {
    id: 5,
    title: 'Leadership & Culture',
    description:
      'The climate that exists within the organisation; how I am led and managed. ',
    coverImage: mockImgCover(6),
    createdAt: faker.date.past(),
    interestGroupId: 1
  },
  {
    id: 6,
    title: 'Strategy & Change Management',
    description:
      'My organisation’s purpose, and how change is communicated to me.',
    coverImage: mockImgCover(5),
    createdAt: faker.date.past(),
    interestGroupId: 1
  },
  {
    id: 7,
    title: 'Career Wellbeing',
    description:
      'My skills, my development and the career opportunities in my organisation. ',
    coverImage: mockImgCover(4),
    createdAt: faker.date.past(),
    interestGroupId: 1
  },
  {
    id: 8,
    title: 'Rewards & Benefits',
    description: 'My salary, my rewards and my total benefits package.',
    coverImage: mockImgCover(3),
    createdAt: faker.date.past(),
    interestGroupId: 1
  }
];

let digitalResourceContainers = {
  data: [...DIGITAL_RESOURCE_CONTAINERS]
};

let digitalResources = [...Array(10)].map((digitalResource, index) => {
  const setIndex = index + 1;
  let type = sample(TYPES);
  return {
    id: faker.datatype.number(),
    imageUrl: mockImgCover(setIndex),
    title: faker.name.title(),
    shortDescription: faker.lorem.sentences(2),
    date: faker.date.past(),
    description: `<p>${faker.lorem.paragraphs()}</p>`,
    type: type,
    mediaUrl:
      type === 'VIDEO'
        ? 'https://media.experienz.co.uk/mitrainnovation/digital_content/23_image_8b86c0c7-86de-47d2-be40-be2bdca2bff0.mp4'
        : 'https://media.experienz.co.uk/mitrainnovation/digital_content/25_media_2ad95a26-d9fa-4ff3-ab67-3bfeb9b63a5a.mp3',
    link: sample(CONTAINERS),
    customTag: [sample(['life', 'green'])],
    language: 'English'
  };
});

let contentStats = [...Array(5)].map((digitalResource, index) => {
  const setIndex = index + 1;
  let type = sample(TYPES);
  return {
    id: faker.datatype.number(),
    title: faker.name.title(),
    type: type,
    likes: faker.datatype.number({
      max: 250.0,
      min: 10.0,
      precision: 1
    })
  };
});

const digitalContentCountElements = ['Digital Resources Count'];

const digitalContentCount = {
  data: {
    article: 45,
    video: 55,
    podcast: 30,
    total: 130
  }
};

const topDigitalResources = [...Array(5)].map((digitalResource, index) => {
  const setIndex = index + 1;
  let type = sample(TYPES);
  return {
    title: faker.name.title(),
    type: type,
    likes: faker.datatype.number({ min: 1, max: 49, precision: 1 })
  };
});

const LANGUAGES = [
  {
    id: 1,
    name: 'English',
    code: 'en'
  },
  {
    id: 2,
    name: 'Mandarin Chinese',
    code: 'en'
  },
  {
    id: 3,
    name: 'Hindi',
    code: 'en'
  },
  {
    id: 4,
    name: 'Spanish',
    code: 'en'
  }
];
// ----------------------------------------------------------------------

mock.onGet('/api/digital-content').reply(async () => {
  await fakeRequest(1000);
  return [200, { data: [...digitalResources] }];
});

// ----------------------------------------------------------------------

mock.onPost('/api/digital-content/container').reply(async (request) => {
  await fakeRequest(1000);
  try {
    const { title, description, coverImage } = JSON.parse(request.data);
    const digitalResourceContainer = {
      id: faker.datatype.number(),
      title,
      description,
      coverImage: mockImgCover(2),
      createdAt: new Date()
    };

    digitalResourceContainers.data = [
      ...digitalResourceContainers.data,
      digitalResourceContainer
    ];

    return [200, { digitalResource: digitalResourceContainer }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

//-----------------------------------------------------------------------------------

mock.onPost('/api/digital-content').reply(async (request) => {
  await fakeRequest(1000);
  try {
    const {
      type,
      title,
      shortDescription,
      description,
      image,
      mediaContentType,
      date,
      link,
      customTag
    } = JSON.parse(request.data);
    const digitalResource = {
      id: faker.datatype.number(),
      type,
      title,
      shortDescription,
      description,
      mediaContentType,
      link,
      image: mockImgCover(2),
      date: new Date(),
      customTag
    };
    digitalResources = [...digitalResources, digitalResource];
    return [
      200,
      {
        data: {
          id: digitalResource.id,
          signedUrl:
            'https://66170bfe-fa85-4266-b792-8ff7c09c2aec.mock.pstmn.io/api/digital-content/media'
        }
      }
    ];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

//-----------------------------------------------------------------------------------

mock.onPut(/^\/api\/digital-content\/container\/\d+/).reply(async (request) => {
  await fakeRequest(1000);
  try {
    const containerId = Number(request.url.split('/').slice(-1)[0]);
    const updatedContainer = JSON.parse(request.data);
    let container = null;
    digitalResourceContainers.data = map(
      digitalResourceContainers.data,
      (_container) => {
        if (_container.id === containerId) {
          assign(_container, {
            ...updatedContainer,
            coverImage: mockImgCover(1)
          });
          container = _container;
        }
        return _container;
      }
    );
    return [200, { data: container }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});
// ----------------------------------------------------------------------

mock.onPut(/^\/api\/digital-content\/[^\/]+/).reply(async (request) => {
  await fakeRequest(1000);
  try {
    const digitalResourceId = Number(request.url.split('/').slice(-1)[0]);
    const { updateDigitalResource } = JSON.parse(request.data);
    let digitalResource = null;
    digitalResources = map(digitalResources, (_digitalResource) => {
      if (_digitalResource.id === digitalResourceId) {
        assign(_digitalResource, { ...updateDigitalResource });
        digitalResource = _digitalResource;
      }
      return _digitalResource;
    });
    return [
      200,
      {
        data: {
          id: digitalResource.id,
          signedUrl:
            'https://66170bfe-fa85-4266-b792-8ff7c09c2aec.mock.pstmn.io/api/digital-content/media'
        }
      }
    ];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

// ----------------------------------------------------------------------

mock.onGet('/api/digital-content/container').reply(async (config) => {
  try {
    return [200, { ...digitalResourceContainers }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock
  .onDelete(/^\/api\/digital-content\/container\/[^\/]+/)
  .reply(async (request) => {
    await fakeRequest(1000);
    try {
      const Id = Number(request.url.split('/').slice(-1)[0]);
      let containerId = parseInt(Id);
      digitalResourceContainers.data = reject(digitalResourceContainers.data, {
        id: containerId
      });
      return [200, { containerId }];
    } catch (error) {
      console.error(error);
      return [500, { message: 'Internal server error' }];
    }
  });
//-----------------------------------------------------------------------------------

mock.onDelete(/^\/api\/digital-content\/[^\/]+/).reply(async (request) => {
  await fakeRequest(1000);
  try {
    const digitalResourceId = request.url.split('/').slice(-1)[0];
    digitalResources = reject(digitalResources, { id: digitalResourceId });
    return [200, { digitalResourceId }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onGet('/api/digital-content/count').reply(200, { ...digitalContentCount });

//for  content stats
mock.onGet('/api/digital-content/top').reply(async () => {
  await fakeRequest(1000);
  return [200, { data: [...contentStats] }];
});

mock.onPost(/^\/api\/digital-content\/[^\/]+\/media/).reply(async (request) => {
  return [200, { status: 'success' }];
});

//-----------------------------------------------------------------------------------

//get languages
mock.onGet('/api/settings/languages').reply(async () => {
  return [200, { data: [...LANGUAGES] }];
});

//-----------------------------------------------------------------------------------

mock
  .onGet(/^\/api\/digital-content\/container\/[^\/]+/)
  .reply(async (request) => {
    await fakeRequest(1000);
    try {
      const containerId = Number(request.url.split('/').slice(-1)[0]);

      const container = digitalResourceContainers.data.find(
        (_container) => _container.id === containerId
      );
      if (!container) {
        return [
          404,
          { ...{ data: { message: 'digital resource container not found' } } }
        ];
      }
      return [200, { ...{ data: container } }];
    } catch (error) {
      console.error(error);
      return [500, { message: 'Internal server error' }];
    }
  });

//-----------------------------------------------------------------------------------

//-----------------------------------------------------------------------------------

mock.onGet(/^\/api\/digital-content\/[^\/]+/).reply(async (request) => {
  await fakeRequest(1000);
  try {
    const digitalResourceId = Number(request.url.split('/').slice(-1)[0]);
    const digitalResource = digitalResources.find(
      (_resource) => _resource.id === digitalResourceId
    );
    if (!digitalResource) {
      return [404, { ...{ data: { message: 'digital resource not found' } } }];
    }
    return [200, { ...{ data: digitalResource } }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});
