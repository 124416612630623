import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_AUTH, PATH_PAGE } from '../routes/paths';
// components
import LoadingScreen from '../components/shared/LoadingScreen';
//constants
import { ROLE_ADMIN_PORTAL_ACCESS } from '../utils/constants';
import BrandLoadingScreen from 'src/components/shared/BrandLoadingScreen';
import { Box } from '@material-ui/core';
// ----------------------------------------------------------------------

AuthProtect.propTypes = {
  children: PropTypes.node
};

export default function AuthProtect({ children }) {
  const { isLoading, isAuthenticated, user } = useAuth();
  const history = useHistory();
  const next = history.location.pathname + history.location.search;

  if (isLoading) {
    return (
      <Box
        height="100vh"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <BrandLoadingScreen />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return (
      <Redirect
        to={
          next && next != '/'
            ? `${PATH_AUTH.login}?next=${encodeURIComponent(next)}`
            : PATH_AUTH.login
        }
      />
    );
  }

  if (!user.roles.includes(ROLE_ADMIN_PORTAL_ACCESS)) {
    return <Redirect to={PATH_PAGE.page401} />;
  }

  return <>{children}</>;
}
