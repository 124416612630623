import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { uniq, map } from 'lodash';
import { DEFAULT_CUBE_SCHEMA } from 'src/utils/constants';
import { checkUndefinedOrNull } from 'src/utils/functions';

const initialState = {
  isLoading: false,
  isPerspectivesLoading: false,
  allPerspectivesDetails: [],
  scrollToComponent: {},
  isSummaryWidgetsLoading: false,
  isFiltersLoading: false,
  error: false,
  isEditMode: false,
  isChangesAvailable: false,
  filterOptions: [],
  perspectiveDetails: {
    viewTitle: '',
    viewDescription: '<p></p>',
    viewCharts: [],
    userGroups: []
  },
  perspectiveList: [{ viewId: 0, viewTitle: '', viewDescription: '' }],
  permissionGroups: [],
  bookmarkedViews: [],
  suppliersView: {},
  summaryCharts: [],
  selectedDateOption: '1y',
  dashboardDateRanges: {},
  dashboardPreviousDateRanges: {}
};

const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    startAllPerspectivesLoading(state) {
      state.isPerspectivesLoading = true;
    },

    // START FILTER LOADING
    startFilterLoading(state) {
      state.isFiltersLoading = true;
    },

    // START FILTER LOADING
    startSummaryWidgetsLoading(state) {
      state.isSummaryWidgetsLoading = true;
    },

    // GET USER PERMISSIONS GROUPS
    getUserPermissionGroupsSuccess(state, action) {
      state.permissionGroups = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isFiltersLoading = false;
      state.error = action.payload;
    },

    // GET PERSPECTIVES
    getPerspectiveListSuccess(state, action) {
      state.isLoading = false;
      const perspectiveList = action.payload.views;
      const defaultDateRange = action.payload.defaultDateRange;
      if (!checkUndefinedOrNull(defaultDateRange)) {
        state.defaultDateRange = defaultDateRange;
      }
      if (perspectiveList?.length > 0) {
        state.perspectiveList = perspectiveList;
        state.bookmarkedViews = perspectiveList.filter(
          (perspective) => perspective.bookmarked
        );
        state.suppliersView = perspectiveList.find(
          (perspective) => perspective.isSupplier
        );
      } else {
        state.perspectiveList = [
          { viewId: 0, viewTitle: '', viewDescription: '' }
        ];
        state.perspectiveDetails = {
          viewTitle: '',
          viewDescription: '<p></p>',
          viewCharts: []
        };
      }
    },

    getPerspectiveDetailsSuccess(state, action) {
      state.isLoading = false;
      let {
        viewId,
        viewTitle,
        viewDescription,
        bookmarked,
        isSupplier,
        viewCharts = [],
        userGroups = []
      } = action.payload;

      viewCharts = viewCharts.map((chart) => {
        return {
          ...chart,
          key: chart.key
        };
      });

      state.perspectiveDetails = {
        viewId,
        viewTitle,
        viewDescription,
        bookmarked,
        isSupplier,
        viewCharts,
        userGroups
      };
      state.isChangesAvailable = false;
    },

    getAllPerspectiveDetailsSuccess(state, action) {
      state.isPerspectivesLoading = false;
      let {
        viewId,
        viewTitle,
        viewDescription,
        bookmarked,
        isSupplier,
        viewCharts = [],
        userGroups = []
      } = action.payload;

      viewCharts = viewCharts.map((chart) => {
        return {
          ...chart,
          key: chart.key
        };
      });

      const perspectiveDetails = {
        viewId,
        viewTitle,
        viewDescription,
        bookmarked,
        isSupplier,
        viewCharts,
        userGroups
      };

      const indexToUpdate = state.allPerspectivesDetails.findIndex(
        (perspective) => perspective.viewId === viewId
      );

      if (indexToUpdate !== -1) {
        state.allPerspectivesDetails[indexToUpdate] = perspectiveDetails;
      } else {
        state.allPerspectivesDetails = [
          ...state.allPerspectivesDetails,
          perspectiveDetails
        ];
      }
    },

    //RESET PERSPECTIVE DETAILS
    resetPerspectiveDetails(state, action) {
      state.perspectiveDetails = {
        viewTitle: '',
        viewDescription: '<p></p>',
        viewCharts: [],
        userGroups: []
      };
    },
    // GET FILTERS SUCCESS
    getFiltersSuccess(state, action) {
      state.isFiltersLoading = false;
      state.filterOptions = action.payload;
    },
    // GET SUMMARY SUCCESS
    getSummarySuccess(state, action) {
      state.summaryCharts = action.payload;
      state.isSummaryWidgetsLoading = false;
      state.isLoading = false;
    },
    // SET DASHBOARD DATE OPTION
    setDashboardDateOption(state, action) {
      state.selectedDateOption = action.payload;
    },
    // SET DASHBOARD DATE RANGES
    setDashboardDateRanges(state, action) {
      state.dashboardDateRanges = action.payload;
    },
    // SET DASHBOARD PREVIOUS DATE RANGES
    setDashboardPreviousDateRanges(state, action) {
      state.dashboardPreviousDateRanges = action.payload;
    },
    // SET setScrollToComponent
    setScrollToComponent(state, action) {
      state.scrollToComponent = action.payload;
    }
  }
});

// ----------------------------------------------------------------------
export const {
  resetPerspectiveDetails,
  setDashboardDateOption,
  setDashboardDateRanges,
  setDashboardPreviousDateRanges,
  setScrollToComponent
} = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPerspectiveList() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/insights-views');
      dispatch(slice.actions.getPerspectiveListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPerspectiveDetails(id) {
  return async (dispatch) => {
    try {
      if (id > 0) {
        dispatch(slice.actions.startLoading());
        const response = await axios.get(`/insights-views/${id}`);
        dispatch(
          slice.actions.getPerspectiveDetailsSuccess(response.data.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllPerspectiveDetails(ids) {
  return async (dispatch) => {
    try {
      if (ids?.length > 0) {
        dispatch(slice.actions.startAllPerspectivesLoading());

        ids.forEach(async (id) => {
          const response = await axios.get(`/insights-views/${id}`);
          dispatch(
            slice.actions.getAllPerspectiveDetailsSuccess(response.data.data)
          );
        });
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function createPerspective(perspective) {
  try {
    const response = await axios.post(`/insights-views`, {
      ...perspective
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function deletePerspective(perspectiveId) {
  try {
    const response = await axios.delete(`/insights-views/${perspectiveId}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function updatePerspective(perspective) {
  const { viewCharts = [], ...dataSet } = perspective;
  const updatedViewCharts = viewCharts.map((c) => {
    const { resultSet, ...other } = c;
    return other;
  });
  try {
    const response = await axios.put(`/insights-views/${dataSet.viewId}`, {
      ...dataSet,
      viewCharts: updatedViewCharts
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function getSearchQuery(prompt) {
  try {
    const response = await axios.get('/suggest', {
      params: { prompt }
    });
    return response;
  } catch (error) {
    return error;
  }
}

export function getUserPermissionGroups() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/user/groups');
      dispatch(
        slice.actions.getUserPermissionGroupsSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateBookmarkStatus(id, bookmarkStatus) {
  try {
    const response = await axios.put(`/insights-views/bookmark/${id}`, {
      bookmarked: bookmarkStatus
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function updatePinStatus(id, pinStatus) {
  try {
    const response = await axios.put(`/insights-views/pin/${id}`, {
      pinned: pinStatus
    });
    return response;
  } catch (error) {
    return error;
  }
}

export function getFilterDimensions(dashboardFilters, cubejsApi) {
  return async (dispatch) => {
    dispatch(slice.actions.startFilterLoading());
    let fOptions = [];
    let filterDimensions = [];
    try {
      const meta = await cubejsApi.meta();

      //check for employee schema
      let schema = meta.cubes.find(
        (schema) => schema.name.toLowerCase() === DEFAULT_CUBE_SCHEMA
      );

      if (!schema) {
        schema = meta.cubes[0];
      }

      if (schema) {
        schema.dimensions.forEach((dimension) => {
          if (dashboardFilters.includes(dimension.name.split('.')[1])) {
            filterDimensions.push(dimension.name);
          }
        });

        const filterQuery = { dimensions: filterDimensions };
        const queryResult = await cubejsApi.load(filterQuery);
        filterDimensions.forEach((filterName) => {
          const options = uniq(
            map(queryResult.loadResponses[0].data, filterName)
          );
          fOptions.push({ name: filterName, options: options });
        });
        dispatch(slice.actions.getFiltersSuccess(fOptions));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSupplierFilterDimensions(dashboardFilters, cubejsApi) {
  return async (dispatch) => {
    dispatch(slice.actions.startFilterLoading());
    let fOptions = [];
    let filterDimensions = [];
    try {
      fOptions = [
        {
          name: 'supplier.event',
          options: [
            'F1',
            'F2',
            "Performer's night",
            'MotoGP',
            'This Quarter',
            'This Month',
            'This Quarter',
            'Last Year',
            'Last Month',
            'Last Quarter'
          ]
        },
        {
          name: 'supplier.sector',
          options: ['Beverage', 'Transport', 'Retail', 'Technology']
        }
      ];
      dispatch(slice.actions.getFiltersSuccess(fOptions));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSummaryWidget() {
  return async (dispatch) => {
    dispatch(slice.actions.startSummaryWidgetsLoading());
    try {
      const response = await axios.get('/insights-views/summary');
      dispatch(slice.actions.getSummarySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
