import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import authJwtReducer from './slices/authJwt';
import settingsReducer from './slices/settings';
import calendarReducer from './slices/calendar';
import insightsReducer from './slices/insights';
import surveyReducer from './slices/surveys';
import interestGroupsReducer from './slices/interest-groups';
import communicationReducer from './slices/communication';
import platformSettingsReducer from './slices/platformSettings';
import digitalResourcesReducer from './slices/digital-resources';
import newsReducer from './slices/news';
import dataSetReducer from './slices/data-sets';
import reportReducer from './slices/report';
import integratedReportReducer from './slices/integrated-reports';
import supplierReducer from './slices/suppliers';
import customCalendarReducer from './slices/custom-calendar';
import betaReducer from './slices/beta';
import forgotPassword from './slices/forgot-password';
import esgMarketplaceReducer from './slices/esg-marketplace';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const authPersistConfig = {
  key: 'authJwt',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated', 'user']
};

const integratedReportPersistConfig = {
  key: 'integratedReport',
  storage,
  keyPrefix: 'redux-',
  whitelist: [
    'isEditMode',
    'isReviewMode',
    'isGenerateMode',
    'singleIntegratedReport',
    'reportManifestData',
    'frameworksData'
  ]
};

const dataSetPersistConfig = {
  key: 'dataSets',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['metaDefaults']
};

const betaPersistConfig = {
  key: 'betaFeatures',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['betaEnabled']
};

const forgetPasswordPersistConfig = {
  key: 'forgotpassword',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['isVerified', 'sent', 'userName']
};

const reportPersistConfig = {
  key: 'report',
  storage,
  keyPrefix: 'redux-',
  whitelist: [
    'selectedDateOption',
    'dashboardDateRanges',
    'dashboardPreviousDateRanges',
    'allPerspectivesDetails'
  ]
};

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  settings: settingsReducer,
  calendar: calendarReducer,
  product: persistReducer(productPersistConfig, productReducer),
  authJwt: persistReducer(authPersistConfig, authJwtReducer),
  insights: insightsReducer,
  survey: surveyReducer,
  interestGroups: interestGroupsReducer,
  communication: communicationReducer,
  platformSettings: platformSettingsReducer,
  digitalResources: digitalResourcesReducer,
  news: newsReducer,
  report: reportReducer,
  integratedReports: persistReducer(
    integratedReportPersistConfig,
    integratedReportReducer
  ),
  dataSets: persistReducer(dataSetPersistConfig, dataSetReducer),
  // dataSets: dataSetReducer,
  report: persistReducer(reportPersistConfig, reportReducer),
  suppliers: supplierReducer,
  customCalendar: customCalendarReducer,
  betaFeatures: persistReducer(betaPersistConfig, betaReducer),
  forgotpassword: persistReducer(forgetPasswordPersistConfig, forgotPassword),
  esgMarketplace: esgMarketplaceReducer
});

export { rootPersistConfig, rootReducer };
