import faker from 'faker';
import { sampleSize, first } from 'lodash';
// utils
import mock from '../utils/mock';
import fakeRequest from '../utils/fakeRequest';
import { subBusinessDays } from 'date-fns';

// ----------------------------------------------------------------------

const STANDARD_QUESTIONNAIRES = [
  'Workplace Wellbeing Questionnaire',
  'Work Limitations Questionnaire',
  'Pulse'
];

const SCHEDULE_TYPES = [
  { name: 'Daily', subOptionsType: 'TIME_OF_THE_DAY' },
  { name: 'Weekly', subOptionsType: 'DAY_OF_THE_WEEK' },
  { name: 'Monthly', subOptionsType: 'NONE' },
  { name: 'Quartely', subOptionsType: 'NONE' }
];

const QUESTIONNAIRE_SCHEDULE_TYPES = {
  'Workplace Wellbeing Questionnaire': 'Weekly',
  'Work Limitations Questionnaire': 'Monthly',
  Pulse: 'Daily'
};

const LOCATIONS = [
  'Shared Services',
  'Digital Services',
  'Ipswich, United Kingdom',
  'Mitra Ventures',
  'Business Services',
  'Mitra Digital',
  'Moratuwa, Sri Lanka',
  'MS & DevOps'
];

const DEPARTMENTS = [
  'Overall',
  'Human Resources',
  'Finance',
  'Legal',
  'Adminstration',
  'Information Technology',
  'Logistics',
  'Communications',
  'Engineering',
  'Marketing',
  'Sales'
];

const POLLS = [
  {
    poll: 'How do you find the new competency framework?',
    options: [
      {
        id: 1,
        value: 'I like working from home as I find it more productive'
      },
      {
        id: 2,
        value: 'I like a mix of working from home and office'
      },
      {
        id: 3,
        value: "I don't like working from home"
      }
    ]
  },
  {
    poll:
      'Are you finding the multimedia content in the mobile application useful?',
    options: [
      {
        id: 1,
        value: 'I like working from home as I find it more productive'
      },
      {
        id: 2,
        value: 'I like a mix of working from home and office'
      },
      {
        id: 3,
        value: "I don't like working from home"
      }
    ]
  },
  {
    poll: 'Are you or your family affected by COVID-19 directly?',
    options: [
      {
        id: 1,
        value: 'I like working from home as I find it more productive'
      },
      {
        id: 2,
        value: 'I like a mix of working from home and office'
      },
      {
        id: 3,
        value: "I don't like working from home"
      }
    ]
  },
  {
    poll: 'How do you find working from home?',
    options: [
      {
        id: 1,
        value: 'I like working from home as I find it more productive'
      },
      {
        id: 2,
        value: 'I like a mix of working from home and office'
      },
      {
        id: 3,
        value: "I don't like working from home"
      }
    ]
  }
];

const POLL_DATA = {
  data: POLLS.map((poll, index) => {
    return {
      ...poll,
      id: faker.datatype.uuid(),
      date: `${subBusinessDays(subBusinessDays(new Date(), 1), 5 * index)}`,
      location: LOCATIONS[index],
      department: DEPARTMENTS[index],
      options: poll.options.map((option) => {
        return {
          id: option.id,
          value: option.value,
          responses: faker.datatype.number({ min: 8, max: 30, precision: 1 })
        };
      })
    };
  })
};

const getRandomSchedule = (type) => {
  const typeOption = first(SCHEDULE_TYPES.filter((t) => t.name === type))
    .subOptionsType;
  switch (typeOption) {
    case 'TIME_OF_THE_DAY':
      let date = new Date();
      const time = faker.datatype.number({ min: 10, max: 15, precision: 1 });
      date.setHours(time, 0, 0);
      return `${date}`;
    case 'NONE':
      return 'Day 01';
    case 'DAY_OF_THE_WEEK':
      return `${faker.datatype.number({
        min: 1,
        max: 5,
        precision: 1
      })}`;
    default:
      return null;
  }
};

const CONFIGURED_STANDARD_QUESTIONNAIRES = sampleSize(
  STANDARD_QUESTIONNAIRES,
  2
);

const getRandomScheduleForQuestionnaire = (q) => {
  const randomScheduleType = QUESTIONNAIRE_SCHEDULE_TYPES[q];
  return {
    id: faker.datatype.uuid(),
    type: randomScheduleType,
    value: getRandomSchedule(randomScheduleType),
    enabled: true,
    notificationTitle: 'test',
    notificationBody: 'test'
  };
};

const CLIENT_QUESTIONNAIRE_SCHEDULE = {
  data: STANDARD_QUESTIONNAIRES.map((q) => {
    let schedules = [];
    if (CONFIGURED_STANDARD_QUESTIONNAIRES.includes(q)) {
      schedules = [{ ...getRandomScheduleForQuestionnaire(q) }];
    }
    return {
      name: q,
      id: faker.datatype.uuid(),
      schedules: schedules
    };
  })
};

mock
  .onGet('/api/survey/standard')
  .reply(200, { ...CLIENT_QUESTIONNAIRE_SCHEDULE });

mock.onGet('/api/survey/polls').reply(async (request) => {
  await fakeRequest(3000);
  return [200, { ...POLL_DATA }];
});
mock.onPost('/api/survey/standard').reply(async (request) => {
  await fakeRequest(3000);
  return [200, { data: [] }];
});

mock.onDelete(/^\/api\/survey\/schedule\/[^\/]+/).reply(async (request) => {
  await fakeRequest(3000);
  return [200, { data: [] }];
});

mock.onGet(/^\/api\/survey\/polls\/[^\/]+\/stats/).reply(200, { ...POLL_DATA });

mock.onPost(/^\/api\/survey\/polls/).reply(async (request) => {
  await fakeRequest(3000);
  return [200, { data: [] }];
});

mock.onGet('/api/settings/locations').reply(200, { data: [...LOCATIONS] });

mock.onGet('/api/departments').reply(200, { data: [...DEPARTMENTS] });
