import faker, { fake } from 'faker';
import { sample, reverse, sampleSize } from 'lodash';
import { paramCase } from 'change-case';
// utils
import mock from '../utils/mock';
import { mockImgProduct } from '../utils/mockImages';
import { add, set, sub, subBusinessDays } from 'date-fns';
import { OVERALL } from '../utils/constants';
import { dotCase } from 'change-case';
import fakeRequest from '../utils/fakeRequest';
// ----------------------------------------------------------------------

const DEPARTMENTS = [
  'Overall',
  'Human Resources',
  'Finance',
  'Legal',
  'Adminstration',
  'Information Technology',
  'Logistics',
  'Communications',
  'Engineering',
  'Marketing',
  'Sales'
];
const REGIONS = [
  'UK Region 1',
  'UK Region 2',
  'UK Region 3',
  'UK Region 4',
  'US Region 1',
  'US Region 2',
  'US Region 3',
  'US Region 4'
];

const SEGMENTS = [
  'Physical & Emotional Wellbeing',
  'Financial Wellbeing',
  'Environment & Ways Of Working',
  'Technology',
  'Leadership & Culture',
  'Strategy & Change Management',
  'Career Wellbeing',
  'Rewards & Benefits'
];

const DIVERSITY_SEGMENTS = [
  'Male',
  'Female',
  'Generation X',
  'Millennials',
  'Centennials',
  'Short Tenured',
  'Long Tenured'
];

// name: segment,
// current: faker.datatype.number({ max: 90.0, min: 60.0, precision: 1 }),
// previous: faker.datatype.number({ max: 78.0, min: 40.0, precision: 1 })

const fakePercentageGeneratorForSegments = (segment) => {
  return {
    name: segment,
    current: faker.datatype.number({ max: 90.0, min: 40.0, precision: 1 }),
    previous: faker.datatype.number({ max: 80.0, min: 30.0, precision: 1 })
  };
};

const fakePercentageGenerator = () =>
  faker.datatype.number({ max: 90.0, min: 60.0, precision: 1 });

const departmentMapper = (department, index) => ({
  name: department,
  data: SEGMENTS.map(fakePercentageGeneratorForSegments),
  overall: faker.datatype.number({ max: 90.0, min: 40.0, precision: 1 })
});

const departmentData = [];

const workplaceWellBeing = {
  data: {
    overall: {
      current: faker.datatype.number({ max: 90.0, min: 55.0, precision: 1 }),
      previous: faker.datatype.number({ max: 80.0, min: 40.0, precision: 1 })
    },
    // overall: {
    //   current: 87,
    //   previous: null
    // },
    // departments: departmentData,
    departments: DEPARTMENTS.map(departmentMapper),
    diversity: DIVERSITY_SEGMENTS.map((segment) => {
      return {
        name: segment,
        data: faker.datatype.number({ max: 90.0, min: 35.0, precision: 1 }),
        participation: faker.datatype.number({
          max: 30.0,
          min: 10.0,
          precision: 1
        })
      };
    })
  }
};

const appUsageElements = [
  'Usage Rate',
  'Survey Completion',
  'Content Utilisation',
  'Onboarding Rate'
];

const appUsage = {
  data: appUsageElements.map((element) => {
    return {
      title: element,
      departments: DEPARTMENTS.map((department) => {
        return {
          name: department,
          data: faker.datatype.number({
            max: 95.0,
            min: 45.0,
            precision: 1
          })
        };
      }),
      overall: faker.datatype.number({
        max: 95.0,
        min: 45.0,
        precision: 1
      })
    };
  })
  // data: []
};

const appUsageDetails = {
  // data: appUsageElements.map((element) => {
  //   return {
  //     title: element,
  //     departments: DEPARTMENTS.map((department) => {
  //       return {
  //         name: department,
  //         data: faker.datatype.number({
  //           max: 95.0,
  //           min: 45.0,
  //           precision: 1
  //         })
  //       };
  //     }),
  //     overall: faker.datatype.number({
  //       max: 95.0,
  //       min: 45.0,
  //       precision: 1
  //     })
  //   };
  // })
  data: []
};

const financialOutlookElements = [
  'Cost of Presenteeism',
  'Cost of Absenteeism',
  'Cost of Attrition'
];

const getHistory = () => {
  return [...Array(6)].map((_, index) => {
    return {
      date: sub(new Date(), { days: 7 * index }),
      data: faker.datatype.number({
        max: 9500.0,
        min: 6500.0,
        precision: 1
      })
    };
  });
};

const financialOutlook = {
  data: financialOutlookElements.map((element) => {
    return {
      title: element,
      departments: DEPARTMENTS.map((department) => {
        return {
          name: department,
          data: faker.datatype.number({
            max: 1500.0,
            min: 750.0,
            precision: 1
          })
        };
      }),
      history: reverse(getHistory()),
      overall: faker.datatype.number({
        max: 9500.0,
        min: 6500.0,
        precision: 1
      })
    };
  })
  // data: []
};

const contentStats = {
  data: SEGMENTS.map((segment) => {
    return {
      name: segment,
      data: {
        views: faker.datatype.number({
          max: 2000.0,
          min: 50.0,
          precision: 1
        }),
        likes: faker.datatype.number({
          max: 250.0,
          min: 10.0,
          precision: 1
        })
      }
    };
  })
};

const getProductivityHistory = () => {
  return [...Array(12)].map((_, index) => {
    return {
      date: sub(new Date(), { days: 14 * index }),
      data: faker.datatype.number({
        min: -10.0,
        max: 20.0,
        precision: 1
      })
    };
  });
};

// const PRDUCTIVTY_LOSS_GROUPS = [
//   'Low (0% - 20%)',
//   'Medium (20% - 40%)',
//   'High (40% - 60%)',
//   'Extremely High (60% and above)'
// ];

const PRDUCTIVTY_LOSS_GROUPS = ['Low', 'Medium', 'High', 'Extreme'];

const getProductivityGroupData = () =>
  PRDUCTIVTY_LOSS_GROUPS.map((group) => {
    return {
      name: group,
      data: faker.datatype.number({
        min: 0.0,
        max: 10.0,
        precision: 1
      })
    };
  });

const productivityGroupOverallData = PRDUCTIVTY_LOSS_GROUPS.map((group) => {
  return {
    name: group,
    data: faker.datatype.number({
      min: 10.0,
      max: 20.0,
      precision: 1
    })
  };
});

const WLQ_SEGMENTS = [
  'Time Management',
  'Physical Demands',
  'Mental Demands',
  'Output Demands'
];

const productivityData = {
  data: {
    departments: DEPARTMENTS.map((department) => {
      return {
        name: department,
        overall: faker.datatype.number({
          min: 10.0,
          max: 45.0,
          precision: 1
        }),
        // overall: [...productivityGroupOverallData],
        // data: reverse(getProductivityHistory()),
        data: [...getProductivityGroupData()]
      };
    }),
    segments: DEPARTMENTS.map((d) => ({
      name: d,
      data: WLQ_SEGMENTS.map((segment) => ({
        name: segment,
        current: faker.datatype.number({
          min: 45.0,
          max: 90.0,
          precision: 1
        }),
        previous: faker.datatype.number({
          min: 35.0,
          max: 80.0,
          precision: 1
        })
      }))
    }))
  }
};

const employees = [
  'Tyler Brady',
  'Billy Harper',
  'Mia Chambers',
  'Toby Khan',
  'Harry Murphy',
  'Louis Thomas',
  'Harrison Townsend',
  'Sienna Allen',
  'Lily Sullivan',
  'Thomas Robson',
  'Kate Gray',
  'Oliver Reid',
  'Samuel Clayton',
  'Maisie Jackson',
  'Harley Rowe',
  'Jamie Pearson',
  'Liam Farrell',
  "Adam O'Sullivan",
  'Isabella Welch',
  'Aaron Flynn',
  'Evan Kennedy',
  'Ellis Burgess',
  'Cameron Akhtar',
  'Lydia Mellor',
  'Rachel Howarth',
  'Archie Reynolds',
  'Chelsea Bolton',
  'Eva Moss',
  'Anthony Macdonald',
  'Sienna Whitehead'
];

const dailyAbsences = [];
const dailyPulseWorkplaceLabels = ['Home', 'Office', 'Leave', 'No Response'];
const dailyPulseMoodSegments = ['Positive', 'Neutral', 'Negative'];
const dailyPulseWorkplaceSegments = [
  'Home',
  'Office',
  'Client',
  'University',
  'Leave'
];
const covid19Effects = [
  'Directly Affected',
  'In-Directly Affected',
  'Not Affected'
];

faker.datatype.number({
  max: 75.0,
  min: 10.0,
  precision: 1
});

const getRandomMaximum = (department) => {
  return faker.datatype.number({
    max: department === 'Overall' ? 30 : 20,
    min: department === 'Overall' ? 10 : 3,
    precision: 1
  });
};

const getAbsences = (department) => {
  return sampleSize(
    employees,
    department === 'Overall'
      ? getRandomMaximum(department)
      : getRandomMaximum(department)
  );
};

const getAbsencesHistory = (department) => {
  const prevDate = subBusinessDays(new Date(), 1);

  const noResponses = [...Array(14)].map((_, index) => {
    return {
      date: subBusinessDays(prevDate, 1 * index),
      data: faker.datatype.number({
        max: department === 'Overall' ? 100 : 25,
        min: 10,
        precision: 1
      }),
      reason: 'No Response',
      absenses: getAbsences(department)
    };
  });
  const leaves = [...Array(14)].map((_, index) => {
    return {
      date: subBusinessDays(prevDate, 1 * index),
      data: faker.datatype.number({
        max: department === 'Overall' ? 18 : 5,
        min: 2,
        precision: 1
      }),
      reason: 'Leave',
      absenses: getAbsences(department)
    };
  });
  return [...noResponses, ...leaves];
};

const dailyPulseData = {
  data: DEPARTMENTS.map((department) => {
    return {
      name: department,
      date: new Date(),
      data: [
        {
          name: 'Workplace',
          data: dailyPulseWorkplaceSegments.map((workplace) => {
            return {
              name: workplace,
              data: faker.datatype.number({
                max: 20.0,
                min: 5.0,
                precision: 1
              })
            };
          })
        },
        {
          name: 'Mood',
          data: dailyPulseMoodSegments.map((mood) => {
            return {
              name: mood,
              data: faker.datatype.number({
                max: 10.0,
                min: 5.0,
                precision: 1
              })
            };
          })
        },
        {
          name: 'COVID-19',
          data: covid19Effects.map((covid) => {
            return {
              name: covid,
              data: faker.datatype.number({
                max: 8.0,
                min: 0.0,
                precision: 1
              })
            };
          })
        },
        {
          name: 'Absences',
          history: reverse(getAbsencesHistory(department))
        }
      ]
    };
  })
};

const workplaceData = {
  data: ['Overall'].map((department) => {
    return {
      name: department,
      data: [...Array(300)]
        .map((_) => ({
          firstName: faker.name.firstName(),
          lastName: faker.name.lastName()
        }))
        .map((employee) => {
          return {
            firstName: employee.firstName,
            lastName: employee.lastName,
            email: `${employee.firstName.toLowerCase()}@experienz.co.uk`,
            workplace: sample(dailyPulseWorkplaceLabels)
          };
        })
    };
  })
};

const ENGAGEMENT_SEGMENTS = [
  'Highly Engaged',
  'Moderately Engaged',
  'Disengaged'
];

const EMPLOYEE_ENGAGEMENT = {
  data: {
    overall: {
      current: faker.datatype.number({
        max: 90.0,
        min: 60.0,
        precision: 1
      }),
      previous: faker.datatype.number({
        max: 70.0,
        min: 60.0,
        precision: 1
      })
    },
    departments: DEPARTMENTS.map((d) => ({
      name: d,
      data: ENGAGEMENT_SEGMENTS.map((s) => ({
        name: s,
        data: faker.datatype.number({
          max: 60.0,
          min: 30.0,
          precision: 1
        })
      }))
    }))
  }
};

mock
  .onGet('/api/insights/workplace-well-being')
  .reply(200, { ...workplaceWellBeing });

//for app usage
mock.onGet('/api/insights/application-usage').reply(200, { ...appUsage });

//for app usage details
mock
  .onGet('/api/insights/application-usage/details')
  .reply(200, { ...appUsageDetails });

//for financial outlook
mock
  .onGet('/api/insights/financial-outlook')
  .reply(200, { ...financialOutlook });

//for  content stats
mock.onGet('/api/insights/content-stats').reply(200, { ...contentStats });

//for productivity stats
mock.onGet('/api/insights/productivity').reply(200, { ...productivityData });

//for daily pulse stats
mock.onGet('/api/insights/daily-pulse').reply(200, { ...dailyPulseData });

//for daily pulse stats

mock.onGet('/api/insights/daily-pulse-workplaces').reply(async () => {
  await fakeRequest(4000);
  return [200, { ...workplaceData }];
  // return [200, { data: [] }];
});
//for daily filters
mock.onGet('/api/insights/filters').reply(200, {
  ...{ data: [{ name: 'location', options: [...REGIONS] }] }
});
//for employee engagment
mock.onGet('/api/insights/employee-engagement').reply(200, {
  ...EMPLOYEE_ENGAGEMENT
});
