import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  schedules: [{ name: '', questionnaireId: '', schedules: [] }],
  standardQuestionnaires: [{ name: '', id: '' }],
  polls: [
    {
      id: '',
      date: `${new Date()}`,
      poll: '',
      options: [{ name: '', data: 0 }]
    }
  ],
  locations: [],
  departments: []
};

const slice = createSlice({
  name: 'surveys',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SCHEDULES SUCCESS
    getSchedulesSuccess(state, action) {
      state.isLoading = false;
      state.schedules = action.payload;
      if (Array.isArray(action.payload)) {
        state.standardQuestionnaires = action.payload.map((q) => ({
          name: q.name,
          id: q.id
        }));
      }
    },

    // GET POLLS SUCCESS
    getRecentPollsSuccess(state, action) {
      state.isLoading = false;
      state.polls = action.payload;
    },
    //GET DEPARTMENTS
    getDepartmentsSuccess(state, action) {
      state.isLoading = false;
      state.departments = action.payload;
    },
    //GET Locations
    getLocationsSuccess(state, action) {
      state.isLoading = false;
      state.locations = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getConfiguredSchedulesForStandardQuestionnaires() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/survey/standard');
      dispatch(slice.actions.getSchedulesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError());
    }
  };
}

// ----------------------------------------------------------------------

export function getRecentPolls() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/survey/polls');
      const departments = await axios.get('/departments');
      const locations = await axios.get('/settings/locations');
      dispatch(slice.actions.getRecentPollsSuccess(response.data.data));
      dispatch(slice.actions.getDepartmentsSuccess(departments.data.data));
      dispatch(slice.actions.getLocationsSuccess(locations.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError());
    }
  };
}

// ----------------------------------------------------------------------

export async function createSurveySchedule(data) {
  try {
    const response = await axios.post('/survey/standard', { ...data });
    return response;
  } catch (error) {
    return { statusCode: -1 };
  }
}

// ----------------------------------------------------------------------

export async function deleteSchedule(scheduleId) {
  try {
    const response = await axios.delete(`/survey/schedule/${scheduleId}`);
    return response;
  } catch (error) {
    return { statusCode: -1 };
  }
}

// ----------------------------------------------------------------------

export async function createPoll(data) {
  try {
    const response = await axios.post(`/survey/polls`, {
      ...data
    });
    return response;
  } catch (error) {
    return { statusCode: -1 };
  }
}
