import mock from '../utils/mock';
import fakeRequest from '../utils/fakeRequest';
import faker from 'faker';
import { checkUndefinedOrNull } from 'src/utils/functions';

const EVENTS_LIST = [
  {
    id: 1,
    name: 'F1',
    description:
      "Formula One is the highest class of international racing for open-wheel single-seater formula racing cars sanctioned by the Fédération Internationale de l'Automobile.",
    segment: 'FIA',
    status: 1,
    from: faker.date.past(),
    to: faker.date.past(),
    createdOn: faker.date.past(),
    modifiedOn: faker.date.past()
  },

  {
    id: 2,
    name: 'MotoGP',
    description:
      'Grand Prix motorcycle racing is the premier class of motorcycle road racing events held on road circuits sanctioned by the Fédération Internationale de Motocyclisme.',
    segment: '',
    status: 1,
    from: faker.date.past(),
    to: faker.date.past(),
    createdOn: faker.date.past(),
    modifiedOn: faker.date.past()
  },
  {
    id: 3,
    name: 'Performers’ Night',
    description: '',
    segment: 'Silverstone Festival',
    status: 1,
    from: faker.date.past(),
    to: faker.date.past(),
    createdOn: faker.date.past(),
    modifiedOn: faker.date.past()
  },
  {
    id: 4,
    name: 'F2',
    description:
      "Formula One is the highest class of international racing for open-wheel single-seater formula racing cars sanctioned by the Fédération Internationale de l'Automobile.",
    segment: 'FIA',
    status: 1,
    from: faker.date.past(),
    to: faker.date.past(),
    createdOn: faker.date.past(),
    modifiedOn: faker.date.past()
  },
  {
    id: 5,
    name: 'Last Year',
    description: '',
    segment: 'Default',
    status: 1,
    from: '',
    to: '',
    createdOn: faker.date.past(),
    modifiedOn: faker.date.past()
  },
  {
    id: 6,
    name: 'Last Month',
    description: '',
    segment: 'Default',
    status: 1,
    from: '',
    to: '',
    createdOn: faker.date.past(),
    modifiedOn: faker.date.past()
  },
  {
    id: 7,
    name: 'Last Quarter',
    description: '',
    segment: 'Default',
    status: 1,
    from: '',
    to: '',
    createdOn: faker.date.past(),
    modifiedOn: faker.date.past()
  }
];

const events = { data: [...EVENTS_LIST] };

// ----------------------------------------------------------------------

mock.onGet('api/custom-calendar/event').reply(async (config) => {
  await fakeRequest(1000);
  try {
    return [200, { ...events }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

// ----------------------------------------------------------------------

mock.onPost('api/custom-calendar/event').reply(async (request) => {
  await fakeRequest(1000);
  try {
    const eventData = JSON.parse(request.data);
    if (!checkUndefinedOrNull(eventData)) {
      const event = { ...eventData, createdOn: faker.date.past() };
      events.data = [...events.data, event];
    }
    return [
      200,
      {
        data: {
          NumberOfInvitationSent: 30,
          NumberOfExistingCustomerAttached: 20
        }
      }
    ];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

// ----------------------------------------------------------------------

mock.onPut(/^\/api\/custom-calendar\/event\/[^\/]+/).reply(async (request) => {
  await fakeRequest(1000);
  try {
    const eventData = JSON.parse(request.data);
    if (!checkUndefinedOrNull(eventData)) {
      const event = { ...eventData, createdOn: faker.date.past() };
      events.data = [...events.data, event];
    }
    return [200, {}];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});
