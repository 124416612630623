import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  otp: '',
  userName: '',
  sent: false,
  isVerified: false
};

const slice = createSlice({
  name: 'forgotpassword',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // START LOADING
    resentCodeLoading(state) {
      state.resentLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // INITIATE FORGOT PASSWORD
    initiateForgotSuccess(state, action) {
      state.userName = action.payload;
      state.resentLoading = false;
      state.isLoading = false;
    },

    // VERIFY SUCCESS
    getVerifySuccess(state, action) {
      state.isLoading = false;
      state.otp = action.payload;
    },

    // CHANGE FORGOT PASSWORD
    changeForgotPasswordSuccess(state, action) {
      state.isLoading = false;
      state.resentLoading = false;
    },
    //save verified state
    saveEmailSentState(state, action) {
      state.sent = action.payload.sent;
    },
    //save verified state
    saveVerifiedState(state, action) {
      state.isVerified = action.payload.verified;
    },
    saveOtp(state, action) {
      state.otp = action.payload.otp;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { saveEmailSentState, saveVerifiedState, saveOtp } = slice.actions;

// ----------------------------------------------------------------------

export function initiateForgotPassword(userName) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.resentCodeLoading());
    try {
      const response = await axios.get(
        `/user/forgot-password?username=${encodeURIComponent(userName)}`
      );
      dispatch(slice.actions.initiateForgotSuccess(userName));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getVerified(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/user/verify-otp', {
        ...data
      });

      dispatch(slice.actions.getVerifySuccess(response.data.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function changeForgotPassword(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post('/user/forgot-password', {
        ...data
      });

      dispatch(slice.actions.changeForgotPasswordSuccess(response.data.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------
