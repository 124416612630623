// utils
import mock from '../utils/mock';
import fakeRequest from '../utils/fakeRequest';
import { reject } from 'lodash';
import { PATH_DASHBOARD } from 'src/routes/paths';

const SEARCH_RESPONSE = {
  measures: ['esg.total'],
  timeDimensions: [
    {
      dimension: 'esg.date',
      granularity: 'quarter'
    }
  ],
  dimensions: ['esg.subCategory'],
  segments: ['esg.environment'],
  order: [{ id: 'esg.date', desc: false }],
  filters: [
    {
      member: 'esg.category',
      operator: 'equals',
      values: ['emission']
    }
  ],
  chartType: 'Bar Chart'
};

const PERSPECTIVES = {
  defaultDateRange: 'This year',
  views: [
    {
      viewId: 11,
      viewTitle: 'Supplier Emissions',
      viewDescription:
        '<p style="font-size: 16px">scope 3 emissions are a consequence of the activities of the company but occur from sources not owned or controlled by it.</p><br/>',
      bookmarked: false,
      isSupplier: true,
      userGroups: ['Supplier_Managers']
    },
    {
      viewId: 12,
      viewTitle: 'suppliers',
      viewDescription:
        '<p style="font-size: 16px">scope 3 emissions are a consequence of the activities of the company but occur from sources not owned or controlled by it.</p><br/>',
      bookmarked: false,
      isSupplier: false,
      userGroups: ['Supplier_Managers']
    },
    {
      viewId: 14,
      viewTitle: 'Environment',
      viewDescription:
        '<p style="font-size: 16px">scope 3 emissions are a consequence of the activities of the company but occur from sources not owned or controlled by it.</p><br/>',
      bookmarked: false,
      isSupplier: false,
      userGroups: ['Supplier_Managers']
    },
    {
      viewId: 15,
      viewTitle: 'Summary',
      viewDescription:
        '<p style="font-size: 16px">scope 3 emissions are a consequence of the activities of the company but occur from sources not owned or controlled by it.</p><br/>',
      bookmarked: false,
      isSupplier: false,
      userGroups: ['Supplier_Managers']
    },
    {
      viewId: 10,
      viewTitle: 'Overview of GHG Emissions',
      viewDescription: null,
      bookmarked: true,
      isSupplier: false,
      userGroups: []
    },
    {
      viewId: 1,
      viewTitle: 'Employee Turnover DashBoard',
      viewDescription:
        '<p>A common definition of employee turnover is the loss of talent in the workforce over time. This includes any employee departure, including resignations, layoffs, terminations, retirements, location transfers, or even deaths.Businesses often calculate their rate of employee turnover as a means of predicting the impact on productivity, customer service, or even morale. The U.S. Bureau of Labor Statistics (BLS) also calculates employee turnover at a national level.</p>',
      bookmarked: false,
      isSupplier: false,
      userGroups: []
    },
    {
      viewId: 2,
      viewTitle: 'Environment - Energy',
      viewDescription: '<p><br/><p>',
      bookmarked: true,
      isSupplier: false,
      userGroups: []
    },
    {
      viewId: 3,
      viewTitle: 'Environment - Waste',
      viewDescription: '<p><br/><p>',
      bookmarked: false,
      isSupplier: false,
      userGroups: []
    },
    {
      viewId: 4,
      viewTitle: 'Environment - Water Usage',
      viewDescription: '<p><br/><p>',
      bookmarked: false,
      isSupplier: false,
      userGroups: []
    },
    {
      viewId: 5,
      viewTitle: 'Environment - Carbon Footprint',
      viewDescription: '<p><br/><p>',
      bookmarked: false,
      isSupplier: false,
      userGroups: []
    },
    {
      viewId: 9,
      viewTitle: 'Social - Diversity',
      viewDescription: null,
      bookmarked: true,
      isSupplier: false,
      userGroups: []
    },
    {
      viewId: 6,
      viewTitle: 'Environment',
      viewDescription: null,
      bookmarked: false,
      isSupplier: false,
      userGroups: []
    },
    {
      viewId: 7,
      viewTitle: 'Social',
      viewDescription: null,
      bookmarked: false,
      isSupplier: false,
      userGroups: []
    },
    {
      viewId: 8,
      viewTitle: 'Governance',
      viewDescription: null,
      bookmarked: true,
      isSupplier: false,
      userGroups: []
    }
  ]
};
// {
//   viewId: 2,
//   viewTitle: 'GHG Emissions',
//   viewDescription:
//     "<p>GHG emissions are often measured in carbon dioxide (CO2) equivalent. To convert emissions of a gas into CO2 equivalent, its emissions are multiplied by the gas's Global Warming Potential (GWP). The GWP takes into account the fact that many gases are more effective at warming Earth than CO2, per unit mass.</p>"
// },
// {
//   viewId: 3,
//   viewTitle: 'Diversity',
//   viewDescription:
//     '<p>A successful community in which individuals of different race, ethnicity, religious beliefs, socioeconomic status, language, geographical origin, gender and/or sexual orientation bring their different knowledge, background, experience and interest for the benefit of their diverse community.</p>'
// },
// {
//   viewId: 4,
//   viewTitle: 'Business Management',
//   viewDescription:
//     '<p>Deliver and pitch your topic in the best possible manner with this enterprise governance business management dashboards mar download pdf. Use them to share invaluable insights on actual expenses vs budget, actual income vs budget and impress your audience. This template can be altered and modified as per your expectations. So, grab it now. Equalize the equation with our Enterprise Governance Business Management Dashboards Mar Download PDF. Get the audience on board with you.</p>'
// }

const COMPONENTS = [
  {
    pId: 2,
    layouts: {
      lg: '{"x":0,"y":0,"w":24,"h":14}',
      md: '{"x":0,"y":0,"w":24,"h":14}',
      sm: '{"x":0,"y":0,"w":12,"h":14}',
      xs: '{"x":0,"y":0,"w":8,"h":13}',
      xxs: '{"x":0,"y":0,"w":8,"h":13}'
    },
    title: 'Energy use (kg of oil equivalent per capita)',
    description:
      '<p style="font-size:10px; margin-top: 10px">Energy use in kgs per capita. Reference data for South Asia (provided by <a href="https://databank.worldbank.org/reports.aspx?source=3711&series=EG.USE.PCAP.KG.OE&country=EAS,SAS,MEA,SSF,LCN,ECS,NAC" target="_blank">worldbank.org</a>) <br/><a href="https://sdgs.un.org/goals/goal7" target="_blank">UN SDG Goal 7</a>: Ensure access to affordable, reliable, sustainable and modern energy for all</p>',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData', 'esg.target'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 524.2,
        'esg.refData': 450.5,
        'esg.target': 420.5
      },
      {
        'esg.kpi': 2018,
        'esg.data': 570.1,
        'esg.refData': 520.5,
        'esg.target': 528.5
      },
      {
        'esg.kpi': 2019,
        'esg.data': 650.2,
        'esg.refData': 500.0,
        'esg.target': 645.5
      },
      {
        'esg.kpi': 2020,
        'esg.data': 673.2,
        'esg.refData': 0,
        'esg.target': 610.5
      },
      {
        'esg.kpi': 2021,
        'esg.data': 720.3,
        'esg.refData': 0,
        'esg.target': 647.5
      }
    ],
    chartType: 'mixed',
    showRefData: true,
    barWidth: 1,
    colorPalette: 'palette8',
    fontSize: 12,
    monoChromeColors: false,
    monoChromeBaseColor: 'success',
    showZeros: false,
    curveType: 'straight'
  },
  {
    pId: 2,
    layouts: {
      lg: '{"x":0,"y":14,"w":12,"h":13}',
      md: '{"x":0,"y":14,"w":12,"h":13}',
      sm: '{"x":0,"y":14,"w":6,"h":13}',
      xs: '{"x":0,"y":14,"w":4,"h":13}',
      xxs: '{"x":0,"y":14,"w":4,"h":13}'
    },
    title: 'Total electricity used (Kwh/Mwh)',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 4231
      },
      {
        'esg.kpi': 2018,
        'esg.data': 5021
      },
      {
        'esg.kpi': 2019,
        'esg.data': 4710
      },
      {
        'esg.kpi': 2020,
        'esg.data': 4089
      },
      {
        'esg.kpi': 2021,
        'esg.data': 4896
      }
    ],
    chartType: 'bar',
    showRefData: false,
    hideYAxis: false
  },
  {
    pId: 2,
    layouts: {
      lg: '{"x":13,"y":14,"w":12,"h":13}',
      md: '{"x":13,"y":14,"w":12,"h":13}',
      sm: '{"x":7,"y":14,"w":6,"h":13}',
      xs: '{"x":0,"y":14,"w":4,"h":13}',
      xxs: '{"x":0,"y":14,"w":4,"h":13}'
    },
    title: 'Total natural gas used (Kwh/Mwh)',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 2860
      },
      {
        'esg.kpi': 2018,
        'esg.data': 3830
      },
      {
        'esg.kpi': 2019,
        'esg.data': 3040
      },
      {
        'esg.kpi': 2020,
        'esg.data': 3460
      },
      {
        'esg.kpi': 2021,
        'esg.data': 2010
      }
    ],
    chartType: 'bar',
    showRefData: false
  },
  {
    pId: 2,
    layouts: {
      lg: '{"x":0,"y":28,"w":12,"h":13}',
      md: '{"x":0,"y":28,"w":12,"h":13}',
      sm: '{"x":0,"y":14,"w":6,"h":13}',
      xs: '{"x":0,"y":14,"w":4,"h":13}',
      xxs: '{"x":0,"y":14,"w":4,"h":13}'
    },
    title:
      'Renewable energy share in the total final energy consumption (Kwh/Mwh)',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 0
      },
      {
        'esg.kpi': 2018,
        'esg.data': 0
      },
      {
        'esg.kpi': 2019,
        'esg.data': 56
      },
      {
        'esg.kpi': 2020,
        'esg.data': 102
      },
      {
        'esg.kpi': 2021,
        'esg.data': 288
      }
    ],
    chartType: 'bar',
    showRefData: false
  },
  {
    pId: 2,
    layouts: {
      lg: '{"x":0,"y":28,"w":12,"h":18}',
      md: '{"x":0,"y":28,"w":12,"h":18}',
      sm: '{"x":0,"y":14,"w":6,"h":13}',
      xs: '{"x":0,"y":14,"w":4,"h":13}',
      xxs: '{"x":0,"y":14,"w":4,"h":13}'
    },
    title: 'Renewable Vs Electricity Usage Vs Natural Gas',
    description: 'Save me Save me Save me Save me Save me Save me ',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Electricity Electricity Electricity ',
        'esg.data': 40000.0
      },
      {
        'esg.kpi': 'Renewable',
        'esg.data': 10000.0
      },
      {
        'esg.kpi': 'Natural Gas',
        'esg.data': 50000.0
      },
      {
        'esg.kpi': 'Bio Gas',
        'esg.data': 500.0
      }
    ],
    chartType: 'progressTable',
    showRefData: false,
    color: 'info',
    unit: 'MtCO2e',
    fontSize: 12,
    showTitle: true,
    centeredTitle: 'Total Scope 1'
  },
  {
    pId: 3,
    layouts: {
      lg: '{"x":0,"y":0,"w":12,"h":13}',
      md: '{"x":0,"y":0,"w":12,"h":13}',
      sm: '{"x":0,"y":0,"w":6,"h":13}',
      xs: '{"x":0,"y":0,"w":4,"h":13}',
      xxs: '{"x":0,"y":0,"w":4,"h":13}'
    },
    title: 'Total waste generated (metric tons)',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 814520
      },
      {
        'esg.kpi': 2018,
        'esg.data': 123730
      },
      {
        'esg.kpi': 2019,
        'esg.data': 941270
      },
      {
        'esg.kpi': 2020,
        'esg.data': 356380
      },
      {
        'esg.kpi': 2021,
        'esg.data': 686000
      }
    ],
    chartType: 'bar',
    showRefData: false
  },
  {
    pId: 3,
    layouts: {
      lg: '{"x":14,"y":0,"w":12,"h":13}',
      md: '{"x":14,"y":0,"w":12,"h":13}',
      sm: '{"x":7,"y":0,"w":6,"h":13}',
      xs: '{"x":0,"y":0,"w":4,"h":13}',
      xxs: '{"x":0,"y":0,"w":4,"h":13}'
    },
    title: 'Total waste per production (kg/metric ton)',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 56
      },
      {
        'esg.kpi': 2018,
        'esg.data': 70
      },
      {
        'esg.kpi': 2019,
        'esg.data': 69
      },
      {
        'esg.kpi': 2020,
        'esg.data': 61
      },
      {
        'esg.kpi': 2021,
        'esg.data': 59
      }
    ],
    chartType: 'bar',
    showRefData: false
  },
  {
    pId: 3,
    layouts: {
      lg: '{"x":0,"y":14,"w":12,"h":13}',
      md: '{"x":0,"y":14,"w":12,"h":13}',
      sm: '{"x":0,"y":14,"w":6,"h":13}',
      xs: '{"x":0,"y":14,"w":4,"h":13}',
      xxs: '{"x":0,"y":14,"w":4,"h":13}'
    },
    title: 'Total landfill waste (%)',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 5
      },
      {
        'esg.kpi': 2018,
        'esg.data': 5
      },
      {
        'esg.kpi': 2019,
        'esg.data': 7
      },
      {
        'esg.kpi': 2020,
        'esg.data': 7
      },
      {
        'esg.kpi': 2021,
        'esg.data': 6
      }
    ],
    chartType: 'bar',
    showRefData: false
  },
  {
    pId: 3,
    layouts: {
      lg: '{"x":13,"y":14,"w":12,"h":13}',
      md: '{"x":13,"y":14,"w":12,"h":13}',
      sm: '{"x":7,"y":14,"w":6,"h":13}',
      xs: '{"x":0,"y":14,"w":4,"h":13}',
      xxs: '{"x":0,"y":14,"w":4,"h":13}'
    },
    title: 'Electronic waste generated (Tonnes)',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 8
      },
      {
        'esg.kpi': 2018,
        'esg.data': 7
      },
      {
        'esg.kpi': 2019,
        'esg.data': 7
      },
      {
        'esg.kpi': 2020,
        'esg.data': 8
      },
      {
        'esg.kpi': 2021,
        'esg.data': 7
      }
    ],
    chartType: 'bar',
    showRefData: false
  },
  {
    pId: 4,
    layouts: {
      lg: '{"x":0,"y":0,"w":24,"h":13}',
      md: '{"x":0,"y":0,"w":24,"h":13}',
      sm: '{"x":0,"y":0,"w":12,"h":13}',
      xs: '{"x":0,"y":0,"w":4,"h":13}',
      xxs: '{"x":0,"y":0,"w":4,"h":13}'
    },
    title: 'Total water usage',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 6641760
      },
      {
        'esg.kpi': 2018,
        'esg.data': 6254897
      },
      {
        'esg.kpi': 2019,
        'esg.data': 5645345
      },
      {
        'esg.kpi': 2020,
        'esg.data': 4987453
      },
      {
        'esg.kpi': 2021,
        'esg.data': 4481760
      }
    ],
    chartType: 'bar',
    showRefData: false
  },
  {
    pId: 4,
    layouts: {
      lg: '{"x":0,"y":14,"w":24,"h":20}',
      md: '{"x":0,"y":14,"w":24,"h":20}',
      sm: '{"x":0,"y":14,"w":12,"h":20}',
      xs: '{"x":0,"y":14,"w":4,"h":20}',
      xxs: '{"x":0,"y":14,"w":4,"h":20}'
    },
    title: 'Water usage details by type (%)',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi'],
      timeDimensions: [
        {
          dimension: 'esg.date',
          granularity: 'year'
        }
      ]
    },
    data: [
      {
        'esg.date': '2017-01-01T00:00:00.000',
        'esg.date.year': '2017-01-01T00:00:00.000',
        'esg.kpi': 'Primary water usage',
        'esg.data': 90
      },
      {
        'esg.date': '2018-01-01T00:00:00.000',
        'esg.date.year': '2018-01-01T00:00:00.000',
        'esg.kpi': 'Primary water usage',
        'esg.data': 87
      },
      {
        'esg.date': '2019-01-01T00:00:00.000',
        'esg.date.year': '2019-01-01T00:00:00.000',
        'esg.kpi': 'Primary water usage',
        'esg.data': 60
      },
      {
        'esg.date': '2020-01-01T00:00:00.000',
        'esg.date.year': '2020-01-01T00:00:00.000',
        'esg.kpi': 'Primary water usage',
        'esg.data': 55
      },
      {
        'esg.date': '2021-01-01T00:00:00.000',
        'esg.date.year': '2021-01-01T00:00:00.000',
        'esg.kpi': 'Primary water usage',
        'esg.data': 45
      },
      {
        'esg.date': '2017-01-01T00:00:00.000',
        'esg.date.year': '2017-01-01T00:00:00.000',
        'esg.kpi': 'Recycled water',
        'esg.data': 10
      },
      {
        'esg.date': '2018-01-01T00:00:00.000',
        'esg.date.year': '2018-01-01T00:00:00.000',
        'esg.kpi': 'Recycled water',
        'esg.data': 13
      },
      {
        'esg.date': '2019-01-01T00:00:00.000',
        'esg.date.year': '2019-01-01T00:00:00.000',
        'esg.kpi': 'Recycled water',
        'esg.data': 35
      },
      {
        'esg.date': '2020-01-01T00:00:00.000',
        'esg.date.year': '2020-01-01T00:00:00.000',
        'esg.kpi': 'Recycled water',
        'esg.data': 70
      },
      {
        'esg.date': '2021-01-01T00:00:00.000',
        'esg.date.year': '2021-01-01T00:00:00.000',
        'esg.kpi': 'Recycled water',
        'esg.data': 85
      },
      {
        'esg.date': '2017-01-01T00:00:00.000',
        'esg.date.year': '2017-01-01T00:00:00.000',
        'esg.kpi': 'Rain water harvesting',
        'esg.data': 0
      },
      {
        'esg.date': '2018-01-01T00:00:00.000',
        'esg.date.year': '2018-01-01T00:00:00.000',
        'esg.kpi': 'Rain water harvesting',
        'esg.data': 0
      },
      {
        'esg.date': '2019-01-01T00:00:00.000',
        'esg.date.year': '2019-01-01T00:00:00.000',
        'esg.kpi': 'Rain water harvesting',
        'esg.data': 5
      },
      {
        'esg.date': '2020-01-01T00:00:00.000',
        'esg.date.year': '2020-01-01T00:00:00.000',
        'esg.kpi': 'Rain water harvesting',
        'esg.data': 5
      },
      {
        'esg.date': '2021-01-01T00:00:00.000',
        'esg.date.year': '2021-01-01T00:00:00.000',
        'esg.kpi': 'Rain water harvesting',
        'esg.data': 10
      }
    ],
    chartType: 'line',
    showRefData: false,
    barWidth: 1,
    colorPalette: 'palette8',
    fontSize: 12,
    monoChromeColors: false,
    monoChromeBaseColor: 'success',
    showZeros: true,
    hideXAxis: false,
    hideYAxis: true,
    showDataLabels: false,
    enableMonoChromeColors: false,
    tooltipsEnabled: true,
    skewLabels: false,
    unit: '%',
    markerSize: 7,
    paddingOverrides: { left: 60, right: 60 }
  },
  {
    pId: 5,
    layouts: {
      lg: '{"x":0,"y":0,"w":12,"h":13}',
      md: '{"x":0,"y":0,"w":12,"h":13}',
      sm: '{"x":0,"y":0,"w":6,"h":13}',
      xs: '{"x":0,"y":0,"w":4,"h":13}',
      xxs: '{"x":0,"y":0,"w":4,"h":13}'
    },
    title: 'Manufacturing (%)',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 29
      },
      {
        'esg.kpi': 2018,
        'esg.data': 25
      },
      {
        'esg.kpi': 2019,
        'esg.data': 27
      },
      {
        'esg.kpi': 2020,
        'esg.data': 29
      },
      {
        'esg.kpi': 2021,
        'esg.data': 29
      }
    ],
    chartType: 'pie',
    showRefData: false,
    monoChromeColors: true
  },
  {
    pId: 5,
    layouts: {
      lg: '{"x":13,"y":0,"w":12,"h":13}',
      md: '{"x":13,"y":0,"w":12,"h":13}',
      sm: '{"x":7,"y":0,"w":6,"h":13}',
      xs: '{"x":0,"y":0,"w":4,"h":13}',
      xxs: '{"x":0,"y":0,"w":4,"h":13}'
    },
    title: 'CO2 emissions (Tons)',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 0.73
      },
      {
        'esg.kpi': 2018,
        'esg.data': 0.84
      },
      {
        'esg.kpi': 2019,
        'esg.data': 0.75
      },
      {
        'esg.kpi': 2020,
        'esg.data': 0.62
      },
      {
        'esg.kpi': 2021,
        'esg.data': 0.7
      }
    ],
    chartType: 'area',
    showRefData: false
  },
  {
    pId: 6,
    layouts: {
      lg: '{"x":0,"y":0,"w":6,"h":10}',
      md: '{"x":0,"y":0,"w":6,"h":10}',
      sm: '{"x":0,"y":0,"w":3,"h":10}',
      xs: '{"x":0,"y":0,"w":4,"h":10}',
      xxs: '{"x":0,"y":0,"w":4,"h":10}'
    },
    title: 'Renewable energy usage ',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 0.8
      }
    ],
    chartType: 'percentageRadial',
    chartHeight: 240,
    showRefData: false
  },
  {
    pId: 6,
    layouts: {
      lg: '{"x":6,"y":0,"w":6,"h":10}',
      md: '{"x":6,"y":0,"w":6,"h":10}',
      sm: '{"x":3,"y":0,"w":3,"h":10}',
      xs: '{"x":0,"y":0,"w":4,"h":10}',
      xxs: '{"x":0,"y":0,"w":4,"h":10}'
    },
    title: 'Reduction in CO2 emission from manufacturing',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 0.65
      }
    ],
    chartType: 'percentageRadial',
    radialSize: 68,
    chartHeight: 250,
    fontSize: 12,
    showRefData: false
  },
  {
    pId: 6,
    layouts: {
      lg: '{"x":12,"y":0,"w":6,"h":10}',
      md: '{"x":12,"y":0,"w":6,"h":10}',
      sm: '{"x":6,"y":0,"w":3,"h":10}',
      xs: '{"x":0,"y":0,"w":4,"h":10}',
      xxs: '{"x":0,"y":0,"w":4,"h":10}'
    },
    title: 'Reduction in priority water usage',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 0.69
      }
    ],
    chartType: 'percentageRadial',
    showRefData: false
  },
  {
    pId: 6,
    layouts: {
      lg: '{"x":18,"y":0,"w":6,"h":10}',
      md: '{"x":18,"y":0,"w":6,"h":10}',
      sm: '{"x":9,"y":0,"w":3,"h":10}',
      xs: '{"x":0,"y":0,"w":4,"h":10}',
      xxs: '{"x":0,"y":0,"w":4,"h":10}'
    },
    title: 'Reduction of waste from manufacturing',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 0.51
      }
    ],
    chartType: 'percentageRadial',
    showRefData: false
  },
  {
    pId: 7,
    layouts: {
      lg: '{"x":0,"y":0,"w":6,"h":10}',
      md: '{"x":0,"y":0,"w":6,"h":10}',
      sm: '{"x":0,"y":0,"w":3,"h":10}',
      xs: '{"x":0,"y":0,"w":4,"h":10}',
      xxs: '{"x":0,"y":0,"w":4,"h":10}'
    },
    title: 'Revenue invested in employee training',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 0.63
      }
    ],
    chartType: 'percentageRadial',
    showRefData: false
  },
  {
    pId: 7,
    layouts: {
      lg: '{"x":6,"y":0,"w":6,"h":10}',
      md: '{"x":6,"y":0,"w":6,"h":10}',
      sm: '{"x":3,"y":0,"w":3,"h":10}',
      xs: '{"x":0,"y":0,"w":4,"h":10}',
      xxs: '{"x":0,"y":0,"w":4,"h":10}'
    },
    title: 'Absenteeism',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 0.82
      }
    ],
    chartType: 'percentageRadial',
    showRefData: false
  },
  {
    pId: 7,
    layouts: {
      lg: '{"x":12,"y":0,"w":6,"h":10}',
      md: '{"x":12,"y":0,"w":6,"h":10}',
      sm: '{"x":6,"y":0,"w":3,"h":10}',
      xs: '{"x":0,"y":0,"w":4,"h":10}',
      xxs: '{"x":0,"y":0,"w":4,"h":10}'
    },
    title: 'New hires',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 0.69
      }
    ],
    chartType: 'percentageRadial',
    showRefData: false
  },
  {
    pId: 7,
    layouts: {
      lg: '{"x":18,"y":0,"w":6,"h":10}',
      md: '{"x":18,"y":0,"w":6,"h":10}',
      sm: '{"x":9,"y":0,"w":3,"h":10}',
      xs: '{"x":0,"y":0,"w":4,"h":10}',
      xxs: '{"x":0,"y":0,"w":4,"h":10}'
    },
    title: 'Employee turnover',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 0.51
      }
    ],
    chartType: 'percentageRadial',
    showRefData: false,
    colorPalette: 'palette1',
    radialSize: 70
  },
  {
    pId: 8,
    layouts: {
      lg: '{"x":0,"y":0,"w":6,"h":10}',
      md: '{"x":0,"y":0,"w":6,"h":10}',
      sm: '{"x":0,"y":0,"w":3,"h":10}',
      xs: '{"x":0,"y":0,"w":4,"h":10}',
      xxs: '{"x":0,"y":0,"w":4,"h":10}'
    },
    title: 'Women in Leadership',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 63,
        'esg.refData': 200
      }
    ],
    chartType: 'percentageRadial',
    showRefData: true,
    showAsFraction: false,
    colorPalette: 'palette8',
    radialSize: 70
  },
  {
    pId: 8,
    layouts: {
      lg: '{"x":6,"y":0,"w":6,"h":10}',
      md: '{"x":6,"y":0,"w":6,"h":10}',
      sm: '{"x":3,"y":0,"w":3,"h":10}',
      xs: '{"x":0,"y":0,"w":4,"h":10}',
      xxs: '{"x":0,"y":0,"w":4,"h":10}'
    },
    title: 'Revenue from new products',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 0.82
      }
    ],
    chartType: 'percentageRadial',
    showRefData: false,
    colorPalette: 'palette3',
    radialSize: 70
  },
  // {
  //   pId: 8,
  //   layouts: {
  //     lg: '{"x":0,"y":14,"w":12,"h":13}',
  //     md: '{"x":0,"y":14,"w":12,"h":13}',
  //     sm: '{"x":0,"y":14,"w":6,"h":13}',
  //     xs: '{"x":0,"y":14,"w":4,"h":13}',
  //     xxs: '{"x":0,"y":14,"w":4,"h":13}'
  //   },
  //   title: 'Monthly Progress',
  //   description: null,
  //   unitType: 'PERCENT',
  //   query: {
  //     measures: ['esg.data', 'esg.refData'],
  //     dimensions: ['esg.kpi']
  //   },
  //   data: [
  //     {
  //       'esg.kpi': 'Jan',
  //       'esg.data': 0.65,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'Feb',
  //       'esg.data': 0.58,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'March',
  //       'esg.data': 0.83,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'April',
  //       'esg.data': 0.8,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'May',
  //       'esg.data': 0.6,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'June',
  //       'esg.data': 0.84,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'August',
  //       'esg.data': 0.5,
  //       'esg.refData': 1000
  //     }
  //   ],
  //   chartType: 'progressRadial',
  //   showRefData: false,
  //   showFractionAsLabel: false,
  //   sortByLabel: false
  // },
  // {
  //   pId: 4,
  //   layout: '{"x":0,"y":0,"w":12,"h":13}',
  //   title: 'Actual VS Benchmark work time in months per Divisic',
  //   description: null,
  //   unitType: 'PERCENT',
  //   query: {
  //     measures: ['esg.data'],
  //     dimensions: ['esg.kpi']
  //   },
  //   data: [
  //     {
  //       'esg.kpi': 'Actual Expenses vs Budget',
  //       'esg.data': 82.23
  //     }
  //   ],
  //   chartType: 'percentageRadial',
  //   showRefData: false
  // },
  // {
  //   pId: 4,
  //   layout: '{"x":12,"y":0,"w":12,"h":13}',
  //   title: 'Actual income VS Budget',
  //   description: null,
  //   unitType: 'PERCENT',
  //   query: {
  //     measures: ['esg.data'],
  //     dimensions: ['esg.kpi']
  //   },
  //   data: [
  //     {
  //       'esg.kpi': 'Actual income VS Budget',
  //       'esg.data': 58.76
  //     }
  //   ],
  //   chartType: 'percentageRadial',
  //   showRefData: false
  // },
  // {
  //   pId: 4,
  //   layout: '{"x":0,"y":9,"w":12,"h":13}',
  //   title: 'Actual Expenses VS Budget',
  //   description: null,
  //   unitType: 'NUMBER',
  //   query: {
  //     measures: ['esg.data', 'esg.refData'],
  //     dimensions: ['esg.kpi']
  //   },
  //   data: [
  //     {
  //       'esg.kpi': 'Jan',
  //       'esg.data': 650,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'Feb',
  //       'esg.data': 580,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'March',
  //       'esg.data': 800,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'April',
  //       'esg.data': 800,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'May',
  //       'esg.data': 600,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'June',
  //       'esg.data': 800,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'August',
  //       'esg.data': 500,
  //       'esg.refData': 1000
  //     }
  //   ],
  //   chartType: 'line',
  //   showRefData: true
  // },
  // {
  //   pId: 4,
  //   layouts: {
  //     lg: '{"x":12,"y":8,"w":12,"h":13}',
  //     md: '{"x":12,"y":8,"w":12,"h":13}',
  //     sm: '{"x":12,"y":8,"w":6,"h":13}',
  //     xs: '{"x":0,"y":8,"w":4,"h":13}',
  //     xxs: '{"x":0,"y":8,"w":4,"h":13}'
  //   },
  //   title: 'Actual Income VS Budget',
  //   description: null,
  //   unitType: 'NUMBER',
  //   query: {
  //     measures: ['esg.data', 'esg.refData'],
  //     dimensions: ['esg.kpi']
  //   },
  //   data: [
  //     {
  //       'esg.kpi': 'Jan',
  //       'esg.data': 800,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'Feb',
  //       'esg.data': 900,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'March',
  //       'esg.data': 950,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'April',
  //       'esg.data': 800,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'May',
  //       'esg.data': 900,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'June',
  //       'esg.data': 800,
  //       'esg.refData': 1000
  //     },
  //     {
  //       'esg.kpi': 'August',
  //       'esg.data': 950,
  //       'esg.refData': 1000
  //     }
  //   ],
  //   chartType: 'line',
  //   showRefData: true
  // },
  {
    pId: 1,
    layouts: {
      lg: '{"x":0,"y":9,"w":24,"h":13}',
      md: '{"x":0,"y":9,"w":24,"h":13}',
      sm: '{"x":0,"y":9,"w":6,"h":13}',
      xs: '{"x":0,"y":9,"w":4,"h":13}',
      xxs: '{"x":0,"y":9,"w":4,"h":13}'
    },
    title: 'Actual VS Benchmark work time in months per Divisic',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Customer Success',
        'esg.data': 20.23,
        'esg.refData': 29.33
      },
      {
        'esg.kpi': 'R&D',
        'esg.data': 20.65,
        'esg.refData': 28.2
      },
      {
        'esg.kpi': 'Marketing/Sales',
        'esg.data': 20.8,
        'esg.refData': 27.5
      },
      {
        'esg.kpi': 'Mobile/Web',
        'esg.data': 18.4,
        'esg.refData': 27
      },
      {
        'esg.kpi': 'IT',
        'esg.data': 14.34,
        'esg.refData': 24
      }
    ],
    chartType: 'bar',
    showRefData: true
  },
  {
    pId: 1,
    layouts: {
      lg: '{"x":13,"y":0,"w":24,"h":18}',
      md: '{"x":13,"y":0,"w":24,"h":18}',
      sm: '{"x":0,"y":0,"w":12,"h":18}',
      xs: '{"x":0,"y":0,"w":4,"h":13}',
      xxs: '{"x":0,"y":0,"w":4,"h":13}'
    },
    title: 'Turnover rate by Division',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Mobile/Web',
        'esg.data': 43.8,
        'esg.refData': 45
      },
      {
        'esg.kpi': 'IT',
        'esg.data': 36.8,
        'esg.refData': 40
      },
      {
        'esg.kpi': 'Customer',
        'esg.data': 33.6,
        'esg.refData': 35
      },
      {
        'esg.kpi': 'R&D',
        'esg.data': 32.5,
        'esg.refData': 30
      },
      {
        'esg.kpi': 'Marketing',
        'esg.data': 31.1,
        'esg.refData': 35
      }
    ],
    chartType: 'bar',
    showRefData: true
  },
  {
    pId: 1,
    layouts: {
      lg: '{"x":0,"y":0,"w":24,"h":13}',
      md: '{"x":0,"y":0,"w":12,"h":13}',
      sm: '{"x":0,"y":0,"w":6,"h":13}',
      xs: '{"x":0,"y":0,"w":4,"h":13}',
      xxs: '{"x":0,"y":0,"w":4,"h":13}'
    },
    title: 'Turnover rate Year over Year',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': '2018',
        'esg.data': 17.2,
        'esg.refData': 20
      },
      {
        'esg.kpi': '2019',
        'esg.data': 20.9,
        'esg.refData': 22
      },
      {
        'esg.kpi': '2020',
        'esg.data': 25.7,
        'esg.refData': 25
      },
      {
        'esg.kpi': '2021',
        'esg.data': 33.2,
        'esg.refData': 33.5
      }
    ],
    chartType: 'bar',
    showRefData: true
  },
  // {
  //   pId: 2,
  //   layouts: '{"x":0,"y":0,"w":12,"h":13}',
  //   title: 'GHG emission contributors',
  //   description: null,
  //   unitType: 'PERCENT',
  //   query: {
  //     measures: ['esg.data'],
  //     dimensions: ['esg.kpi']
  //   },
  //   data: [
  //     {
  //       'esg.kpi': 'Manufacturing',
  //       'esg.data': 10
  //     },
  //     { 'esg.kpi': 'Raw material', 'esg.data': 15 },
  //     { 'esg.kpi': 'Packaging', 'esg.data': 20 },
  //     { 'esg.kpi': 'Raw material', 'esg.data': 15 }
  //   ],
  //   chartType: 'pie',
  //   showRefData: false
  // },
  // {
  //   pId: 2,
  //   layout: '{"x":12,"y":0,"w":12,"h":13}',
  //   title: 'GHG emissions',
  //   description: null,
  //   unitType: 'NUMBER',
  //   query: {
  //     measures: ['esg.data', 'esg.refData'],
  //     dimensions: ['esg.kpi']
  //   },
  //   data: [
  //     { 'esg.kpi': 2018, 'esg.data': 88000, 'esg.refData': 80000 },
  //     { 'esg.kpi': 2019, 'esg.data': 80000, 'esg.refData': 72000 },
  //     { 'esg.kpi': 2020, 'esg.data': 65000, 'esg.refData': 62000 },
  //     { 'esg.kpi': 2021, 'esg.data': 60000, 'esg.refData': 55555 }
  //   ],
  //   chartType: 'line',
  //   showRefData: true
  // },
  // {
  //   pId: 2,
  //   layout: '{"x":0,"y":8,"w":12,"h":13}',
  //   title: 'Types of energy used',
  //   description: null,
  //   unitType: 'NUMBER',
  //   query: {
  //     measures: ['esg.data'],
  //     dimensions: ['esg.kpi']
  //   },
  //   data: [
  //     { 'esg.kpi': 'Renewable', 'esg.data': 15.3 },
  //     { 'esg.kpi': 'Brown Coal', 'esg.data': 26.6 },
  //     { 'esg.kpi': 'Nuclear', 'esg.data': 27.2 },
  //     { 'esg.kpi': 'Gas ', 'esg.data': 30.9 }
  //   ],
  //   chartType: 'pie',
  //   showRefData: false
  // },
  // {
  //   pId: 2,
  //   layout: '{"x":12,"y":8,"w":12,"h":13}',
  //   title: 'Reduction in emissions due energy conversions',
  //   description: null,
  //   unitType: 'NUMBER',
  //   query: {
  //     measures: ['esg.data', 'esg.refData'],
  //     dimensions: ['esg.kpi']
  //   },
  //   data: [
  //     { 'esg.kpi': 2018, 'esg.data': 2700, 'esg.refData': 3700 },
  //     { 'esg.kpi': 2019, 'esg.data': 7500, 'esg.refData': 8000 },
  //     { 'esg.kpi': 2020, 'esg.data': 5200, 'esg.refData': 5200 },
  //     { 'esg.kpi': 2021, 'esg.data': 11000, 'esg.refData': 13700 }
  //   ],
  //   chartType: 'line',
  //   showRefData: true
  // },
  {
    pId: 9,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":0,"w":20,"h":1.5}',
      md: '{"x":0,"y":0,"w":6,"h":1.5}',
      sm: '{"x":0,"y":0,"w":3,"h":1.5}',
      xs: '{"x":0,"y":0,"w":4,"h":1.5}',
      xxs: '{"x":0,"y":0,"w":4,"h":1.5}'
    },
    title: 'Employee Diversity',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 24,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 9,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":0,"w":20,"h":2}',
      md: '{"x":0,"y":0,"w":6,"h":2}',
      sm: '{"x":0,"y":0,"w":3,"h":2}',
      xs: '{"x":0,"y":0,"w":4,"h":2}',
      xxs: '{"x":0,"y":0,"w":4,"h":2}'
    },
    title: "Key insights into organisation's employee diversity",
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 14,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 9,
    layouts: {
      lg: '{"x":0,"y":0,"w":6,"h":8}',
      md: '{"x":0,"y":0,"w":6,"h":8}',
      sm: '{"x":0,"y":0,"w":3,"h":8}',
      xs: '{"x":0,"y":0,"w":4,"h":8}',
      xxs: '{"x":0,"y":0,"w":4,"h":8}'
    },
    title: 'Total Number Of Employees',
    titleAlignment: 'center',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [{ 'esg.kpi': 'Total Employees', 'esg.data': 4500 }],
    chartType: 'number',
    showRefData: false,
    trendEnabled: true
  }, //Enviroment charts  {
  {
    pId: 14,
    chartType: 'esgIllustration',
    layouts: {
      lg: '{"x":0,"y":0,"w":40,"h":3}',
      md: '{"x":0,"y":0,"w":40,"h":3}',
      sm: '{"x":0,"y":0,"w":3,"h":3}',
      xs: '{"x":0,"y":0,"w":4,"h":3}',
      xxs: '{"x":0,"y":0,"w":4,"h":3}'
    },
    title: 'Scope 1 Emissionssss',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 24,
    query: {
      measures: [],
      dimensions: []
    },
    illustrationUrl:
      'https://media.experienz.co.uk/shared/esg/backgrounds/sustainablecity-extended.png'
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":6,"w":20,"h":1.5}',
      md: '{"x":0,"y":6,"w":6,"h":1.5}',
      sm: '{"x":0,"y":6,"w":3,"h":1.5}',
      xs: '{"x":0,"y":6,"w":4,"h":1.5}',
      xxs: '{"x":0,"y":6,"w":4,"h":1.5}'
    },
    title: 'Scope 1 Emissions',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 24,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":6,"w":20,"h":2}',
      md: '{"x":0,"y":6,"w":6,"h":2}',
      sm: '{"x":0,"y":6,"w":3,"h":2}',
      xs: '{"x":0,"y":6,"w":4,"h":2}',
      xxs: '{"x":0,"y":6,"w":4,"h":2}'
    },
    title:
      'This covers emissions resulting in Refrigerants, Fire extinguishers, Vehicles, Generators and Cooking by Category',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 14,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":4,"y":12,"w":4,"h":8}',
      md: '{"x":4,"y":12,"w":6,"h":8}',
      sm: '{"x":0,"y":12,"w":3,"h":8}',
      xs: '{"x":0,"y":0,"w":4,"h":8}',
      xxs: '{"x":0,"y":0,"w":4,"h":8}'
    },
    title: 'Scope 01',
    titleAlignment: 'center',
    titleColor: '#1d71b8',
    hideBorder: false,
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'esg.emissions',
        'esg.data': 238000,
        'esg.refData': 238000
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['esg.emissions'],
          timeDimensions: [
            {
              dimension: 'esg.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'esg.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'esg.emissions.date.year': '2022-01-01T00:00:00.000',
            'esg.emissions.date': '2022-01-01T00:00:00.000',
            'esg.emissions': 238000,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'esg.emissions': {
              title: 'esg emissions invited',
              shortTitle: 'esg.emissions',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'esg.emissions.date.year': {
              title: 'esg.emissions Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'esg.emissions.date': {
              title: 'esg.emissions Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['esg.emissions'],
          timeDimensions: [
            {
              dimension: 'esg.emissions.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'esg.emissions.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'esg.emissions.date.year': '2021-01-01T00:00:00.000',
            'esg.emissions.date': '2021-01-01T00:00:00.000',
            'esg.emissions': 222000,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'esg.emissions.invited': {
              title: 'esg emissions invited',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'esg.emissions.date.year': {
              title: 'esg emissions Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'esg.emissions.date': {
              title: 'esg emissions Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    trendEnabled: true
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":12,"y":8,"w":12,"h":10}',
      md: '{"x":12,"y":8,"w":12,"h":10}',
      sm: '{"x":0,"y":8,"w":6,"h":10}',
      xs: '{"x":0,"y":8,"w":4,"h":10}',
      xxs: '{"x":0,"y":8,"w":4,"h":10}'
    },
    title: 'By Category',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: [
        'esg.stationaryCombustion',
        'esg.fugitiveEmissions',
        'esg.mobileCombustions'
      ],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': '2021',
        'esg.stationaryCombustion': 115837.87,
        'esg.fugitiveEmissions': 104254.09,
        'esg.mobileCombustions': 21037.65
      },
      {
        'esg.kpi': '2022',
        'esg.stationaryCombustion': 36511.17,
        'esg.fugitiveEmissions': 32860.05,
        'esg.mobileCombustions': 18723.78
      },
      {
        'esg.kpi': '2023',
        'esg.stationaryCombustion': 152417.73,
        'esg.fugitiveEmissions': 135475.28,
        'esg.mobileCombustions': 20023.83
      }
    ],
    chartType: 'bar',
    hideBorder: true,
    colorPalette: 'palette1',
    titleAlignment: 'center',
    stacked: true,
    showRefData: true
  },
  {
    pId: 14,
    chartType: 'separator',
    title: 'sep1',
    layouts: {
      lg: '{"x":0,"y":18,"w":30,"h":1.5}',
      md: '{"x":0,"y":18,"w":30,"h":1.5}',
      sm: '{"x":0,"y":0,"w":3,"h":2}',
      xs: '{"x":0,"y":0,"w":4,"h":1}',
      xxs: '{"x":0,"y":0,"w":4,"h":1}'
    },
    marginY: 2,
    hideBorder: true,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":20,"w":20,"h":1.5}',
      md: '{"x":0,"y":20,"w":6,"h":1.5}',
      sm: '{"x":0,"y":0,"w":3,"h":1.5}',
      xs: '{"x":0,"y":0,"w":4,"h":1.5}',
      xxs: '{"x":0,"y":0,"w":4,"h":1.5}'
    },
    title: 'Scope 2 Emissions',
    titleAlignment: 'flex-start',
    hideBorder: true,
    description: null,
    fontSize: 24,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":22,"w":20,"h":2}',
      md: '{"x":0,"y":22,"w":6,"h":2}',
      sm: '{"x":0,"y":0,"w":3,"h":2}',
      xs: '{"x":0,"y":0,"w":4,"h":2}',
      xxs: '{"x":0,"y":0,"w":4,"h":2}'
    },
    title: 'Emissions from electricity consumption in Mt CO2e.',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 14,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":4,"y":24,"w":4,"h":8}',
      md: '{"x":4,"y":24,"w":6,"h":8}',
      sm: '{"x":0,"y":12,"w":3,"h":8}',
      xs: '{"x":0,"y":0,"w":4,"h":8}',
      xxs: '{"x":0,"y":0,"w":4,"h":8}'
    },
    title: 'Scope 02',
    titleAlignment: 'center',
    titleColor: '#00b1aa',
    hideBorder: false,
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'esg.emissions',
        'esg.data': 124000,
        'esg.refData': 124000
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['esg.emissions'],
          timeDimensions: [
            {
              dimension: 'esg.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'esg.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'esg.emissions.date.year': '2022-01-01T00:00:00.000',
            'esg.emissions.date': '2022-01-01T00:00:00.000',
            'esg.emissions': 124000,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'esg.emissions': {
              title: 'esg emissions invited',
              shortTitle: 'esg.emissions',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'esg.emissions.date.year': {
              title: 'esg.emissions Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'esg.emissions.date': {
              title: 'esg.emissions Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['esg.emissions'],
          timeDimensions: [
            {
              dimension: 'esg.emissions.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'esg.emissions.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'esg.emissions.date.year': '2021-01-01T00:00:00.000',
            'esg.emissions.date': '2021-01-01T00:00:00.000',
            'esg.emissions': 120000,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'esg.emissions.invited': {
              title: 'esg emissions invited',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'esg.emissions.date.year': {
              title: 'esg emissions Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'esg.emissions.date': {
              title: 'esg emissions Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    trendEnabled: true
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":12,"y":24,"w":12,"h":10}',
      md: '{"x":12,"y":24,"w":12,"h":10}',
      sm: '{"x":0,"y":8,"w":6,"h":10}',
      xs: '{"x":0,"y":8,"w":4,"h":10}',
      xxs: '{"x":0,"y":8,"w":4,"h":10}'
    },
    title: 'By Type',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.electricity', 'esg.gas', 'esg.steam'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': '2021',
        'esg.electricity': 12000.87,
        'esg.gas': 6000.09,
        'esg.steam': 10000.65
      },
      {
        'esg.kpi': '2022',
        'esg.electricity': 11000.87,
        'esg.gas': 8000.09,
        'esg.steam': 14000.65
      },
      {
        'esg.kpi': '2023',
        'esg.electricity': 15000.87,
        'esg.gas': 6000.09,
        'esg.steam': 10000.65
      }
    ],
    chartType: 'bar',
    stacked: true,
    hideBorder: true,
    colorPalette: 'palette2',
    titleAlignment: 'center',

    showRefData: true
  },
  {
    pId: 14,
    chartType: 'separator',
    title: 'sep2',
    layouts: {
      lg: '{"x":0,"y":28,"w":30,"h":1}',
      md: '{"x":0,"y":28,"w":30,"h":1}',
      sm: '{"x":0,"y":0,"w":3,"h":1}',
      xs: '{"x":0,"y":0,"w":4,"h":1}',
      xxs: '{"x":0,"y":0,"w":4,"h":1}'
    },
    marginY: 2,
    hideBorder: true,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":30,"w":20,"h":1.5}',
      md: '{"x":0,"y":30,"w":6,"h":1.5}',
      sm: '{"x":0,"y":30,"w":3,"h":1.5}',
      xs: '{"x":0,"y":30,"w":4,"h":1.5}',
      xxs: '{"x":0,"y":30,"w":4,"h":1.5}'
    },
    title: 'Scope 3 Emissions',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 24,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":31,"w":20,"h":2}',
      md: '{"x":0,"y":31,"w":6,"h":2}',
      sm: '{"x":0,"y":31,"w":3,"h":2}',
      xs: '{"x":0,"y":31,"w":4,"h":2}',
      xxs: '{"x":0,"y":31,"w":4,"h":2}'
    },
    title:
      'This includes indirect emissions from your value chain including purchased goods and services, international business travel, water usage and waste disposal.',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 14,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":4,"y":34,"w":4,"h":8}',
      md: '{"x":4,"y":34,"w":6,"h":8}',
      sm: '{"x":0,"y":34,"w":3,"h":8}',
      xs: '{"x":0,"y":34,"w":4,"h":8}',
      xxs: '{"x":0,"y":34,"w":4,"h":8}'
    },
    title: 'Scope 03',
    titleAlignment: 'center',
    titleColor: '#1d71b8',
    hideBorder: false,
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'esg.emissions',
        'esg.data': 978000,
        'esg.refData': 978000
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['esg.emissions'],
          timeDimensions: [
            {
              dimension: 'esg.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'esg.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'esg.emissions.date.year': '2022-01-01T00:00:00.000',
            'esg.emissions.date': '2022-01-01T00:00:00.000',
            'esg.emissions': 978000,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'esg.emissions': {
              title: 'esg emissions invited',
              shortTitle: 'esg.emissions',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'esg.emissions.date.year': {
              title: 'esg.emissions Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'esg.emissions.date': {
              title: 'esg.emissions Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['esg.emissions'],
          timeDimensions: [
            {
              dimension: 'esg.emissions.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'esg.emissions.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'esg.emissions.date.year': '2021-01-01T00:00:00.000',
            'esg.emissions.date': '2021-01-01T00:00:00.000',
            'esg.emissions': 788000,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'esg.emissions.invited': {
              title: 'esg emissions invited',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'esg.emissions.date.year': {
              title: 'esg emissions Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'esg.emissions.date': {
              title: 'esg emissions Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    trendEnabled: true
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":12,"y":34,"w":12,"h":10}',
      md: '{"x":12,"y":34,"w":12,"h":10}',
      sm: '{"x":0,"y":34,"w":6,"h":10}',
      xs: '{"x":0,"y":34,"w":4,"h":10}',
      xxs: '{"x":0,"y":34,"w":4,"h":10}'
    },
    title: 'By Source',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: [
        'esg.purchasedGoodAndServices',
        'esg.useOfSoldProducts',
        'esg.capitalGoods',
        'esg.fuelAndEnergyRelatedActivities',
        'esg.leasedAssets',
        'esg.employeeCommuting',
        'esg.wasteGeneratedInOperations',
        'esg.transportationAndDistribution'
      ],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': '2021',
        'esg.purchasedGoodAndServices': 125837.87,
        'esg.useOfSoldProducts': 104254.09,
        'esg.capitalGoods': 11037.65,
        'esg.fuelAndEnergyRelatedActivities': 117837.87,
        'esg.leasedAssets': 114254.09,
        'esg.employeeCommuting': 21337.65,
        'esg.wasteGeneratedInOperations': 135837.87,
        'esg.transportationAndDistribution': 154254.09
      },
      {
        'esg.kpi': '2022',
        'esg.purchasedGoodAndServices': 95837.87,
        'esg.useOfSoldProducts': 124254.09,
        'esg.capitalGoods': 23037.65,
        'esg.fuelAndEnergyRelatedActivities': 105837.87,
        'esg.leasedAssets': 94254.09,
        'esg.employeeCommuting': 19037.65,
        'esg.wasteGeneratedInOperations': 135837.87,
        'esg.transportationAndDistribution': 144254.09
      },
      {
        'esg.kpi': '2023',
        'esg.purchasedGoodAndServices': 125837.87,
        'esg.useOfSoldProducts': 134254.09,
        'esg.capitalGoods': 22037.65,
        'esg.fuelAndEnergyRelatedActivities': 125837.87,
        'esg.leasedAssets': 114254.09,
        'esg.employeeCommuting': 23037.65,
        'esg.wasteGeneratedInOperations': 125837.87,
        'esg.transportationAndDistribution': 114254.09
      }
    ],
    chartType: 'bar',
    colorPalette: 'palette3',
    titleAlignment: 'center',
    hideBorder: true,
    stacked: true,
    showRefData: true
  },
  {
    pId: 14,
    chartType: 'separator',
    title: 'sep4',
    layouts: {
      lg: '{"x":0,"y":35,"w":30,"h":1}',
      md: '{"x":0,"y":35,"w":30,"h":1}',
      sm: '{"x":0,"y":0,"w":3,"h":1}',
      xs: '{"x":0,"y":0,"w":4,"h":1}',
      xxs: '{"x":0,"y":0,"w":4,"h":1}'
    },
    marginY: 2,
    hideBorder: true,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":40,"w":20,"h":1.5}',
      md: '{"x":0,"y":40,"w":6,"h":1.5}',
      sm: '{"x":0,"y":38,"w":3,"h":1.5}',
      xs: '{"x":0,"y":38,"w":4,"h":1.5}',
      xxs: '{"x":0,"y":38,"w":4,"h":1.5}'
    },
    title: 'Spectator Travel',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 24,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":42,"w":20,"h":2}',
      md: '{"x":0,"y":42,"w":6,"h":2}',
      sm: '{"x":0,"y":39,"w":3,"h":2}',
      xs: '{"x":0,"y":39,"w":4,"h":2}',
      xxs: '{"x":0,"y":39,"w":4,"h":2}'
    },
    title:
      'Emissions result from the use of transportation modes like cars, planes, or trains to reach the event venue',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 14,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":4,"y":42,"w":4,"h":8}',
      md: '{"x":4,"y":42,"w":6,"h":8}',
      sm: '{"x":0,"y":42,"w":3,"h":8}',
      xs: '{"x":0,"y":42,"w":4,"h":8}',
      xxs: '{"x":0,"y":42,"w":4,"h":8}'
    },
    title: 'Spectators Travel',
    titleAlignment: 'center',
    titleColor: '#1d71b8',
    hideBorder: false,
    hideTitle: true,
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'esg.emissions',
        'esg.data': 39000,
        'esg.refData': 39000
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['esg.emissions'],
          timeDimensions: [
            {
              dimension: 'esg.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'esg.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'esg.emissions.date.year': '2022-01-01T00:00:00.000',
            'esg.emissions.date': '2022-01-01T00:00:00.000',
            'esg.emissions': 39000,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'esg.emissions': {
              title: 'esg emissions invited',
              shortTitle: 'esg.emissions',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'esg.emissions.date.year': {
              title: 'esg.emissions Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'esg.emissions.date': {
              title: 'esg.emissions Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['esg.emissions'],
          timeDimensions: [
            {
              dimension: 'esg.emissions.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'esg.emissions.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'esg.emissions.date.year': '2021-01-01T00:00:00.000',
            'esg.emissions.date': '2021-01-01T00:00:00.000',
            'esg.emissions': 35000,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'esg.emissions.invited': {
              title: 'esg emissions invited',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'esg.emissions.date.year': {
              title: 'esg emissions Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'esg.emissions.date': {
              title: 'esg emissions Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    trendEnabled: true
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":12,"y":42,"w":12,"h":10}',
      md: '{"x":12,"y":42,"w":12,"h":10}',
      sm: '{"x":0,"y":42,"w":3,"h":8}',
      xs: '{"x":0,"y":42,"w":4,"h":8}',
      xxs: '{"x":0,"y":42,"w":4,"h":8}'
    },
    title: 'Total Emissions by Events',
    description: 'By Event',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    hideTitle: true,
    data: [
      {
        'esg.kpi': "Gentlemen's Singles",
        'esg.data': 20029.0
      },
      {
        'esg.kpi': "Ladies' Singles",
        'esg.data': 12000
      },
      {
        'esg.kpi': 'Gentleman’s Double',
        'esg.data': 7000
      }
    ],
    chartType: 'progressTable',
    showRefData: false,
    color: 'info',
    unit: 'MtCO2e',
    fontSize: 12,
    showTitle: false,
    centeredTitle: 'Total Scope 1',
    hideBorder: true
  },

  {
    pId: 14,
    chartType: 'separator',
    title: 'sep5',
    layouts: {
      lg: '{"x":0,"y":48,"w":30,"h":1}',
      md: '{"x":0,"y":48,"w":30,"h":1}',
      sm: '{"x":0,"y":0,"w":3,"h":1}',
      xs: '{"x":0,"y":0,"w":4,"h":1}',
      xxs: '{"x":0,"y":0,"w":4,"h":1}'
    },
    marginY: 2,
    hideBorder: true,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":50,"w":20,"h":1.5}',
      md: '{"x":0,"y":50,"w":6,"h":1.5}',
      sm: '{"x":0,"y":50,"w":3,"h":1.5}',
      xs: '{"x":0,"y":50,"w":4,"h":1.5}',
      xxs: '{"x":0,"y":50,"w":4,"h":1.5}'
    },
    title: 'Staff Travel',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 24,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":52,"w":20,"h":2}',
      md: '{"x":0,"y":52,"w":6,"h":2}',
      sm: '{"x":0,"y":52,"w":3,"h":2}',
      xs: '{"x":0,"y":52,"w":4,"h":2}',
      xxs: '{"x":0,"y":52,"w":4,"h":2}'
    },
    title:
      'Emissions result from the use of transportation modes like cars or trains to reach the venue',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 14,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":4,"y":54,"w":4,"h":8}',
      md: '{"x":4,"y":54,"w":6,"h":8}',
      sm: '{"x":0,"y":54,"w":3,"h":8}',
      xs: '{"x":0,"y":54,"w":4,"h":8}',
      xxs: '{"x":0,"y":54,"w":4,"h":8}'
    },
    title: 'Staff Travel Emission',
    titleAlignment: 'center',
    titleColor: '#1d71b8',
    hideBorder: false,
    hideTitle: true,
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'esg.emissions',
        'esg.data': 18000,
        'esg.refData': 18000
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['esg.emissions'],
          timeDimensions: [
            {
              dimension: 'esg.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'esg.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'esg.emissions.date.year': '2022-01-01T00:00:00.000',
            'esg.emissions.date': '2022-01-01T00:00:00.000',
            'esg.emissions': 18000,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'esg.emissions': {
              title: 'esg emissions invited',
              shortTitle: 'esg.emissions',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'esg.emissions.date.year': {
              title: 'esg.emissions Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'esg.emissions.date': {
              title: 'esg.emissions Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['esg.emissions'],
          timeDimensions: [
            {
              dimension: 'esg.emissions.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'esg.emissions.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'esg.emissions.date.year': '2021-01-01T00:00:00.000',
            'esg.emissions.date': '2021-01-01T00:00:00.000',
            'esg.emissions': 14000,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'esg.emissions.invited': {
              title: 'esg emissions invited',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'esg.emissions.date.year': {
              title: 'esg emissions Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'esg.emissions.date': {
              title: 'esg emissions Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    trendEnabled: true
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":12,"y":54,"w":12,"h":10}',
      md: '{"x":12,"y":54,"w":12,"h":10}',
      sm: '{"x":0,"y":54,"w":3,"h":8}',
      xs: '{"x":0,"y":54,"w":4,"h":8}',
      xxs: '{"x":0,"y":54,"w":4,"h":8}'
    },
    title: 'Total Emissions by Eventss',
    description: 'By Events',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    hideTitle: true,
    data: [
      {
        'esg.kpi': "Gentlemen's Singles",
        'esg.data': 5000.0
      },
      {
        'esg.kpi': "Ladies' Singles",
        'esg.data': 6000
      },
      {
        'esg.kpi': 'Gentleman’s Double',
        'esg.data': 7000
      }
    ],
    chartType: 'progressTable',
    showRefData: false,
    color: 'info',
    unit: 'MtCO2e',
    fontSize: 12,
    showTitle: false,
    centeredTitle: 'Total Scope 1',
    hideBorder: true
  },

  //carbon footprint of suppliers

  {
    pId: 14,
    chartType: 'separator',
    title: 'sep6',
    layouts: {
      lg: '{"x":0,"y":65,"w":30,"h":1}',
      md: '{"x":0,"y":65,"w":30,"h":1}',
      sm: '{"x":0,"y":0,"w":3,"h":1}',
      xs: '{"x":0,"y":0,"w":4,"h":1}',
      xxs: '{"x":0,"y":0,"w":4,"h":1}'
    },
    marginY: 2,
    hideBorder: true,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":67,"w":30,"h":1.5}',
      md: '{"x":0,"y":67,"w":30,"h":1.5}',
      sm: '{"x":0,"y":50,"w":3,"h":1.5}',
      xs: '{"x":0,"y":50,"w":4,"h":1.5}',
      xxs: '{"x":0,"y":50,"w":4,"h":1.5}'
    },
    title: 'Carbon Footprint of Wimbledon Tennis Suppliers',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 24,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":69,"w":30,"h":2}',
      md: '{"x":0,"y":69,"w":30,"h":2}',
      sm: '{"x":0,"y":52,"w":3,"h":2}',
      xs: '{"x":0,"y":52,"w":4,"h":2}',
      xxs: '{"x":0,"y":52,"w":4,"h":2}'
    },
    title: '(82/149 Suppliers)',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 14,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":0,"y":72,"w":4,"h":10}',
      md: '{"x":0,"y":72,"w":4,"h":10}',
      sm: '{"x":0,"y":72,"w":3,"h":8}',
      xs: '{"x":0,"y":72,"w":4,"h":8}',
      xxs: '{"x":0,"y":72,"w":4,"h":8}'
    },
    title: 'Total Supplier Emissions',
    titleAlignment: 'center',
    titleColor: '#1d71b8',
    hideBorder: false,
    description: null,
    shortDescription: 'mtCO2e',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Supplier total invitations',
        'esg.data': 873,
        'esg.refData': 500
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['suppliers.invited'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'suppliers.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2022-01-01T00:00:00.000',
            'suppliers.date': '2022-01-01T00:00:00.000',
            'suppliers.invited': 873,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'suppliers.invited': {
              title: 'suppliers invited',
              shortTitle: 'suppliers.invited',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['suppliers.invited'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'supplier.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2021-01-01T00:00:00.000',
            'suppliers.date': '2021-01-01T00:00:00.000',
            'suppliers.invited': 1600,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'suppliers.invited': {
              title: 'Suppliers invited',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'Suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    trendEnabled: true
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":5,"y":72,"w":4,"h":8}',
      md: '{"x":5,"y":72,"w":6,"h":8}',
      sm: '{"x":3,"y":72,"w":3,"h":8}',
      xs: '{"x":0,"y":72,"w":4,"h":8}',
      xxs: '{"x":0,"y":72,"w":4,"h":8}'
    },
    title: 'Water Usage',
    titleAlignment: 'center',
    titleColor: '#00b1aa',
    fontSize: 10,
    hideBorder: false,
    description: null,
    shortDescription: 'Litres',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Supplier total registrations',
        'esg.data': 3000000,
        'esg.refData': 200
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['suppliers.regsitration.total'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'suppliers.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2022-01-01T00:00:00.000',
            'suppliers.date': '2022-01-01T00:00:00.000',
            'suppliers.regsitration.total': 3000000,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'suppliers.regsitration.total': {
              title: 'suppliers regsitration total',
              shortTitle: 'regsitration.total',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['suppliers.regsitration.total'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'supplier.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2021-01-01T00:00:00.000',
            'suppliers.date': '2021-01-01T00:00:00.000',
            'suppliers.regsitration.total': 1600000,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'suppliers.regsitration.total': {
              title: 'Suppliers regsitration.total',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'Suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    shortDescription: '',
    trendEnabled: true
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":10,"y":72,"w":4,"h":8}',
      md: '{"x":10,"y":72,"w":6,"h":8}',
      sm: '{"x":6,"y":72,"w":3,"h":8}',
      xs: '{"x":0,"y":72,"w":4,"h":8}',
      xxs: '{"x":0,"y":72,"w":4,"h":8}'
    },
    title: 'Waste Generated',
    titleAlignment: 'center',
    titleColor: '#3F830E',
    hideBorder: false,
    description: null,
    shortDescription: 'kg',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Supplier registration rate',
        'esg.data': 4000,
        'esg.refData': 30
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['suppliers.regsitration.rate'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'suppliers.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2022-01-01T00:00:00.000',
            'suppliers.date': '2022-01-01T00:00:00.000',
            'suppliers.regsitration.rate': 4000,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'suppliers.regsitration.rate': {
              title: 'suppliers regsitration rate',
              shortTitle: 'regsitration.rate',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['suppliers.regsitration.rate'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'supplier.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2021-01-01T00:00:00.000',
            'suppliers.date': '2021-01-01T00:00:00.000',
            'suppliers.regsitration.rate': 3600,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'suppliers.regsitration.rate': {
              title: 'Suppliers regsitration.rate',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'Suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    shortDescription: '',
    trendEnabled: true
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":15,"y":72,"w":4,"h":8}',
      md: '{"x":15,"y":72,"w":6,"h":8}',
      sm: '{"x":9,"y":72,"w":3,"h":8}',
      xs: '{"x":0,"y":0,"w":4,"h":8}',
      xxs: '{"x":0,"y":0,"w":4,"h":8}'
    },
    title: 'Staff Transportation',
    titleAlignment: 'center',
    titleColor: '#B71926',
    hideBorder: false,
    description: null,
    shortDescription: 'mtCO2e',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi'],
      timeDimensions: [
        {
          dimension: 'esg.date'
        }
      ]
    },
    data: [
      {
        'esg.date.year': '2022-01-01T00:00:00.000',
        'esg.kpi': 'Supplier total data submissions',
        'esg.data': 6
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['suppliers.submissions'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'suppliers.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2022-01-01T00:00:00.000',
            'suppliers.date': '2022-01-01T00:00:00.000',
            'suppliers.submissions': 6,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'suppliers.submissions': {
              title: 'suppliers Emissions',
              shortTitle: 'submissions',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['suppliers.submissions'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'supplier.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2021-01-01T00:00:00.000',
            'suppliers.date': '2021-01-01T00:00:00.000',
            'suppliers.submissions': 5,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'emissions.emissions': {
              title: 'Emissions Emissions',
              shortTitle: 'Emissions',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'Suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: false,
    trendEnabled: true
  },

  {
    pId: 14,
    layouts: {
      lg: '{"x":21,"y":72,"w":4,"h":8}',
      md: '{"x":21,"y":72,"w":6,"h":8}',
      sm: '{"x":9,"y":72,"w":3,"h":8}',
      xs: '{"x":0,"y":0,"w":4,"h":8}',
      xxs: '{"x":0,"y":0,"w":4,"h":8}'
    },
    title: 'Transportation of Goods',
    titleAlignment: 'center',
    titleColor: '#B71926',
    hideBorder: false,
    description: null,
    shortDescription: 'mtCO2e',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi'],
      timeDimensions: [
        {
          dimension: 'esg.date'
        }
      ]
    },
    data: [
      {
        'esg.date.year': '2022-01-01T00:00:00.000',
        'esg.kpi': 'Supplier total data submissions',
        'esg.data': 47
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['suppliers.submissions'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'suppliers.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2022-01-01T00:00:00.000',
            'suppliers.date': '2022-01-01T00:00:00.000',
            'suppliers.submissions': 47,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'suppliers.submissions': {
              title: 'suppliers Emissions',
              shortTitle: 'submissions',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['suppliers.submissions'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'supplier.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2021-01-01T00:00:00.000',
            'suppliers.date': '2021-01-01T00:00:00.000',
            'suppliers.submissions': 36,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'emissions.emissions': {
              title: 'Emissions Emissions',
              shortTitle: 'Emissions',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'Suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: false,
    trendEnabled: true
  },

  //Total Emissions by contributors

  {
    pId: 14,
    chartType: 'separator',
    title: 'sep7',
    layouts: {
      lg: '{"x":0,"y":80,"w":30,"h":1}',
      md: '{"x":0,"y":80,"w":30,"h":1}',
      sm: '{"x":0,"y":0,"w":3,"h":1}',
      xs: '{"x":0,"y":0,"w":4,"h":1}',
      xxs: '{"x":0,"y":0,"w":4,"h":1}'
    },
    marginY: 2,
    hideBorder: true,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":82,"w":30,"h":1.5}',
      md: '{"x":0,"y":82,"w":30,"h":1.5}',
      sm: '{"x":0,"y":50,"w":3,"h":1.5}',
      xs: '{"x":0,"y":50,"w":4,"h":1.5}',
      xxs: '{"x":0,"y":50,"w":4,"h":1.5}'
    },
    title: 'Total Emissions by Contributors',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 24,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":84,"w":30,"h":2}',
      md: '{"x":0,"y":84,"w":30,"h":2}',
      sm: '{"x":0,"y":52,"w":3,"h":2}',
      xs: '{"x":0,"y":52,"w":4,"h":2}',
      xxs: '{"x":0,"y":52,"w":4,"h":2}'
    },
    title:
      'The aggregate sum of emissions originating from individual contributors (mtCO2e)',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 14,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":4,"y":86,"w":4,"h":8}',
      md: '{"x":4,"y":86,"w":4,"h":8}',
      sm: '{"x":0,"y":72,"w":3,"h":8}',
      xs: '{"x":0,"y":72,"w":4,"h":8}',
      xxs: '{"x":0,"y":72,"w":4,"h":8}'
    },
    title: 'Total Supplier Emissionsss',
    titleAlignment: 'center',
    titleColor: '#1d71b8',
    hideBorder: false,
    hideTitle: true,
    description: null,
    shortDescription: 'mtCO2e',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Supplier total invitations',
        'esg.data': 873,
        'esg.refData': 500
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['suppliers.invited'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'suppliers.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2022-01-01T00:00:00.000',
            'suppliers.date': '2022-01-01T00:00:00.000',
            'suppliers.invited': 873,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'suppliers.invited': {
              title: 'suppliers invited',
              shortTitle: 'suppliers.invited',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['suppliers.invited'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'supplier.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2021-01-01T00:00:00.000',
            'suppliers.date': '2021-01-01T00:00:00.000',
            'suppliers.invited': 1600,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'suppliers.invited': {
              title: 'Suppliers invited',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'Suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    trendEnabled: true
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":12,"y":86,"w":12,"h":10}',
      md: '{"x":12,"y":86,"w":12,"h":10}',
      sm: '{"x":0,"y":42,"w":3,"h":8}',
      xs: '{"x":0,"y":42,"w":4,"h":8}',
      xxs: '{"x":0,"y":42,"w":4,"h":8}'
    },
    title: 'Total Emissions by Contributorss',
    description: 'By Event',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    hideTitle: true,
    data: [
      {
        'esg.kpi': 'Goods and Services',
        'esg.data': 120
      },
      {
        'esg.kpi': 'Spectator Travel',
        'esg.data': 100
      },
      {
        'esg.kpi': 'Transportation of Goods',
        'esg.data': 90
      },
      {
        'esg.kpi': 'Lanson',
        'esg.data': 80
      },
      {
        'esg.kpi': 'Staff Transportation',
        'esg.data': 70
      },
      {
        'esg.kpi': 'Refrigerant',
        'esg.data': 60
      },
      {
        'esg.kpi': 'Vehicle',
        'esg.data': 55
      },
      {
        'esg.kpi': 'Staff Travel',
        'esg.data': 50
      },
      {
        'esg.kpi': 'Electricity',
        'esg.data': 45
      },
      {
        'esg.kpi': 'Vehicle',
        'esg.data': 55
      },
      {
        'esg.kpi': 'Fuel Used On-site',
        'esg.data': 40
      },
      {
        'esg.kpi': 'Water Usage',
        'esg.data': 35
      },
      {
        'esg.kpi': 'Waste Generated',
        'esg.data': 30
      }
    ],
    chartType: 'progressTable',
    showRefData: false,
    color: 'info',
    unit: 'MtCO2e',
    fontSize: 12,
    showTitle: false,
    centeredTitle: 'Total Scope 1',
    hideBorder: true
  },

  //Total Emissions by Sectors

  {
    pId: 14,
    chartType: 'separator',
    title: 'sep8',
    layouts: {
      lg: '{"x":0,"y":88,"w":30,"h":1}',
      md: '{"x":0,"y":88,"w":30,"h":1}',
      sm: '{"x":0,"y":0,"w":3,"h":1}',
      xs: '{"x":0,"y":0,"w":4,"h":1}',
      xxs: '{"x":0,"y":0,"w":4,"h":1}'
    },
    marginY: 2,
    hideBorder: true,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":90,"w":30,"h":1.5}',
      md: '{"x":0,"y":90,"w":30,"h":1.5}',
      sm: '{"x":0,"y":50,"w":3,"h":1.5}',
      xs: '{"x":0,"y":50,"w":4,"h":1.5}',
      xxs: '{"x":0,"y":50,"w":4,"h":1.5}'
    },
    title: 'Total Emissions by Sectors',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 24,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":92,"w":30,"h":2}',
      md: '{"x":0,"y":92,"w":30,"h":2}',
      sm: '{"x":0,"y":52,"w":3,"h":2}',
      xs: '{"x":0,"y":52,"w":4,"h":2}',
      xxs: '{"x":0,"y":52,"w":4,"h":2}'
    },
    title: 'Cumulative emissions segregated according to the sector (mtCO2e)',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 14,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":4,"y":94,"w":4,"h":8}',
      md: '{"x":4,"y":94,"w":4,"h":8}',
      sm: '{"x":0,"y":72,"w":3,"h":8}',
      xs: '{"x":0,"y":72,"w":4,"h":8}',
      xxs: '{"x":0,"y":72,"w":4,"h":8}'
    },
    title: 'Total Supplier Emissionsss By Sector',
    titleAlignment: 'center',
    titleColor: '#1d71b8',
    hideBorder: false,
    hideTitle: true,
    description: null,
    shortDescription: 'mtCO2e',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Supplier total invitations',
        'esg.data': 873,
        'esg.refData': 500
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['suppliers.invited'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'suppliers.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2022-01-01T00:00:00.000',
            'suppliers.date': '2022-01-01T00:00:00.000',
            'suppliers.invited': 873,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'suppliers.invited': {
              title: 'suppliers invited',
              shortTitle: 'suppliers.invited',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['suppliers.invited'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'supplier.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2021-01-01T00:00:00.000',
            'suppliers.date': '2021-01-01T00:00:00.000',
            'suppliers.invited': 1600,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'suppliers.invited': {
              title: 'Suppliers invited',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'Suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    trendEnabled: true
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":12,"y":94,"w":12,"h":10}',
      md: '{"x":12,"y":94,"w":12,"h":10}',
      sm: '{"x":0,"y":42,"w":3,"h":8}',
      xs: '{"x":0,"y":42,"w":4,"h":8}',
      xxs: '{"x":0,"y":42,"w":4,"h":8}'
    },
    title: 'Total Emissions by Sector',
    description: 'By Sector',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    hideTitle: true,
    data: [
      {
        'esg.kpi': 'Slazenger',
        'esg.data': 220
      },
      {
        'esg.kpi': 'Robinsons',
        'esg.data': 160
      },
      {
        'esg.kpi': 'IBM',
        'esg.data': 100
      },
      {
        'esg.kpi': 'Lanson',
        'esg.data': 80
      },
      {
        'esg.kpi': 'Ralph Lauren',
        'esg.data': 70
      },
      {
        'esg.kpi': 'Haagen-Dazs',
        'esg.data': 50
      },
      {
        'esg.kpi': 'Jaguar Land Rover',
        'esg.data': 40
      },
      {
        'esg.kpi': 'Stella Artois',
        'esg.data': 25
      },
      {
        'esg.kpi': 'Lavazza',
        'esg.data': 15
      },
      {
        'esg.kpi': 'Evian',
        'esg.data': 12
      }
    ],
    chartType: 'progressTable',
    showRefData: false,
    color: 'info',
    unit: 'MtCO2e',
    fontSize: 12,
    showTitle: false,
    centeredTitle: 'Total Scope 1',
    hideBorder: true
  },

  //Total Emissions by categories

  {
    pId: 14,
    chartType: 'separator',
    title: 'sep9',
    layouts: {
      lg: '{"x":0,"y":96,"w":30,"h":1}',
      md: '{"x":0,"y":96,"w":30,"h":1}',
      sm: '{"x":0,"y":0,"w":3,"h":1}',
      xs: '{"x":0,"y":0,"w":4,"h":1}',
      xxs: '{"x":0,"y":0,"w":4,"h":1}'
    },
    marginY: 2,
    hideBorder: true,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":98,"w":30,"h":1.5}',
      md: '{"x":0,"y":98,"w":30,"h":1.5}',
      sm: '{"x":0,"y":50,"w":3,"h":1.5}',
      xs: '{"x":0,"y":50,"w":4,"h":1.5}',
      xxs: '{"x":0,"y":50,"w":4,"h":1.5}'
    },
    title: 'Total Emissions by Categories',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 24,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":100,"w":30,"h":2}',
      md: '{"x":0,"y":100,"w":30,"h":2}',
      sm: '{"x":0,"y":52,"w":3,"h":2}',
      xs: '{"x":0,"y":52,"w":4,"h":2}',
      xxs: '{"x":0,"y":52,"w":4,"h":2}'
    },
    title: 'Cumulative emissions segregated according to categories (mtCO2e)',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 14,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":4,"y":102,"w":4,"h":8}',
      md: '{"x":4,"y":102,"w":4,"h":8}',
      sm: '{"x":0,"y":72,"w":3,"h":8}',
      xs: '{"x":0,"y":72,"w":4,"h":8}',
      xxs: '{"x":0,"y":72,"w":4,"h":8}'
    },
    title: 'Total Supplier Emissionsss By Categoriess',
    titleAlignment: 'center',
    titleColor: '#1d71b8',
    hideBorder: false,
    hideTitle: true,
    description: null,
    shortDescription: 'mtCO2e',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Supplier total invitations',
        'esg.data': 873,
        'esg.refData': 500
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['suppliers.invited'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'suppliers.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2022-01-01T00:00:00.000',
            'suppliers.date': '2022-01-01T00:00:00.000',
            'suppliers.invited': 873,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'suppliers.invited': {
              title: 'suppliers invited',
              shortTitle: 'suppliers.invited',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['suppliers.invited'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'supplier.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2021-01-01T00:00:00.000',
            'suppliers.date': '2021-01-01T00:00:00.000',
            'suppliers.invited': 1600,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'suppliers.invited': {
              title: 'Suppliers invited',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'Suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    trendEnabled: true
  },
  {
    pId: 14,
    layouts: {
      lg: '{"x":12,"y":102,"w":12,"h":10}',
      md: '{"x":12,"y":102,"w":12,"h":10}',
      sm: '{"x":0,"y":42,"w":3,"h":8}',
      xs: '{"x":0,"y":42,"w":4,"h":8}',
      xxs: '{"x":0,"y":42,"w":4,"h":8}'
    },
    title: 'Total Emissions by Categoriess',
    description: 'By Category',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    hideTitle: true,
    data: [
      {
        'esg.kpi': 'Temporary Infrastructure',
        'esg.data': 120
      },
      {
        'esg.kpi': 'Entertainment',
        'esg.data': 90
      },
      {
        'esg.kpi': 'Food & Beverage',
        'esg.data': 80
      },
      {
        'esg.kpi': 'Professional Fees',
        'esg.data': 60
      },
      {
        'esg.kpi': 'Staffing (Cleaning)',
        'esg.data': 55
      },
      {
        'esg.kpi': 'Energy',
        'esg.data': 50
      },
      {
        'esg.kpi': 'Transport',
        'esg.data': 45
      },
      {
        'esg.kpi': 'Fugitive',
        'esg.data': 40
      },
      {
        'esg.kpi': 'Staffing (Security)',
        'esg.data': 35
      },
      {
        'esg.kpi': 'Broadcast / Connectivity',
        'esg.data': 30
      },
      {
        'esg.kpi': 'Traffic Management ',
        'esg.data': 25
      },
      {
        'esg.kpi': 'Staffing (Other)',
        'esg.data': 20
      }
    ],
    chartType: 'progressTable',
    showRefData: false,
    color: 'info',
    unit: 'MtCO2e',
    fontSize: 12,
    showTitle: false,
    centeredTitle: 'Total Scope 1',
    hideBorder: true
  },

  //summary charts dashboard

  {
    pId: 15,
    chartType: 'esgIllustration',
    layouts: {
      lg: '{"x":0,"y":0,"w":40,"h":3}',
      md: '{"x":0,"y":0,"w":40,"h":3}',
      sm: '{"x":0,"y":0,"w":3,"h":3}',
      xs: '{"x":0,"y":0,"w":4,"h":3}',
      xxs: '{"x":0,"y":0,"w":4,"h":3}'
    },
    title: 'Scope 1 Emissionssss',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 24,
    query: {
      measures: [],
      dimensions: []
    },
    illustrationUrl:
      'https://media.experienz.co.uk/shared/esg/backgrounds/carbonemissions2-extended.png'
  },
  {
    pId: 15,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":6,"w":30,"h":2}',
      md: '{"x":0,"y":6,"w":30,"h":2}',
      sm: '{"x":0,"y":6,"w":3,"h":2}',
      xs: '{"x":0,"y":6,"w":4,"h":2}',
      xxs: '{"x":0,"y":6,"w":4,"h":2}'
    },
    title: 'Carbon Footprint for Wimbledon Tennis',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 24,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },

  {
    pId: 15,
    layouts: {
      lg: '{"x":1,"y":8,"w":4,"h":10}',
      md: '{"x":1,"y":8,"w":4,"h":10}',
      sm: '{"x":0,"y":8,"w":3,"h":8}',
      xs: '{"x":0,"y":8,"w":4,"h":8}',
      xxs: '{"x":0,"y":8,"w":4,"h":8}'
    },
    title: 'Total Emission',
    titleAlignment: 'center',
    titleColor: '#1d71b8',
    hideBorder: false,
    description: null,
    shortDescription: 'mtCO2e',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Supplier total invitations',
        'esg.data': 1340000,
        'esg.refData': 500
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['suppliers.invited'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'suppliers.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2022-01-01T00:00:00.000',
            'suppliers.date': '2022-01-01T00:00:00.000',
            'suppliers.invited': 1340000,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'suppliers.invited': {
              title: 'suppliers invited',
              shortTitle: 'suppliers.invited',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['suppliers.invited'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'supplier.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2021-01-01T00:00:00.000',
            'suppliers.date': '2021-01-01T00:00:00.000',
            'suppliers.invited': 1540000,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'suppliers.invited': {
              title: 'Suppliers invited',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'Suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    trendEnabled: true
  },
  {
    pId: 15,
    layouts: {
      lg: '{"x":7,"y":8,"w":4,"h":8}',
      md: '{"x":7,"y":8,"w":6,"h":8}',
      sm: '{"x":3,"y":8,"w":3,"h":8}',
      xs: '{"x":0,"y":8,"w":4,"h":8}',
      xxs: '{"x":0,"y":8,"w":4,"h":8}'
    },
    title: 'Scope 1',
    titleAlignment: 'center',
    titleColor: '#00b1aa',
    fontSize: 10,
    hideBorder: false,
    description: null,
    shortDescription: 'mtCO2e',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Supplier total registrations',
        'esg.data': 238000,
        'esg.refData': 200
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['suppliers.regsitration.total'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'suppliers.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2022-01-01T00:00:00.000',
            'suppliers.date': '2022-01-01T00:00:00.000',
            'suppliers.regsitration.total': 238000,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'suppliers.regsitration.total': {
              title: 'suppliers regsitration total',
              shortTitle: 'regsitration.total',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['suppliers.regsitration.total'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'supplier.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2021-01-01T00:00:00.000',
            'suppliers.date': '2021-01-01T00:00:00.000',
            'suppliers.regsitration.total': 268000,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'suppliers.regsitration.total': {
              title: 'Suppliers regsitration.total',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'Suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    shortDescription: '',
    trendEnabled: true
  },
  {
    pId: 15,
    layouts: {
      lg: '{"x":13,"y":8,"w":4,"h":8}',
      md: '{"x":13,"y":8,"w":6,"h":8}',
      sm: '{"x":6,"y":8,"w":3,"h":8}',
      xs: '{"x":0,"y":8,"w":4,"h":8}',
      xxs: '{"x":0,"y":8,"w":4,"h":8}'
    },
    title: 'Scope 2',
    titleAlignment: 'center',
    titleColor: '#3F830E',
    hideBorder: false,
    description: null,
    shortDescription: 'kg',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Supplier registration rate',
        'esg.data': 124000,
        'esg.refData': 30
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['suppliers.regsitration.rate'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'suppliers.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2022-01-01T00:00:00.000',
            'suppliers.date': '2022-01-01T00:00:00.000',
            'suppliers.regsitration.rate': 124000,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'suppliers.regsitration.rate': {
              title: 'suppliers regsitration rate',
              shortTitle: 'regsitration.rate',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['suppliers.regsitration.rate'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'supplier.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2021-01-01T00:00:00.000',
            'suppliers.date': '2021-01-01T00:00:00.000',
            'suppliers.regsitration.rate': 118000,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'suppliers.regsitration.rate': {
              title: 'Suppliers regsitration.rate',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'Suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    shortDescription: '',
    trendEnabled: true
  },
  {
    pId: 15,
    layouts: {
      lg: '{"x":19,"y":8,"w":4,"h":8}',
      md: '{"x":19,"y":8,"w":6,"h":8}',
      sm: '{"x":9,"y":8,"w":3,"h":8}',
      xs: '{"x":0,"y":8,"w":4,"h":8}',
      xxs: '{"x":0,"y":8,"w":4,"h":8}'
    },
    title: 'Scope 3',
    titleAlignment: 'center',
    titleColor: '#B71926',
    hideBorder: false,
    description: null,
    shortDescription: 'mtCO2e',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi'],
      timeDimensions: [
        {
          dimension: 'esg.date'
        }
      ]
    },
    data: [
      {
        'esg.date.year': '2022-01-01T00:00:00.000',
        'esg.kpi': 'Supplier total data submissions',
        'esg.data': 978000
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['suppliers.submissions'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'suppliers.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2022-01-01T00:00:00.000',
            'suppliers.date': '2022-01-01T00:00:00.000',
            'suppliers.submissions': 978000,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'suppliers.submissions': {
              title: 'suppliers Emissions',
              shortTitle: 'submissions',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['suppliers.submissions'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'supplier.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2021-01-01T00:00:00.000',
            'suppliers.date': '2021-01-01T00:00:00.000',
            'suppliers.submissions': 1078000,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'emissions.emissions': {
              title: 'Emissions Emissions',
              shortTitle: 'Emissions',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'Suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: false,
    trendEnabled: true
  },
  {
    pId: 15,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":22,"w":30,"h":2}',
      md: '{"x":0,"y":22,"w":30,"h":2}',
      sm: '{"x":0,"y":6,"w":3,"h":2}',
      xs: '{"x":0,"y":6,"w":4,"h":2}',
      xxs: '{"x":0,"y":6,"w":4,"h":2}'
    },
    title: 'Total Scope 1, 2 & 3 Emissions Against SBTs',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 14,
    query: {
      measures: [],
      dimensions: []
    },
    data: [],
    marginY: 4
  },
  {
    pId: 15,
    layouts: {
      lg: '{"x":0,"y":25,"w":24,"h":20}',
      md: '{"x":0,"y":25,"w":24,"h":20}',
      sm: '{"x":0,"y":14,"w":12,"h":20}',
      xs: '{"x":0,"y":14,"w":4,"h":20}',
      xxs: '{"x":0,"y":14,"w":4,"h":20}'
    },
    title: 'Water usage details by type (%)',
    description: null,
    hideTitle: true,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi'],
      timeDimensions: [
        {
          dimension: 'esg.date',
          granularity: 'year'
        }
      ]
    },
    data: [
      {
        'esg.date': '2021-01-01T00:00:00.000',
        'esg.date.year': '2021-01-01T00:00:00.000',
        'esg.kpi': 'Targets',
        'esg.data': 900
      },
      {
        'esg.date': '2022-01-01T00:00:00.000',
        'esg.date.year': '2022-01-01T00:00:00.000',
        'esg.kpi': 'Targets',
        'esg.data': 800
      },
      {
        'esg.date': '2023-01-01T00:00:00.000',
        'esg.date.year': '2023-01-01T00:00:00.000',
        'esg.kpi': 'Targets',
        'esg.data': 700
      },
      {
        'esg.date': '2024-01-01T00:00:00.000',
        'esg.date.year': '2024-01-01T00:00:00.000',
        'esg.kpi': 'Targets',
        'esg.data': 600
      },
      {
        'esg.date': '2025-01-01T00:00:00.000',
        'esg.date.year': '2025-01-01T00:00:00.000',
        'esg.kpi': 'Targets',
        'esg.data': 500
      },
      {
        'esg.date': '2026-01-01T00:00:00.000',
        'esg.date.year': '2026-01-01T00:00:00.000',
        'esg.kpi': 'Targets',
        'esg.data': 400
      },
      {
        'esg.date': '2027-01-01T00:00:00.000',
        'esg.date.year': '2027-01-01T00:00:00.000',
        'esg.kpi': 'Targets',
        'esg.data': 300
      },
      {
        'esg.date': '2028-01-01T00:00:00.000',
        'esg.date.year': '2028-01-01T00:00:00.000',
        'esg.kpi': 'Targets',
        'esg.data': 200
      },
      {
        'esg.date': '2029-01-01T00:00:00.000',
        'esg.date.year': '2029-01-01T00:00:00.000',
        'esg.kpi': 'Targets',
        'esg.data': 100
      },
      {
        'esg.date': '2030-01-01T00:00:00.000',
        'esg.date.year': '2030-01-01T00:00:00.000',
        'esg.kpi': 'Targets',
        'esg.data': 0
      },
      {
        'esg.date': '2021-01-01T00:00:00.000',
        'esg.date.year': '2021-01-01T00:00:00.000',
        'esg.kpi': 'Total Emission',
        'esg.data': 1250
      },
      {
        'esg.date': '2022-01-01T00:00:00.000',
        'esg.date.year': '2022-01-01T00:00:00.000',
        'esg.kpi': 'Total Emission',
        'esg.data': 900
      },
      {
        'esg.date': '2023-01-01T00:00:00.000',
        'esg.date.year': '2023-01-01T00:00:00.000',
        'esg.kpi': 'Total Emission',
        'esg.data': 950
      }
    ],
    chartType: 'line',
    showRefData: false,
    barWidth: 1,
    colorPalette: 'palette8',
    fontSize: 12,
    monoChromeColors: false,
    monoChromeBaseColor: 'success',
    showZeros: true,
    hideXAxis: false,
    hideYAxis: false,
    showDataLabels: false,
    enableMonoChromeColors: false,
    tooltipsEnabled: true,
    showLegend: true,
    skewLabels: false,
    unit: 'mtCO2e',
    markerSize: 7,
    paddingOverrides: { left: 60, right: 60 }
  },
  {
    pId: 15,
    layouts: {
      lg: '{"x":0,"y":36,"w":24,"h":13}',
      md: '{"x":0,"y":36,"w":24,"h":13}',
      sm: '{"x":0,"y":36,"w":12,"h":13}',
      xs: '{"x":0,"y":0,"w":4,"h":13}',
      xxs: '{"x":0,"y":0,"w":4,"h":13}'
    },
    title: 'Emissions by scope',
    titleAlignment: 'center',
    description: null,
    unitType: 'NUMBER',
    hideBorder: false,
    hideTitle: true,
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.category', 'esg.subCategory']
    },
    data: [
      {
        'esg.category': '2021',
        'esg.data': 300,
        'esg.subCategory': 'Scope 1'
      },
      {
        'esg.category': '2021',
        'esg.data': 150,
        'esg.subCategory': 'Scope 2'
      },
      {
        'esg.category': '2021',
        'esg.data': 650,
        'esg.subCategory': 'Scope 3'
      },
      {
        'esg.category': '2022',
        'esg.data': 200,
        'esg.subCategory': 'Scope 1'
      },
      {
        'esg.category': '2022',
        'esg.data': 150,
        'esg.subCategory': 'Scope 2'
      },
      {
        'esg.category': '2022',
        'esg.data': 500,
        'esg.subCategory': 'Scope 3'
      },
      {
        'esg.category': '2023',
        'esg.data': 310,
        'esg.subCategory': 'Scope 1'
      },
      {
        'esg.category': '2023',
        'esg.data': 100,
        'esg.subCategory': 'Scope 2'
      },
      {
        'esg.category': '2023',
        'esg.data': 400,
        'esg.subCategory': 'Scope 3'
      },
      {
        'esg.category': '2024',
        'esg.data': 0,
        'esg.subCategory': 'Scope 1'
      },
      {
        'esg.category': '2025',
        'esg.data': 0,
        'esg.subCategory': 'Scope 2'
      },
      {
        'esg.category': '2026',
        'esg.data': 0,
        'esg.subCategory': 'Scope 3'
      },
      {
        'esg.category': '2027',
        'esg.data': 0,
        'esg.subCategory': 'Scope 3'
      },
      {
        'esg.category': '2028',
        'esg.data': 0,
        'esg.subCategory': 'Scope 3'
      },
      {
        'esg.category': '2029',
        'esg.data': 0,
        'esg.subCategory': 'Scope 3'
      },
      {
        'esg.category': '2030',
        'esg.data': 0,
        'esg.subCategory': 'Scope 3'
      }
    ],
    chartType: 'bar',
    showRefData: true,
    stacked: true,
    hideYAxis: false,
    showLegend: true,
    titleEnabled: true,
    showDataLabels: false,
    tooltipsEnabled: true,
    colorPalette: 'palette2',
    barWidth: 50,
    fontSize: 12,
    unit: 'mtCO2e'
  },
  {
    pId: 15,
    layouts: {
      lg: '{"x":0,"y":52,"w":40,"h":15}',
      md: '{"x":0,"y":52,"w":40,"h":15}',
      sm: '{"x":0,"y":0,"w":12,"h":10}',
      xs: '{"x":6,"y":0,"w":6,"h":10}',
      xxs: '{"x":6,"y":0,"w":6,"h":10}'
    },
    title: 'Scope 3 Emissions',
    unitType: 'NUMBER',
    description: ' By Category',
    shortDescription: ' By Category',
    centeredTitle: 'Scope 3 Emissions',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Temporary Infrastructure',
        'esg.data': 29
      },
      {
        'esg.kpi': 'Entertainment',
        'esg.data': 25
      },
      {
        'esg.kpi': 'Professional Fees',
        'esg.data': 10
      },
      {
        'esg.kpi': 'Staffing(Cleaning)',
        'esg.data': 5
      },
      {
        'esg.kpi': 'Energy',
        'esg.data': 8
      },
      {
        'esg.kpi': 'Transport',
        'esg.data': 6
      },
      {
        'esg.kpi': 'Staffing(Security)',
        'esg.data': 2
      },
      {
        'esg.kpi': 'Fugitive',
        'esg.data': 9
      },
      {
        'esg.kpi': 'Broadcast / Connectivity',
        'esg.data': 1
      },
      {
        'esg.kpi': 'Traffic Management',
        'esg.data': 3
      },
      {
        'esg.kpi': 'Staffing (Other)',
        'esg.data': 6
      }
    ],
    paddingOverrides: { left: 10, right: 10, top: 20, bottom: 10 },
    chartType: 'donut',
    chartHeight: 1400,
    showRefData: false,
    titleEnabled: false,
    showLegend: true,
    borderRadius: 0.5,
    colorScheme: 'category10',
    hideBorder: false,
    unit: 'mtCO2e'
  },
  {
    pId: 15,
    layouts: {
      lg: '{"x":20,"y":70,"w":40,"h":1.5}',
      md: '{"x":20,"y":70,"w":40,"h":1.5}',
      sm: '{"x":0,"y":0,"w":12,"h":10}',
      xs: '{"x":6,"y":0,"w":6,"h":10}',
      xxs: '{"x":6,"y":0,"w":6,"h":10}'
    },
    title: 'Link to Environment',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [],
    chartType: 'hyperLink',
    chartHeight: 1400,
    marginX: 2,
    marginY: 2,
    hideBorder: true,
    hideTitle: true,
    alignment: 'right',
    link: `${PATH_DASHBOARD.general.environment.root}`,
    label: 'View more details'
  },
  // {
  //   pId: 15,
  //   chartType: 'separator',
  //   title: 'sep10',
  //   layouts: {
  //     lg: '{"x":0,"y":73,"w":30,"h":1.5}',
  //     md: '{"x":0,"y":73,"w":30,"h":1.5}',
  //     sm: '{"x":0,"y":0,"w":3,"h":2}',
  //     xs: '{"x":0,"y":0,"w":4,"h":1}',
  //     xxs: '{"x":0,"y":0,"w":4,"h":1}'
  //   },
  //   marginY: 6,
  //   hideBorder: true,
  //   query: {
  //     measures: [],
  //     dimensions: []
  //   },
  //   data: []
  // },
  // {
  //   pId: 15,
  //   chartType: 'organisationalHealth',
  //   title: 'Organisational Health',
  //   layouts: {
  //     lg: '{"x":0,"y":75,"w":30,"h":1.5}',
  //     md: '{"x":0,"y":75,"w":30,"h":1.5}',
  //     sm: '{"x":0,"y":0,"w":3,"h":2}',
  //     xs: '{"x":0,"y":0,"w":4,"h":1}',
  //     xxs: '{"x":0,"y":0,"w":4,"h":1}'
  //   },
  //   marginY: 6,
  //   hideBorder: true,
  //   hideTitle: true,
  //   query: {
  //     measures: [],
  //     dimensions: []
  //   },
  //   data: []
  // },
  // {
  //   pId: 15,
  //   chartType: 'titleBox',
  //   layouts: {
  //     lg: '{"x":0,"y":6,"w":20,"h":2}',
  //     md: '{"x":0,"y":6,"w":6,"h":2}',
  //     sm: '{"x":0,"y":6,"w":3,"h":2}',
  //     xs: '{"x":0,"y":6,"w":4,"h":2}',
  //     xxs: '{"x":0,"y":6,"w":4,"h":2}'
  //   },
  //   title:
  //     'This covers emissions resulting in Refrigerants, Fire extinguishers, Vehicles, Generators and Cooking by Category',
  //   titleAlignment: 'flex-start',
  //   // titleColor: '#1d71b8',
  //   hideBorder: true,
  //   description: null,
  //   fontSize: 14,
  //   query: {
  //     measures: [],
  //     dimensions: []
  //   },
  //   data: []
  // },

  /////////////////////////////////////////////////////////////////////////////////////

  // {
  //   pId: 14,
  //   layouts: {
  //     lg: '{"x":7,"y":0,"w":4,"h":8}',
  //     md: '{"x":6,"y":0,"w":6,"h":8}',
  //     sm: '{"x":3,"y":0,"w":3,"h":8}',
  //     xs: '{"x":0,"y":0,"w":4,"h":8}',
  //     xxs: '{"x":0,"y":0,"w":4,"h":8}'
  //   },
  //   title: 'Registered',
  //   titleAlignment: 'center',
  //   titleColor: '#00b1aa',
  //   hideBorder: false,
  //   description: null,
  //   unitType: 'NUMBER',
  //   query: {
  //     measures: ['esg.data', 'esg.refData'],
  //     dimensions: ['esg.kpi']
  //   },
  //   data: [
  //     {
  //       'esg.kpi': 'Supplier total registrations',
  //       'esg.data': 149,
  //       'esg.refData': 200
  //     }
  //   ],
  //   loadResponses: [
  //     {
  //       query: {
  //         measures: ['suppliers.regsitration.total'],
  //         timeDimensions: [
  //           {
  //             dimension: 'suppliers.date',
  //             granularity: 'year'
  //           }
  //         ],
  //         order: [
  //           {
  //             id: 'suppliers.date',
  //             desc: false
  //           }
  //         ],
  //         filters: [],
  //         timezone: 'UTC',
  //         dimensions: []
  //       },
  //       data: [
  //         {
  //           'suppliers.date.year': '2022-01-01T00:00:00.000',
  //           'suppliers.date': '2022-01-01T00:00:00.000',
  //           'suppliers.regsitration.total': 149,
  //           'time.year': '2022-01-01T00:00:00.000'
  //         }
  //       ],
  //       lastRefreshTime: '2023-12-12T14:12:38.782Z',
  //       annotation: {
  //         measures: {
  //           'suppliers.regsitration.total': {
  //             title: 'suppliers regsitration total',
  //             shortTitle: 'regsitration.total',
  //             type: 'number',
  //             drillMembers: [],
  //             drillMembersGrouped: {
  //               measures: [],
  //               dimensions: []
  //             }
  //           }
  //         },
  //         dimensions: {},
  //         segments: {},
  //         timeDimensions: {
  //           'suppliers.date.year': {
  //             title: 'suppliers Date',
  //             shortTitle: 'Date',
  //             type: 'time'
  //           },
  //           'suppliers.date': {
  //             title: 'suppliers Date',
  //             shortTitle: 'Date',
  //             type: 'time'
  //           }
  //         }
  //       },
  //       dataSource: 'default',
  //       dbType: 'postgres',
  //       extDbType: 'postgres',
  //       external: false,
  //       slowQuery: false,
  //       total: null
  //     },
  //     {
  //       query: {
  //         measures: ['suppliers.regsitration.total'],
  //         timeDimensions: [
  //           {
  //             dimension: 'suppliers.date',
  //             granularity: 'year'
  //           }
  //         ],
  //         order: [
  //           {
  //             id: 'supplier.date',
  //             desc: false
  //           }
  //         ],
  //         filters: [],
  //         timezone: 'UTC',
  //         dimensions: []
  //       },
  //       data: [
  //         {
  //           'suppliers.date.year': '2021-01-01T00:00:00.000',
  //           'suppliers.date': '2021-01-01T00:00:00.000',
  //           'suppliers.regsitration.total': 200,
  //           'time.year': '2021-01-01T00:00:00.000'
  //         }
  //       ],
  //       lastRefreshTime: '2023-12-12T14:12:38.810Z',
  //       annotation: {
  //         measures: {
  //           'suppliers.regsitration.total': {
  //             title: 'Suppliers regsitration.total',
  //             shortTitle: 'Suppliers',
  //             type: 'number',
  //             drillMembers: [],
  //             drillMembersGrouped: {
  //               measures: [],
  //               dimensions: []
  //             }
  //           }
  //         },
  //         dimensions: {},
  //         segments: {},
  //         timeDimensions: {
  //           'suppliers.date.year': {
  //             title: 'Suppliers Date',
  //             shortTitle: 'Date',
  //             type: 'time'
  //           },
  //           'suppliers.date': {
  //             title: 'suppliers Date',
  //             shortTitle: 'Date',
  //             type: 'time'
  //           }
  //         }
  //       },
  //       dataSource: 'default',
  //       dbType: 'postgres',
  //       extDbType: 'postgres',
  //       external: false,
  //       slowQuery: false,
  //       total: null
  //     }
  //   ],
  //   chartType: 'number',
  //   showRefData: true,
  //   shortDescription: '',
  //   trendEnabled: true
  // },
  // {
  //   pId: 14,
  //   layouts: {
  //     lg: '{"x":12,"y":0,"w":4,"h":8}',
  //     md: '{"x":12,"y":0,"w":6,"h":8}',
  //     sm: '{"x":6,"y":0,"w":3,"h":8}',
  //     xs: '{"x":0,"y":0,"w":4,"h":8}',
  //     xxs: '{"x":0,"y":0,"w":4,"h":8}'
  //   },
  //   title: 'Registration Rate',
  //   titleAlignment: 'center',
  //   titleColor: '#3F830E',
  //   hideBorder: false,
  //   description: null,
  //   unitType: 'PERCENT',
  //   query: {
  //     measures: ['esg.data', 'esg.refData'],
  //     dimensions: ['esg.kpi']
  //   },
  //   data: [
  //     {
  //       'esg.kpi': 'Supplier registration rate',
  //       'esg.data': 21,
  //       'esg.refData': 30
  //     }
  //   ],
  //   loadResponses: [
  //     {
  //       query: {
  //         measures: ['suppliers.regsitration.rate'],
  //         timeDimensions: [
  //           {
  //             dimension: 'suppliers.date',
  //             granularity: 'year'
  //           }
  //         ],
  //         order: [
  //           {
  //             id: 'suppliers.date',
  //             desc: false
  //           }
  //         ],
  //         filters: [],
  //         timezone: 'UTC',
  //         dimensions: []
  //       },
  //       data: [
  //         {
  //           'suppliers.date.year': '2022-01-01T00:00:00.000',
  //           'suppliers.date': '2022-01-01T00:00:00.000',
  //           'suppliers.regsitration.rate': 21,
  //           'time.year': '2022-01-01T00:00:00.000'
  //         }
  //       ],
  //       lastRefreshTime: '2023-12-12T14:12:38.782Z',
  //       annotation: {
  //         measures: {
  //           'suppliers.regsitration.rate': {
  //             title: 'suppliers regsitration rate',
  //             shortTitle: 'regsitration.rate',
  //             type: 'number',
  //             drillMembers: [],
  //             drillMembersGrouped: {
  //               measures: [],
  //               dimensions: []
  //             }
  //           }
  //         },
  //         dimensions: {},
  //         segments: {},
  //         timeDimensions: {
  //           'suppliers.date.year': {
  //             title: 'suppliers Date',
  //             shortTitle: 'Date',
  //             type: 'time'
  //           },
  //           'suppliers.date': {
  //             title: 'suppliers Date',
  //             shortTitle: 'Date',
  //             type: 'time'
  //           }
  //         }
  //       },
  //       dataSource: 'default',
  //       dbType: 'postgres',
  //       extDbType: 'postgres',
  //       external: false,
  //       slowQuery: false,
  //       total: null
  //     },
  //     {
  //       query: {
  //         measures: ['suppliers.regsitration.rate'],
  //         timeDimensions: [
  //           {
  //             dimension: 'suppliers.date',
  //             granularity: 'year'
  //           }
  //         ],
  //         order: [
  //           {
  //             id: 'supplier.date',
  //             desc: false
  //           }
  //         ],
  //         filters: [],
  //         timezone: 'UTC',
  //         dimensions: []
  //       },
  //       data: [
  //         {
  //           'suppliers.date.year': '2021-01-01T00:00:00.000',
  //           'suppliers.date': '2021-01-01T00:00:00.000',
  //           'suppliers.regsitration.rate': 30,
  //           'time.year': '2021-01-01T00:00:00.000'
  //         }
  //       ],
  //       lastRefreshTime: '2023-12-12T14:12:38.810Z',
  //       annotation: {
  //         measures: {
  //           'suppliers.regsitration.rate': {
  //             title: 'Suppliers regsitration.rate',
  //             shortTitle: 'Suppliers',
  //             type: 'number',
  //             drillMembers: [],
  //             drillMembersGrouped: {
  //               measures: [],
  //               dimensions: []
  //             }
  //           }
  //         },
  //         dimensions: {},
  //         segments: {},
  //         timeDimensions: {
  //           'suppliers.date.year': {
  //             title: 'Suppliers Date',
  //             shortTitle: 'Date',
  //             type: 'time'
  //           },
  //           'suppliers.date': {
  //             title: 'suppliers Date',
  //             shortTitle: 'Date',
  //             type: 'time'
  //           }
  //         }
  //       },
  //       dataSource: 'default',
  //       dbType: 'postgres',
  //       extDbType: 'postgres',
  //       external: false,
  //       slowQuery: false,
  //       total: null
  //     }
  //   ],
  //   chartType: 'number',
  //   showRefData: true,
  //   shortDescription: '',
  //   trendEnabled: true
  // },
  // {
  //   pId: 14,
  //   layouts: {
  //     lg: '{"x":17,"y":0,"w":4,"h":8}',
  //     md: '{"x":18,"y":0,"w":6,"h":8}',
  //     sm: '{"x":9,"y":0,"w":3,"h":8}',
  //     xs: '{"x":0,"y":0,"w":4,"h":8}',
  //     xxs: '{"x":0,"y":0,"w":4,"h":8}'
  //   },
  //   title: 'Data Submissions',
  //   titleAlignment: 'center',
  //   titleColor: '#B71926',
  //   hideBorder: false,
  //   description: null,
  //   unitType: 'NUMBER',
  //   query: {
  //     measures: ['esg.data'],
  //     dimensions: ['esg.kpi'],
  //     timeDimensions: [
  //       {
  //         dimension: 'esg.date'
  //       }
  //     ]
  //   },
  //   data: [
  //     {
  //       'esg.date.year': '2022-01-01T00:00:00.000',
  //       'esg.kpi': 'Supplier total data submissions',
  //       'esg.data': 82
  //     },
  //     {
  //       'esg.date.year': '2021-01-01T00:00:00.000',
  //       'esg.kpi': 'Supplier total data submissions',
  //       'esg.data': 70
  //     }
  //   ],
  //   loadResponses: [
  //     {
  //       query: {
  //         measures: ['suppliers.submissions'],
  //         timeDimensions: [
  //           {
  //             dimension: 'suppliers.date',
  //             granularity: 'year'
  //           }
  //         ],
  //         order: [
  //           {
  //             id: 'suppliers.date',
  //             desc: false
  //           }
  //         ],
  //         filters: [],
  //         timezone: 'UTC',
  //         dimensions: []
  //       },
  //       data: [
  //         {
  //           'suppliers.date.year': '2022-01-01T00:00:00.000',
  //           'suppliers.date': '2022-01-01T00:00:00.000',
  //           'suppliers.submissions': 82,
  //           'time.year': '2022-01-01T00:00:00.000'
  //         }
  //       ],
  //       lastRefreshTime: '2023-12-12T14:12:38.782Z',
  //       annotation: {
  //         measures: {
  //           'suppliers.submissions': {
  //             title: 'suppliers Emissions',
  //             shortTitle: 'submissions',
  //             type: 'number',
  //             drillMembers: [],
  //             drillMembersGrouped: {
  //               measures: [],
  //               dimensions: []
  //             }
  //           }
  //         },
  //         dimensions: {},
  //         segments: {},
  //         timeDimensions: {
  //           'suppliers.date.year': {
  //             title: 'suppliers Date',
  //             shortTitle: 'Date',
  //             type: 'time'
  //           },
  //           'suppliers.date': {
  //             title: 'suppliers Date',
  //             shortTitle: 'Date',
  //             type: 'time'
  //           }
  //         }
  //       },
  //       dataSource: 'default',
  //       dbType: 'postgres',
  //       extDbType: 'postgres',
  //       external: false,
  //       slowQuery: false,
  //       total: null
  //     },
  //     {
  //       query: {
  //         measures: ['suppliers.submissions'],
  //         timeDimensions: [
  //           {
  //             dimension: 'suppliers.date',
  //             granularity: 'year'
  //           }
  //         ],
  //         order: [
  //           {
  //             id: 'supplier.date',
  //             desc: false
  //           }
  //         ],
  //         filters: [],
  //         timezone: 'UTC',
  //         dimensions: []
  //       },
  //       data: [
  //         {
  //           'suppliers.date.year': '2021-01-01T00:00:00.000',
  //           'suppliers.date': '2021-01-01T00:00:00.000',
  //           'suppliers.submissions': 70,
  //           'time.year': '2021-01-01T00:00:00.000'
  //         }
  //       ],
  //       lastRefreshTime: '2023-12-12T14:12:38.810Z',
  //       annotation: {
  //         measures: {
  //           'emissions.emissions': {
  //             title: 'Emissions Emissions',
  //             shortTitle: 'Emissions',
  //             type: 'number',
  //             drillMembers: [],
  //             drillMembersGrouped: {
  //               measures: [],
  //               dimensions: []
  //             }
  //           }
  //         },
  //         dimensions: {},
  //         segments: {},
  //         timeDimensions: {
  //           'suppliers.date.year': {
  //             title: 'Suppliers Date',
  //             shortTitle: 'Date',
  //             type: 'time'
  //           },
  //           'suppliers.date': {
  //             title: 'suppliers Date',
  //             shortTitle: 'Date',
  //             type: 'time'
  //           }
  //         }
  //       },
  //       dataSource: 'default',
  //       dbType: 'postgres',
  //       extDbType: 'postgres',
  //       external: false,
  //       slowQuery: false,
  //       total: null
  //     }
  //   ],
  //   chartType: 'number',
  //   showRefData: false,
  //   trendEnabled: true
  // },

  /////////////////////////////////////////////////////////////////////////////
  // {
  //   pId: 12,
  //   chartType: 'titleBox',
  //   layouts: {
  //     lg: '{"x":0,"y":0,"w":20,"h":1.5}',
  //     md: '{"x":0,"y":0,"w":6,"h":1.5}',
  //     sm: '{"x":0,"y":0,"w":3,"h":1.5}',
  //     xs: '{"x":0,"y":0,"w":4,"h":1.5}',
  //     xxs: '{"x":0,"y":0,"w":4,"h":1.5}'
  //   },
  //   title: '2022-2023',
  //   titleAlignment: 'flex-start',
  //   titleColor: '#0A8A84',
  //   hideBorder: true,
  //   description: null,
  //   fontSize: 22,
  //   query: {
  //     measures: [],
  //     dimensions: []
  //   },
  //   data: []
  // },
  {
    pId: 12,
    chartType: 'esgIllustration',
    layouts: {
      lg: '{"x":0,"y":0,"w":40,"h":3}',
      md: '{"x":0,"y":0,"w":40,"h":3}',
      sm: '{"x":0,"y":0,"w":3,"h":3}',
      xs: '{"x":0,"y":0,"w":4,"h":3}',
      xxs: '{"x":0,"y":0,"w":4,"h":3}'
    },
    title: 'Scope 1 Emissionssss',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 24,
    query: {
      measures: [],
      dimensions: []
    },
    illustrationUrl:
      'https://media.experienz.co.uk/shared/esg/backgrounds/carbonemissions-extended.png'
  },
  {
    pId: 12,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":4,"w":20,"h":1.5}',
      md: '{"x":0,"y":4,"w":6,"h":1.5}',
      sm: '{"x":0,"y":0,"w":3,"h":1.5}',
      xs: '{"x":0,"y":0,"w":4,"h":1.5}',
      xxs: '{"x":0,"y":0,"w":4,"h":1.5}'
    },
    title: 'Overview of Supplier Registrations',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 24,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 12,
    chartType: 'titleBox',
    layouts: {
      lg: '{"x":0,"y":6,"w":20,"h":2}',
      md: '{"x":0,"y":6,"w":6,"h":2}',
      sm: '{"x":0,"y":0,"w":3,"h":2}',
      xs: '{"x":0,"y":0,"w":4,"h":2}',
      xxs: '{"x":0,"y":0,"w":4,"h":2}'
    },
    title: 'Key insights into supplier engagement and data submission',
    titleAlignment: 'flex-start',
    // titleColor: '#1d71b8',
    hideBorder: true,
    description: null,
    fontSize: 14,
    query: {
      measures: [],
      dimensions: []
    },
    data: []
  },
  {
    pId: 12,
    layouts: {
      lg: '{"x":2,"y":9,"w":4,"h":8}',
      md: '{"x":0,"y":9,"w":6,"h":8}',
      sm: '{"x":0,"y":0,"w":3,"h":8}',
      xs: '{"x":0,"y":0,"w":4,"h":8}',
      xxs: '{"x":0,"y":0,"w":4,"h":8}'
    },
    title: 'Invited',
    titleAlignment: 'center',
    titleColor: '#1d71b8',
    hideBorder: false,
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Supplier total invitations',
        'esg.data': 732,
        'esg.refData': 500
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['suppliers.invited'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'suppliers.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2022-01-01T00:00:00.000',
            'suppliers.date': '2022-01-01T00:00:00.000',
            'suppliers.invited': 732,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'suppliers.invited': {
              title: 'suppliers invited',
              shortTitle: 'suppliers.invited',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['suppliers.invited'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'supplier.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2021-01-01T00:00:00.000',
            'suppliers.date': '2021-01-01T00:00:00.000',
            'suppliers.invited': 200,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'suppliers.invited': {
              title: 'Suppliers invited',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'Suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    trendEnabled: true
  },
  {
    pId: 12,
    layouts: {
      lg: '{"x":7,"y":9,"w":4,"h":8}',
      md: '{"x":6,"y":9,"w":6,"h":8}',
      sm: '{"x":3,"y":0,"w":3,"h":8}',
      xs: '{"x":0,"y":0,"w":4,"h":8}',
      xxs: '{"x":0,"y":0,"w":4,"h":8}'
    },
    title: 'Registered',
    titleAlignment: 'center',
    titleColor: '#00b1aa',
    hideBorder: false,
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Supplier total registrations',
        'esg.data': 149,
        'esg.refData': 200
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['suppliers.regsitration.total'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'suppliers.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2022-01-01T00:00:00.000',
            'suppliers.date': '2022-01-01T00:00:00.000',
            'suppliers.regsitration.total': 149,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'suppliers.regsitration.total': {
              title: 'suppliers regsitration total',
              shortTitle: 'regsitration.total',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['suppliers.regsitration.total'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'supplier.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2021-01-01T00:00:00.000',
            'suppliers.date': '2021-01-01T00:00:00.000',
            'suppliers.regsitration.total': 200,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'suppliers.regsitration.total': {
              title: 'Suppliers regsitration.total',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'Suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    shortDescription: '',
    trendEnabled: true
  },
  {
    pId: 12,
    layouts: {
      lg: '{"x":12,"y":9,"w":4,"h":8}',
      md: '{"x":12,"y":9,"w":6,"h":8}',
      sm: '{"x":6,"y":0,"w":3,"h":8}',
      xs: '{"x":0,"y":0,"w":4,"h":8}',
      xxs: '{"x":0,"y":0,"w":4,"h":8}'
    },
    title: 'Registration Rate',
    titleAlignment: 'center',
    titleColor: '#3F830E',
    hideBorder: false,
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data', 'esg.refData'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Supplier registration rate',
        'esg.data': 21,
        'esg.refData': 30
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['suppliers.regsitration.rate'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'suppliers.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2022-01-01T00:00:00.000',
            'suppliers.date': '2022-01-01T00:00:00.000',
            'suppliers.regsitration.rate': 21,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'suppliers.regsitration.rate': {
              title: 'suppliers regsitration rate',
              shortTitle: 'regsitration.rate',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['suppliers.regsitration.rate'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'supplier.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2021-01-01T00:00:00.000',
            'suppliers.date': '2021-01-01T00:00:00.000',
            'suppliers.regsitration.rate': 30,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'suppliers.regsitration.rate': {
              title: 'Suppliers regsitration.rate',
              shortTitle: 'Suppliers',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'Suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: true,
    shortDescription: '',
    trendEnabled: true
  },
  {
    pId: 12,
    layouts: {
      lg: '{"x":17,"y":9,"w":4,"h":8}',
      md: '{"x":18,"y":9,"w":6,"h":8}',
      sm: '{"x":9,"y":0,"w":3,"h":8}',
      xs: '{"x":0,"y":0,"w":4,"h":8}',
      xxs: '{"x":0,"y":0,"w":4,"h":8}'
    },
    title: 'Data Submissions',
    titleAlignment: 'center',
    titleColor: '#B71926',
    hideBorder: false,
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi'],
      timeDimensions: [
        {
          dimension: 'esg.date'
        }
      ]
    },
    data: [
      {
        'esg.date.year': '2022-01-01T00:00:00.000',
        'esg.kpi': 'Supplier total data submissions',
        'esg.data': 82
      },
      {
        'esg.date.year': '2021-01-01T00:00:00.000',
        'esg.kpi': 'Supplier total data submissions',
        'esg.data': 70
      }
    ],
    loadResponses: [
      {
        query: {
          measures: ['suppliers.submissions'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'suppliers.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2022-01-01T00:00:00.000',
            'suppliers.date': '2022-01-01T00:00:00.000',
            'suppliers.submissions': 82,
            'time.year': '2022-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.782Z',
        annotation: {
          measures: {
            'suppliers.submissions': {
              title: 'suppliers Emissions',
              shortTitle: 'submissions',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      },
      {
        query: {
          measures: ['suppliers.submissions'],
          timeDimensions: [
            {
              dimension: 'suppliers.date',
              granularity: 'year'
            }
          ],
          order: [
            {
              id: 'supplier.date',
              desc: false
            }
          ],
          filters: [],
          timezone: 'UTC',
          dimensions: []
        },
        data: [
          {
            'suppliers.date.year': '2021-01-01T00:00:00.000',
            'suppliers.date': '2021-01-01T00:00:00.000',
            'suppliers.submissions': 70,
            'time.year': '2021-01-01T00:00:00.000'
          }
        ],
        lastRefreshTime: '2023-12-12T14:12:38.810Z',
        annotation: {
          measures: {
            'emissions.emissions': {
              title: 'Emissions Emissions',
              shortTitle: 'Emissions',
              type: 'number',
              drillMembers: [],
              drillMembersGrouped: {
                measures: [],
                dimensions: []
              }
            }
          },
          dimensions: {},
          segments: {},
          timeDimensions: {
            'suppliers.date.year': {
              title: 'Suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            },
            'suppliers.date': {
              title: 'suppliers Date',
              shortTitle: 'Date',
              type: 'time'
            }
          }
        },
        dataSource: 'default',
        dbType: 'postgres',
        extDbType: 'postgres',
        external: false,
        slowQuery: false,
        total: null
      }
    ],
    chartType: 'number',
    showRefData: false,
    trendEnabled: true
  },
  {
    pId: 9,
    layouts: {
      lg: '{"x":6,"y":0,"w":6,"h":8}',
      md: '{"x":6,"y":0,"w":6,"h":8}',
      sm: '{"x":3,"y":0,"w":3,"h":8}',
      xs: '{"x":0,"y":0,"w":4,"h":8}',
      xxs: '{"x":0,"y":0,"w":4,"h":8}'
    },
    title: 'Women Employees Ratio',
    titleAlignment: 'center',
    description: '',
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [{ 'esg.kpi': 'Women Employees Ratio', 'esg.data': 42.2 }],
    chartType: 'number',
    showRefData: false
  },
  {
    pId: 9,
    layouts: {
      lg: '{"x":12,"y":0,"w":6,"h":8}',
      md: '{"x":12,"y":0,"w":6,"h":8}',
      sm: '{"x":6,"y":0,"w":3,"h":8}',
      xs: '{"x":0,"y":0,"w":4,"h":8}',
      xxs: '{"x":0,"y":0,"w":4,"h":8}'
    },
    title: 'Complete Training Ratio',
    titleAlignment: 'center',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [{ 'esg.kpi': 'Completed Training Ratio', 'esg.data': 0.6 }],
    chartType: 'number',
    showRefData: false,
    shortDescription: '',
    trendEnabled: true
  },
  {
    pId: 9,
    layouts: {
      lg: '{"x":18,"y":0,"w":6,"h":8}',
      md: '{"x":18,"y":0,"w":6,"h":8}',
      sm: '{"x":9,"y":0,"w":3,"h":8}',
      xs: '{"x":0,"y":0,"w":4,"h":8}',
      xxs: '{"x":0,"y":0,"w":4,"h":8}'
    },
    title: 'Employee Satisfaction Ratio',
    titleAlignment: 'center',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [{ 'esg.kpi': 'Employee Satisfaction Ratio', 'esg.data': 48.7 }],
    chartType: 'number',
    showRefData: false
  },
  {
    pId: 9,
    layouts: {
      lg: '{"x":0,"y":9,"w":12,"h":13}',
      md: '{"x":0,"y":9,"w":12,"h":13}',
      sm: '{"x":0,"y":9,"w":6,"h":13}',
      xs: '{"x":0,"y":0,"w":4,"h":13}',
      xxs: '{"x":0,"y":0,"w":4,"h":13}'
    },
    title: 'Gender Diversity',
    titleAlignment: 'center',
    hideBorder: true,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      { 'esg.kpi': 'Male', 'esg.data': 75 },
      { 'esg.kpi': 'Female', 'esg.data': 25 }
    ],
    chartType: 'pie',
    showRefData: false
  },
  {
    pId: 9,
    layouts: {
      lg: '{"x":12,"y":9,"w":12,"h":13}',
      md: '{"x":12,"y":9,"w":12,"h":13}',
      sm: '{"x":6,"y":9,"w":6,"h":13}',
      xs: '{"x":0,"y":0,"w":4,"h":13}',
      xxs: '{"x":0,"y":0,"w":4,"h":13}'
    },
    title: 'Ethnic Diversity',
    titleAlignment: 'center',
    hideBorder: true,
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      { 'esg.kpi': 'White', 'esg.data': 33.3 },
      { 'esg.kpi': 'Black', 'esg.data': 66.7 }
    ],
    chartType: 'pie',
    showRefData: false
  },
  {
    pId: 9,
    layouts: {
      lg: '{"x":12,"y":23,"w":12,"h":13}',
      md: '{"x":12,"y":23,"w":12,"h":13}',
      sm: '{"x":6,"y":23,"w":6,"h":13}',
      xs: '{"x":0,"y":0,"w":4,"h":13}',
      xxs: '{"x":0,"y":0,"w":4,"h":13}'
    },
    title: 'Age Diversity',
    titleAlignment: 'center',
    hideBorder: true,
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      { 'esg.kpi': '18-25 years', 'esg.data': 28.6 },
      { 'esg.kpi': '25-30 years', 'esg.data': 14.3 },
      { 'esg.kpi': '30-40 years', 'esg.data': 21.4 },
      { 'esg.kpi': '40-50 years', 'esg.data': 21.4 },
      { 'esg.kpi': '50+ years', 'esg.data': 14.3 }
    ],
    chartType: 'pie',
    showRefData: false
  },
  {
    pId: 9,
    layouts: {
      lg: '{"x":0,"y":23,"w":12,"h":13}',
      md: '{"x":0,"y":23,"w":12,"h":13}',
      sm: '{"x":0,"y":23,"w":6,"h":13}',
      xs: '{"x":0,"y":0,"w":4,"h":13}',
      xxs: '{"x":0,"y":0,"w":4,"h":13}'
    },
    title: 'Tenure Diversity',
    titleAlignment: 'center',
    description: null,
    unitType: 'PERCENT',
    hideBorder: true,
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      { 'esg.kpi': '0-3 years', 'esg.data': 33.3 },
      { 'esg.kpi': '4-6 years', 'esg.data': 16.7 },
      { 'esg.kpi': '7-9 years', 'esg.data': 25 },
      { 'esg.kpi': '10+ years', 'esg.data': 25 }
    ],
    chartType: 'pie',
    showRefData: false
  },
  {
    pId: 9,
    layouts: {
      lg: '{"x":0,"y":36,"w":24,"h":13}',
      md: '{"x":0,"y":36,"w":24,"h":13}',
      sm: '{"x":0,"y":36,"w":12,"h":13}',
      xs: '{"x":0,"y":0,"w":4,"h":13}',
      xxs: '{"x":0,"y":0,"w":4,"h":13}'
    },
    title: 'Employees by Role',
    titleAlignment: 'center',
    description: null,
    unitType: 'NUMBER',
    hideBorder: true,
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.category', 'esg.subCategory']
    },
    data: [
      {
        'esg.category': 'Marketing',
        'esg.data': 43.8,
        'esg.subCategory': 'Cat 1'
      },
      {
        'esg.category': 'Marketing',
        'esg.data': 63.8,
        'esg.subCategory': 'Cat 2'
      },
      {
        'esg.category': 'IT',
        'esg.data': 36.8,
        'esg.subCategory': 'Cat 1'
      },
      {
        'esg.category': 'Engineering',
        'esg.data': 33.6,
        'esg.subCategory': 'Cat 2'
      },
      {
        'esg.category': 'HR',
        'esg.data': 32.5,
        'esg.subCategory': 'Cat 3'
      },
      {
        'esg.category': 'Management',
        'esg.data': 31.1,
        'esg.subCategory': 'Cat 4'
      }
    ],
    chartType: 'bar',
    showRefData: true,
    stacked: true,
    hideYAxis: true,
    showLegend: false,
    titleEnabled: true,
    showDataLabels: true,
    tooltipsEnabled: true,
    colorPalette: 'palette1',
    barWidth: 50,
    fontSize: 12,
    unit: 'x'
  },
  // {
  //   pId: 10,
  //   layouts: {
  //     lg: '{"x":0,"y":14,"w":24,"h":20}',
  //     md: '{"x":0,"y":14,"w":24,"h":20}',
  //     sm: '{"x":0,"y":14,"w":12,"h":20}',
  //     xs: '{"x":0,"y":14,"w":4,"h":20}',
  //     xxs: '{"x":0,"y":14,"w":4,"h":20}'
  //   },
  //   title: 'Water usage details by type (%)',
  //   description: null,
  //   unitType: 'PERCENT',
  //   query: {
  //     measures: ['esg.data'],
  //     dimensions: ['esg.kpi'],
  //     timeDimensions: [
  //       {
  //         dimension: 'esg.date',
  //         granularity: 'year'
  //       }
  //     ]
  //   },
  //   data: [
  //     {
  //       'esg.date': '2017-01-01T00:00:00.000',
  //       'esg.date.year': '2017-01-01T00:00:00.000',
  //       'esg.kpi': 'Primary water usage',
  //       'esg.data': 90
  //     },
  //     {
  //       'esg.date': '2018-01-01T00:00:00.000',
  //       'esg.date.year': '2018-01-01T00:00:00.000',
  //       'esg.kpi': 'Primary water usage',
  //       'esg.data': 87
  //     },
  //     {
  //       'esg.date': '2019-01-01T00:00:00.000',
  //       'esg.date.year': '2019-01-01T00:00:00.000',
  //       'esg.kpi': 'Primary water usage',
  //       'esg.data': 60
  //     },
  //     {
  //       'esg.date': '2020-01-01T00:00:00.000',
  //       'esg.date.year': '2020-01-01T00:00:00.000',
  //       'esg.kpi': 'Primary water usage',
  //       'esg.data': 55
  //     },
  //     {
  //       'esg.date': '2021-01-01T00:00:00.000',
  //       'esg.date.year': '2021-01-01T00:00:00.000',
  //       'esg.kpi': 'Primary water usage',
  //       'esg.data': 45
  //     },
  //     {
  //       'esg.date': '2017-01-01T00:00:00.000',
  //       'esg.date.year': '2017-01-01T00:00:00.000',
  //       'esg.kpi': 'Recycled water',
  //       'esg.data': 10
  //     },
  //     {
  //       'esg.date': '2018-01-01T00:00:00.000',
  //       'esg.date.year': '2018-01-01T00:00:00.000',
  //       'esg.kpi': 'Recycled water',
  //       'esg.data': 13
  //     },
  //     {
  //       'esg.date': '2019-01-01T00:00:00.000',
  //       'esg.date.year': '2019-01-01T00:00:00.000',
  //       'esg.kpi': 'Recycled water',
  //       'esg.data': 35
  //     },
  //     {
  //       'esg.date': '2020-01-01T00:00:00.000',
  //       'esg.date.year': '2020-01-01T00:00:00.000',
  //       'esg.kpi': 'Recycled water',
  //       'esg.data': 70
  //     },
  //     {
  //       'esg.date': '2021-01-01T00:00:00.000',
  //       'esg.date.year': '2021-01-01T00:00:00.000',
  //       'esg.kpi': 'Recycled water',
  //       'esg.data': 85
  //     },
  //     {
  //       'esg.date': '2017-01-01T00:00:00.000',
  //       'esg.date.year': '2017-01-01T00:00:00.000',
  //       'esg.kpi': 'Rain water harvesting',
  //       'esg.data': 0
  //     },
  //     {
  //       'esg.date': '2018-01-01T00:00:00.000',
  //       'esg.date.year': '2018-01-01T00:00:00.000',
  //       'esg.kpi': 'Rain water harvesting',
  //       'esg.data': 0
  //     },
  //     {
  //       'esg.date': '2019-01-01T00:00:00.000',
  //       'esg.date.year': '2019-01-01T00:00:00.000',
  //       'esg.kpi': 'Rain water harvesting',
  //       'esg.data': 5
  //     },
  //     {
  //       'esg.date': '2020-01-01T00:00:00.000',
  //       'esg.date.year': '2020-01-01T00:00:00.000',
  //       'esg.kpi': 'Rain water harvesting',
  //       'esg.data': 5
  //     },
  //     {
  //       'esg.date': '2021-01-01T00:00:00.000',
  //       'esg.date.year': '2021-01-01T00:00:00.000',
  //       'esg.kpi': 'Rain water harvesting',
  //       'esg.data': 10
  //     }
  //   ],
  //   chartType: 'radar',
  //   showRefData: false
  // },
  // {
  //   pId: 10,
  //   layouts: {
  //     lg: '{"x":0,"y":0,"w":24,"h":20}',
  //     md: '{"x":0,"y":0,"w":24,"h":13}',
  //     sm: '{"x":0,"y":0,"w":12,"h":13}',
  //     xs: '{"x":0,"y":0,"w":4,"h":13}',
  //     xxs: '{"x":0,"y":0,"w":4,"h":13}'
  //   },
  //   title: 'Organisational Health By Department',
  //   description: null,
  //   unitType: 'PERCENT',
  //   query: {
  //     measures: ['esg.data'],
  //     dimensions: ['esg.kpi', 'esg.department']
  //   },
  //   data: [
  //     {
  //       'esg.kpi': 'Physical & Emotional Wellbeing',
  //       'esg.department': 'Human Resources',
  //       'esg.data': 56
  //     },
  //     {
  //       'esg.kpi': 'Financial Wellbeing',
  //       'esg.department': 'Human Resources',
  //       'esg.data': 34
  //     },
  //     {
  //       'esg.kpi': 'Technology',
  //       'esg.department': 'Human Resources',
  //       'esg.data': 78
  //     },
  //     {
  //       'esg.kpi': 'Leadership & Culture',
  //       'esg.department': 'Human Resources',
  //       'esg.data': 40
  //     },
  //     {
  //       'esg.kpi': 'Strategy & Change Management',
  //       'esg.department': 'Human Resources',
  //       'esg.data': 33
  //     },
  //     {
  //       'esg.kpi': 'Physical & Emotional Wellbeing',
  //       'esg.department': 'Finance',
  //       'esg.data': 56
  //     },
  //     {
  //       'esg.kpi': 'Financial Wellbeing',
  //       'esg.department': 'Finance',
  //       'esg.data': 34
  //     },
  //     {
  //       'esg.kpi': 'Technology',
  //       'esg.department': 'Finance',
  //       'esg.data': 78
  //     },
  //     {
  //       'esg.kpi': 'Leadership & Culture',
  //       'esg.department': 'Finance',
  //       'esg.data': 40
  //     },
  //     {
  //       'esg.kpi': 'Strategy & Change Management',
  //       'esg.department': 'Finance',
  //       'esg.data': 33
  //     },
  //     {
  //       'esg.kpi': 'Physical & Emotional Wellbeing',
  //       'esg.department': 'Legal',
  //       'esg.data': 56
  //     },
  //     {
  //       'esg.kpi': 'Financial Wellbeing',
  //       'esg.department': 'Legal',
  //       'esg.data': 34
  //     },
  //     {
  //       'esg.kpi': 'Technology',
  //       'esg.department': 'Legal',
  //       'esg.data': 78
  //     },
  //     {
  //       'esg.kpi': 'Leadership & Culture',
  //       'esg.department': 'Legal',
  //       'esg.data': 40
  //     },
  //     {
  //       'esg.kpi': 'Strategy & Change Management',
  //       'esg.department': 'Legal',
  //       'esg.data': 33
  //     },
  //     {
  //       'esg.kpi': 'Physical & Emotional Wellbeing',
  //       'esg.department': 'Adminstration',
  //       'esg.data': 56
  //     },
  //     {
  //       'esg.kpi': 'Financial Wellbeing',
  //       'esg.department': 'Adminstration',
  //       'esg.data': 34
  //     },
  //     {
  //       'esg.kpi': 'Technology',
  //       'esg.department': 'Adminstration',
  //       'esg.data': 78
  //     },
  //     {
  //       'esg.kpi': 'Leadership & Culture',
  //       'esg.department': 'Adminstration',
  //       'esg.data': 40
  //     },
  //     {
  //       'esg.kpi': 'Strategy & Change Management',
  //       'esg.department': 'Adminstration',
  //       'esg.data': 33
  //     },
  //     {
  //       'esg.kpi': 'Physical & Emotional Wellbeing',
  //       'esg.department': 'Information Technology',
  //       'esg.data': 56
  //     },
  //     {
  //       'esg.kpi': 'Financial Wellbeing',
  //       'esg.department': 'Information Technology',
  //       'esg.data': 34
  //     },
  //     {
  //       'esg.kpi': 'Technology',
  //       'esg.department': 'Information Technology',
  //       'esg.data': 78
  //     },
  //     {
  //       'esg.kpi': 'Leadership & Culture',
  //       'esg.department': 'Information Technology',
  //       'esg.data': 40
  //     },
  //     {
  //       'esg.kpi': 'Strategy & Change Management',
  //       'esg.department': 'Information Technology',
  //       'esg.data': 33
  //     },
  //     {
  //       'esg.kpi': 'Physical & Emotional Wellbeing',
  //       'esg.department': 'Logistics',
  //       'esg.data': 56
  //     },
  //     {
  //       'esg.kpi': 'Financial Wellbeing',
  //       'esg.department': 'Logistics',
  //       'esg.data': 34
  //     },
  //     {
  //       'esg.kpi': 'Technology',
  //       'esg.department': 'Logistics',
  //       'esg.data': 78
  //     },
  //     {
  //       'esg.kpi': 'Leadership & Culture',
  //       'esg.department': 'Logistics',
  //       'esg.data': 40
  //     },
  //     {
  //       'esg.kpi': 'Strategy & Change Management',
  //       'esg.department': 'Logistics',
  //       'esg.data': 33
  //     }
  //   ],
  //   chartType: 'bubble',
  //   showRefData: true
  // },
  {
    pId: 10,
    layouts: {
      lg: '{"x":0,"y":0,"w":6,"h":6}',
      md: '{"x":0,"y":0,"w":6,"h":6}',
      sm: '{"x":0,"y":0,"w":3,"h":6}',
      xs: '{"x":0,"y":0,"w":4,"h":6}',
      xxs: '{"x":0,"y":0,"w":4,"h":6}'
    },
    title: 'Tonnes CO2e',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [{ 'esg.kpi': 'Tonnes CO2e', 'esg.data': 304766.77 }],
    chartType: 'number',
    showRefData: false
  },
  {
    pId: 10,
    layouts: {
      lg: '{"x":6,"y":0,"w":6,"h":6}',
      md: '{"x":6,"y":0,"w":6,"h":6}',
      sm: '{"x":3,"y":0,"w":3,"h":6}',
      xs: '{"x":0,"y":0,"w":4,"h":6}',
      xxs: '{"x":0,"y":0,"w":4,"h":6}'
    },
    title: 'Tonnes C02',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [{ 'esg.kpi': 'Tonnes C02', 'esg.data': 272589.41 }],
    chartType: 'number',
    showRefData: false
  },
  {
    pId: 10,
    layouts: {
      lg: '{"x":12,"y":0,"w":6,"h":6}',
      md: '{"x":12,"y":0,"w":6,"h":6}',
      sm: '{"x":6,"y":0,"w":3,"h":6}',
      xs: '{"x":0,"y":0,"w":4,"h":6}',
      xxs: '{"x":0,"y":0,"w":4,"h":6}'
    },
    title: 'Tonnes CH4',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [{ 'esg.kpi': 'Tonnes CH4', 'esg.data': 22674.27 }],
    chartType: 'number',
    showRefData: false
  },
  {
    pId: 10,
    layouts: {
      lg: '{"x":18,"y":0,"w":6,"h":6}',
      md: '{"x":18,"y":0,"w":6,"h":6}',
      sm: '{"x":9,"y":0,"w":3,"h":6}',
      xs: '{"x":0,"y":0,"w":4,"h":6}',
      xxs: '{"x":0,"y":0,"w":4,"h":6}'
    },
    title: 'Tonnes N2O',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [{ 'esg.kpi': 'Tonnes N2O', 'esg.data': 9503.11 }],
    chartType: 'number',
    showRefData: false
  },
  {
    pId: 10,
    layouts: {
      lg: '{"x":0,"y":8,"w":24,"h":14}',
      md: '{"x":0,"y":8,"w":24,"h":14}',
      sm: '{"x":0,"y":8,"w":12,"h":14}',
      xs: '{"x":0,"y":8,"w":8,"h":13}',
      xxs: '{"x":0,"y":8,"w":8,"h":13}'
    },
    title: 'CO2 emissions (metric tons per capita)',
    description: '<p style="font-size:12px;">Sample description</p>',
    // '<p style="font-size:12px; margin-left: 20px;">Emissions in tons per capita. Reference data for South Asia (provided by <a href="https://databank.worldbank.org/reports.aspx?source=3711&series=EN.ATM.CO2E.PC&country=EAS" target="_blank">worldbank.org</a>) <br/><br/> <a href="https://sdgs.un.org/goals/goal13" target="_blank">UN SDG Goal 13</a>: Take urgent action to combat climate change and its impacts</p>',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.target', 'esg.data'],
      timeDimensions: [
        {
          dimension: 'esg.date',
          granularity: 'year'
        }
      ],
      dimensions: ['esg.date']
    },
    data: [
      {
        'esg.date': '2019-01-01T00:00:00.000',
        'esg.date.year': '2019-01-01T00:00:00.000',
        'esg.target': 772290,
        'esg.data': 772290
      },
      {
        'esg.date': '2020-01-01T00:00:00.000',
        'esg.date.year': '2020-01-01T00:00:00.000',
        'esg.target': 632290,
        'esg.data': 572190
      },
      {
        'esg.date': '2021-01-01T00:00:00.000',
        'esg.date.year': '2021-01-01T00:00:00.000',
        'esg.target': 450000,
        'esg.data': 402000
      }
    ],
    chartType: 'mixed',
    showRefData: true,
    // labels: 'T,E',
    barWidth: 1,
    colorPalette: 'palette2',
    fontSize: 12,
    monoChromeColors: true,
    monoChromeBaseColor: 'success'
  },

  {
    pId: 11,
    layouts: {
      lg: '{"x":0,"y":14,"w":12,"h":13}',
      md: '{"x":0,"y":14,"w":12,"h":13}',
      sm: '{"x":0,"y":14,"w":6,"h":13}',
      xs: '{"x":0,"y":14,"w":4,"h":13}',
      xxs: '{"x":0,"y":14,"w":4,"h":13}'
    },
    title: 'Scope 1 Emissions',
    description: 'by year (mtCO2e)',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 42331
      },
      {
        'esg.kpi': 2018,
        'esg.data': 50321
      },
      {
        'esg.kpi': 2019,
        'esg.data': 47510
      },
      {
        'esg.kpi': 2020,
        'esg.data': 40389
      },
      {
        'esg.kpi': 2021,
        'esg.data': 42896
      }
    ],
    chartType: 'bar',
    showRefData: false,
    hideYAxis: false,
    hideYAxis: true,
    showLegend: false,
    titleEnabled: true,
    showDataLabels: true,
    tooltipsEnabled: true,
    paddingOverrides: { top: 30, bottom: 30 },
    colorPalette: 'palette4',
    barWidth: 65,
    fontSize: 14,
    hideBorder: true
  },
  {
    pId: 11,
    layouts: {
      lg: '{"x":12,"y":8,"w":12,"h":13}',
      md: '{"x":12,"y":8,"w":12,"h":13}',
      sm: '{"x":6,"y":8,"w":6,"h":13}',
      xs: '{"x":0,"y":8,"w":4,"h":13}',
      xxs: '{"x":0,"y":8,"w":4,"h":13}'
    },
    title: 'Scope 2 Emissions',
    description: 'by year (mtCO2e)',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 54231
      },
      {
        'esg.kpi': 2018,
        'esg.data': 95021
      },
      {
        'esg.kpi': 2019,
        'esg.data': 94710
      },
      {
        'esg.kpi': 2020,
        'esg.data': 74089
      },
      {
        'esg.kpi': 2021,
        'esg.data': 64896
      }
    ],
    chartType: 'bar',
    showRefData: false,
    hideYAxis: false,
    hideYAxis: true,
    showLegend: false,
    titleEnabled: true,
    showDataLabels: true,
    tooltipsEnabled: true,
    paddingOverrides: { top: 30, bottom: 30 },
    colorPalette: 'palette2',
    barWidth: 65,
    fontSize: 14,
    hideBorder: true
  },
  {
    pId: 11,
    layouts: {
      lg: '{"x":18,"y":0,"w":12,"h":10}',
      md: '{"x":18,"y":0,"w":12,"h":10}',
      sm: '{"x":9,"y":0,"w":6,"h":10}',
      xs: '{"x":0,"y":0,"w":4,"h":10}',
      xxs: '{"x":0,"y":0,"w":4,"h":10}'
    },
    title: 'Scope 3 Emissions',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [{ 'esg.kpi': 'Tonnes N2O', 'esg.data': 935503.11 }],
    chartType: 'number',
    showRefData: false,
    hideBorder: true
  },
  {
    pId: 11,
    layouts: {
      lg: '{"x":0,"y":8,"w":24,"h":18}',
      md: '{"x":0,"y":8,"w":24,"h":18}',
      sm: '{"x":0,"y":8,"w":12,"h":14}',
      xs: '{"x":0,"y":8,"w":8,"h":13}',
      xxs: '{"x":0,"y":8,"w":8,"h":13}'
    },
    title: 'Emissions Lineage',
    description: '<p>Some description.</p>',
    unitType: 'NUMBER',
    query: {
      measures: ['emissions.data'],
      timeDimensions: [
        {
          dimension: 'emissions.date',
          granularity: 'year'
        }
      ],
      dimensions: ['emissions.category', 'emissions.subCategory'],
      timezone: 'UTC',
      order: [],
      filters: []
    },
    data: [
      {
        'emissions.date': '2019-10-01T00:00:00.000',
        'emissions.date.year': '2019-10-01T00:00:00.000',
        'emissions.category': 'Petrol',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 220150
      },
      {
        'emissions.date': '2019-10-01T00:00:00.000',
        'emissions.date.year': '2019-10-01T00:00:00.000',
        'emissions.category': 'Diesel',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 215000
      },
      {
        'emissions.date': '2019-10-01T00:00:00.000',
        'emissions.date.year': '2019-10-01T00:00:00.000',
        'emissions.category': 'Refrigirents (R-410)',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 182050
      },
      {
        'emissions.date': '2019-10-01T00:00:00.000',
        'emissions.date.year': '2019-10-01T00:00:00.000',
        'emissions.category': 'Purchased Electricity',
        'emissions.subCategory': 'Scope 2',
        'emissions.data': 308000
      },
      {
        'emissions.date': '2019-10-01T00:00:00.000',
        'emissions.date.year': '2019-10-01T00:00:00.000',
        'emissions.category': 'Purchased Goods',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 250000
      },
      {
        'emissions.date': '2019-10-01T00:00:00.000',
        'emissions.date.year': '2019-10-01T00:00:00.000',
        'emissions.category': 'Downstream Transport',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 285000
      },
      {
        'emissions.date': '2019-10-01T00:00:00.000',
        'emissions.date.year': '2019-10-01T00:00:00.000',
        'emissions.category': 'investments',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 145000
      },
      {
        'emissions.date': '2020-10-01T00:00:00.000',
        'emissions.date.year': '2020-10-01T00:00:00.000',
        'emissions.category': 'Petrol',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 200150
      },
      {
        'emissions.date': '2020-10-01T00:00:00.000',
        'emissions.date.year': '2020-10-01T00:00:00.000',
        'emissions.category': 'Diesel',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 235000
      },
      {
        'emissions.date': '2020-10-01T00:00:00.000',
        'emissions.date.year': '2020-10-01T00:00:00.000',
        'emissions.category': 'Refrigirents (R-410)',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 142050
      },
      {
        'emissions.date': '2020-10-01T00:00:00.000',
        'emissions.date.year': '2020-10-01T00:00:00.000',
        'emissions.category': 'Purchased Electricity',
        'emissions.subCategory': 'Scope 2',
        'emissions.data': 308000
      },
      {
        'emissions.date': '2020-10-01T00:00:00.000',
        'emissions.date.year': '2020-10-01T00:00:00.000',
        'emissions.category': 'Purchased Goods',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 190000
      },
      {
        'emissions.date': '2020-10-01T00:00:00.000',
        'emissions.date.year': '2020-10-01T00:00:00.000',
        'emissions.category': 'Downstream Transport',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 285000
      },
      {
        'emissions.date': '2020-10-01T00:00:00.000',
        'emissions.date.year': '2020-10-01T00:00:00.000',
        'emissions.category': 'investments',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 145000
      },
      {
        'emissions.date': '2020-10-01T00:00:00.000',
        'emissions.date.year': '2020-10-01T00:00:00.000',
        'emissions.category': 'Other',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 350000
      },
      {
        'emissions.date': '2021-10-01T00:00:00.000',
        'emissions.date.year': '2021-10-01T00:00:00.000',
        'emissions.category': 'Petrol',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 302150
      },
      {
        'emissions.date': '2021-10-01T00:00:00.000',
        'emissions.date.year': '2021-10-01T00:00:00.000',
        'emissions.category': 'Diesel',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 206097
      },
      {
        'emissions.date': '2021-10-01T00:00:00.000',
        'emissions.date.year': '2021-10-01T00:00:00.000',
        'emissions.category': 'Refrigirents (R-410)',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 210000
      },
      {
        'emissions.date': '2021-10-01T00:00:00.000',
        'emissions.date.year': '2021-10-01T00:00:00.000',
        'emissions.category': 'Purchased Electricity',
        'emissions.subCategory': 'Scope 2',
        'emissions.data': 408000
      },
      {
        'emissions.date': '2021-10-01T00:00:00.000',
        'emissions.date.year': '2021-10-01T00:00:00.000',
        'emissions.category': 'Purchased Goods',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 190000
      },
      {
        'emissions.date': '2021-10-01T00:00:00.000',
        'emissions.date.year': '2021-10-01T00:00:00.000',
        'emissions.category': 'Downstream Transport',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 275000
      },
      {
        'emissions.date': '2021-10-01T00:00:00.000',
        'emissions.date.year': '2021-10-01T00:00:00.000',
        'emissions.category': 'investments',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 286000
      },
      {
        'emissions.date': '2021-10-01T00:00:00.000',
        'emissions.date.year': '2021-10-01T00:00:00.000',
        'emissions.category': 'Other',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 316000
      }
    ],
    chartType: 'emissions_lineage',
    showRefData: false,
    fontSize: 12,
    categoryFontSize: 14,
    colorPalette: 'schemePaired',
    unit: 'mtCO2e',
    hideBorder: true
  },
  {
    pId: 11,
    layouts: {
      lg: '{"x":12,"y":28,"w":12,"h":20}',
      md: '{"x":12,"y":28,"w":12,"h":20}',
      sm: '{"x":6,"y":14,"w":6,"h":15}',
      xs: '{"x":0,"y":14,"w":4,"h":15}',
      xxs: '{"x":0,"y":14,"w":4,"h":15}'
    },
    title: 'Total Emissions by Events',
    description: 'By Event',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    hideTitle: true,
    data: [
      {
        'esg.kpi': 'Formula 1 British Grand Prix',
        'esg.data': 9329.0
      },
      {
        'esg.kpi': 'Monster Energy British Grand Prix Motogp',
        'esg.data': 4697
      },
      {
        'esg.kpi': 'Silverstone Festival',
        'esg.data': 4327
      },
      {
        'esg.kpi': 'Bio Gas',
        'esg.data': 500.0
      },
      {
        'esg.kpi': 'Brscc Season Opener',
        'esg.data': 3101
      },
      {
        'esg.kpi': 'Bennetts British Superbike Championship',
        'esg.data': 2576
      },
      {
        'esg.kpi': 'Vintage Sports Car Club',
        'esg.data': 2571
      },
      {
        'esg.kpi': 'Barc Britcar and Classic Touring Cars',
        'esg.data': 2497
      },
      {
        'esg.kpi': 'Msvr Club Car Championship',
        'esg.data': 2480
      },
      {
        'esg.kpi': '750 Motor Club',
        'esg.data': 2418
      },
      {
        'esg.kpi': 'Kwik Fit British Touring Car Championship',
        'esg.data': 2355
      },
      {
        'esg.kpi': 'Classic Sports Car Club',
        'esg.data': 2298
      },
      {
        'esg.kpi': 'Brscc Citroën C1 24 Hour',
        'esg.data': 2263
      },
      {
        'esg.kpi': 'Hscc International Trophy',
        'esg.data': 1880
      }
    ],
    chartType: 'progressTable',
    showRefData: false,
    color: 'info',
    unit: 'MtCO2e',
    fontSize: 12,
    showTitle: false,
    centeredTitle: 'Total Scope 1',
    hideBorder: true
  },
  {
    pId: 11,
    layouts: {
      lg: '{"x":0,"y":0,"w":6,"h":20}',
      md: '{"x":0,"y":0,"w":6,"h":20}',
      sm: '{"x":0,"y":0,"w":3,"h":15}',
      xs: '{"x":0,"y":0,"w":4,"h":15}',
      xxs: '{"x":0,"y":0,"w":4,"h":15}'
    },
    title: 'Total Emissions By Events',
    description: '',
    shortDescription: 'Total Emissions By Events (mtCO2e)',
    fontSize: 15,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [{ 'esg.kpi': 'Tonnes CO2e', 'esg.data': 304766.77 }],
    chartType: 'number',
    showRefData: false,
    hideTitle: true,
    hideBorder: true
  },
  // {
  //   pId: 11,
  //   layouts: {
  //     lg: '{"x":0,"y":22,"w":24,"h":13}',
  //     md: '{"x":0,"y":22,"w":24,"h":13}',
  //     sm: '{"x":0,"y":22,"w":12,"h":13}',
  //     xs: '{"x":0,"y":0,"w":4,"h":13}',
  //     xxs: '{"x":0,"y":0,"w":4,"h":13}'
  //   },
  //   title: 'Co2 Emissions (tons) with Aspect',
  //   description: null,
  //   unitType: 'NUMBER',
  //   query: {
  //     measures: ['esg.data'],
  //     dimensions: ['esg.emission', 'esg.franchise']
  //   },
  //   data: [
  //     {
  //       'esg.emission': 'Direct Emission',
  //       'esg.franchise': 'Franchise A',
  //       'esg.data': 20000
  //     },
  //     {
  //       'esg.emission': 'Direct Emission',
  //       'esg.franchise': 'Franchise B',
  //       'esg.data': 35000
  //     },
  //     {
  //       'esg.emission': 'Direct Emission',
  //       'esg.franchise': 'Franchise C',
  //       'esg.data': 20000
  //     },
  //     {
  //       'esg.emission': 'Direct Emission',
  //       'esg.franchise': 'Franchise D',
  //       'esg.data': 72220
  //     },
  //     {
  //       'esg.emission': 'Direct Emission',
  //       'esg.franchise': 'Franchise E',
  //       'esg.data': 53111
  //     },
  //     {
  //       'esg.emission': 'Direct Emission',
  //       'esg.franchise': 'Franchise F',
  //       'esg.data': 14222
  //     },
  //     {
  //       'esg.emission': 'Direct Emission',
  //       'esg.franchise': 'Franchise G',
  //       'esg.data': 28999
  //     },
  //     {
  //       'esg.emission': 'Other Indirect Emission',
  //       'esg.franchise': 'Franchise A',
  //       'esg.data': 230
  //     },
  //     {
  //       'esg.emission': 'Other Indirect Emission',
  //       'esg.franchise': 'Franchise B',
  //       'esg.data': 300
  //     },
  //     {
  //       'esg.emission': 'Other Indirect Emission',
  //       'esg.franchise': 'Franchise C',
  //       'esg.data': 510
  //     },
  //     {
  //       'esg.emission': 'Other Indirect Emission',
  //       'esg.franchise': 'Franchise D',
  //       'esg.data': 72220
  //     },
  //     {
  //       'esg.emission': 'Other Indirect Emission',
  //       'esg.franchise': 'Franchise E',
  //       'esg.data': 350
  //     },
  //     {
  //       'esg.emission': 'Other Indirect Emission',
  //       'esg.franchise': 'Franchise F',
  //       'esg.data': 217
  //     },
  //     {
  //       'esg.emission': 'Other Indirect Emission',
  //       'esg.franchise': 'Franchise G',
  //       'esg.data': 121
  //     }
  //   ],
  //   chartType: 'heatmap',
  //   chartColor: '#B78B02',
  //   showRefData: true,
  //   hideBorder: true
  // },
  // {
  //   pId: 11,
  //   layouts: {
  //     lg: '{"x":0,"y":8,"w":12,"h":13}',
  //     md: '{"x":0,"y":8,"w":12,"h":13}',
  //     sm: '{"x":0,"y":8,"w":6,"h":13}',
  //     xs: '{"x":0,"y":8,"w":4,"h":13}',
  //     xxs: '{"x":0,"y":8,"w":4,"h":13}'
  //   },
  //   title: 'Emissions by the scopes',
  //   description: null,
  //   unitType: 'NUMBER',
  //   query: {
  //     measures: [
  //       'esg.tonnesCO2e',
  //       'esg.tonnesCO2',
  //       'esg.tonnesCH4',
  //       'esg.tonnesNO2'
  //     ],
  //     dimensions: ['esg.kpi']
  //   },
  //   data: [
  //     {
  //       'esg.kpi': 'Scope-1',
  //       'esg.tonnesCO2e': 115837.87,
  //       'esg.tonnesCO2': 104254.09,
  //       'esg.tonnesCH4': 8108.65,
  //       'esg.tonnesNO2': 3475.14
  //     },
  //     {
  //       'esg.kpi': 'Scope-2',
  //       'esg.tonnesCO2e': 36511.17,
  //       'esg.tonnesCO2': 32860.05,
  //       'esg.tonnesCH4': 2555.78,
  //       'esg.tonnesNO2': 1096.33
  //     },
  //     {
  //       'esg.kpi': 'Scope-3',
  //       'esg.tonnesCO2e': 152417.73,
  //       'esg.tonnesCO2': 135475.28,
  //       'esg.tonnesCH4': 12009.83,
  //       'esg.tonnesNO2': 4932.64
  //     }
  //   ],
  //   chartType: 'bar',
  //   showRefData: true,
  //   hideBorder: true
  // },
  {
    pId: 11,
    layouts: {
      lg: '{"x":12,"y":14,"w":12,"h":13}',
      md: '{"x":12,"y":14,"w":12,"h":13}',
      sm: '{"x":6,"y":14,"w":6,"h":13}',
      xs: '{"x":0,"y":14,"w":4,"h":13}',
      xxs: '{"x":0,"y":14,"w":4,"h":13}'
    },
    title: 'Total electricity used (Kwh/Mwh)',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 2017,
        'esg.data': 4231
      },
      {
        'esg.kpi': 2018,
        'esg.data': 5021
      },
      {
        'esg.kpi': 2019,
        'esg.data': 4710
      },
      {
        'esg.kpi': 2020,
        'esg.data': 4089
      },
      {
        'esg.kpi': 2021,
        'esg.data': 4896
      }
    ],
    chartType: 'bar',
    showRefData: false,
    hideYAxis: false,
    hideBorder: true
  },
  {
    pId: 11,
    layouts: {
      lg: '{"x":0,"y":8,"w":12,"h":13}',
      md: '{"x":0,"y":8,"w":12,"h":13}',
      sm: '{"x":0,"y":8,"w":6,"h":13}',
      xs: '{"x":0,"y":8,"w":4,"h":13}',
      xxs: '{"x":0,"y":8,"w":4,"h":13}'
    },
    title: 'Major CO2 Contributors',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      { 'esg.kpi': 'Cocacola', 'esg.data': 69.9 },
      {
        'esg.kpi': 'Ferrari',
        'esg.data': 85.9
      },
      { 'esg.kpi': 'DHL', 'esg.data': 73.3 },
      { 'esg.kpi': 'mcLaren', 'esg.data': 31.1 },
      { 'esg.kpi': 'Dell', 'esg.data': 68.9 }
    ],
    chartType: 'pie',
    showRefData: false,
    hideBorder: true
  },
  {
    pId: 11,
    layouts: {
      lg: '{"x":0,"y":35,"w":24,"h":13}',
      md: '{"x":0,"y":35,"w":24,"h":13}',
      sm: '{"x":0,"y":35,"w":12,"h":13}',
      xs: '{"x":0,"y":35,"w":8,"h":13}',
      xxs: '{"x":0,"y":35,"w":8,"h":13}'
    },
    title: 'Details',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: [
        'esg.tonnesCO2e',
        'esg.tonnesCO2',
        'esg.tonnesCH4',
        'esg.tonnesNO2'
      ],
      dimensions: ['esg.emissions', 'esg.franchise', 'esg.aspects']
    },
    data: [
      {
        'esg.emissions': 'Indirect Emission',
        'esg.franchise': 'Cocacola',
        'esg.aspects': 'Beverage',
        'esg.tonnesCO2e': 25000,
        'esg.tonnesCO2': 22500,
        'esg.tonnesNO2': 750,
        'esg.tonnesCH4': 1750
      },
      {
        'esg.emissions': 'Other Indirect Emission',
        'esg.franchise': 'DHL',
        'esg.aspects': 'supplier van',
        'esg.tonnesCO2e': 23899,
        'esg.tonnesCO2': 21509.12,
        'esg.tonnesNO2': 716.97,
        'esg.tonnesCH4': 1672.93
      },
      {
        'esg.emissions': 'Other Indirect Emission',
        'esg.franchise': 'Dell',
        'esg.aspects': 'products',
        'esg.tonnesCO2e': 21530.65,
        'esg.tonnesCO2': 19377.59,
        'esg.tonnesNO2': 645.92,
        'esg.tonnesCH4': 1507.15
      },
      {
        'esg.emissions': 'Direct Emission',
        'esg.franchise': 'Ferrari',
        'esg.aspects': 'Cars',
        'esg.tonnesCO2e': 19713.14,
        'esg.tonnesCO2': 17741.83,
        'esg.tonnesNO2': 591.39,
        'esg.tonnesCH4': 1379.92
      },
      {
        'esg.emissions': 'Other Indirect Emission',
        'esg.franchise': 'Emirates',
        'esg.aspects': 'Air travelling',
        'esg.tonnesCO2e': 17474.76,
        'esg.tonnesCO2': 15727.28,
        'esg.tonnesNO2': 524.24,
        'esg.tonnesCH4': 1223.23
      }
    ],
    chartType: 'table',
    showTableHeader: true,
    tableRowCount: 5,
    showRefData: true,
    hideBorder: true
  },
  {
    pId: 11,
    layouts: {
      lg: '{"x":0,"y":0,"w":6,"h":10}',
      md: '{"x":0,"y":0,"w":6,"h":10}',
      sm: '{"x":0,"y":0,"w":3,"h":10}',
      xs: '{"x":0,"y":0,"w":4,"h":10}',
      xxs: '{"x":0,"y":0,"w":4,"h":10}'
    },
    title: 'Supplier Analysis',
    description: 'Suppliers who have submitted ESG data',
    shortDescription: 'Total',
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [{ 'esg.kpi': 'Tonnes C02', 'esg.data': 175 }],
    chartType: 'number',
    showRefData: false,
    hideBorder: true
  },
  {
    pId: 11,
    layouts: {
      lg: '{"x":6,"y":0,"w":6,"h":10}',
      md: '{"x":6,"y":0,"w":6,"h":10}',
      sm: '{"x":3,"y":0,"w":3,"h":10}',
      xs: '{"x":0,"y":0,"w":4,"h":10}',
      xxs: '{"x":0,"y":0,"w":4,"h":10}'
    },
    title: 'Supplier submissions',
    description: null,
    shortDescription: 'as a percentage',
    hideTitle: true,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [{ 'esg.kpi': 'Tonnes CO2e', 'esg.data': 45 }],
    chartType: 'number',
    showRefData: false,
    hideBorder: true
  },
  {
    pId: 11,
    layouts: {
      lg: '{"x":0,"y":0,"w":12,"h":10}',
      md: '{"x":0,"y":0,"w":12,"h":10}',
      sm: '{"x":0,"y":0,"w":12,"h":10}',
      xs: '{"x":0,"y":0,"w":6,"h":10}',
      xxs: '{"x":0,"y":0,"w":6,"h":10}'
    },
    title: 'Emissions By Waste',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [{ 'esg.kpi': 'Tonnes CH4', 'esg.data': 22674.27 }],
    chartType: 'number',
    showRefData: false,
    hideBorder: true
  },
  {
    pId: 11,
    layouts: {
      lg: '{"x":12,"y":0,"w":12,"h":10}',
      md: '{"x":12,"y":0,"w":12,"h":10}',
      sm: '{"x":12,"y":0,"w":12,"h":10}',
      xs: '{"x":6,"y":0,"w":6,"h":10}',
      xxs: '{"x":6,"y":0,"w":6,"h":10}'
    },
    title: 'Waste By Type',
    titleEnabled: true,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Landfill',
        'esg.data': 29
      },
      {
        'esg.kpi': 'Electricity',
        'esg.data': 25
      },
      {
        'esg.kpi': 'Electronic',
        'esg.data': 27
      },
      {
        'esg.kpi': 'Tech equipment',
        'esg.data': 29
      },
      {
        'esg.kpi': 'TVs, monitors and screens.',
        'esg.data': 29
      }
    ],
    paddingOverrides: { left: 30, right: 30, top: 20, bottom: 20 },
    chartType: 'donut',
    chartHeight: 700,
    showRefData: false,
    titleEnabled: true,
    showLegend: false,
    hideBorder: true
  },
  {
    pId: 10,
    layouts: {
      lg: '{"x":0,"y":8,"w":24,"h":18}',
      md: '{"x":0,"y":8,"w":24,"h":18}',
      sm: '{"x":0,"y":8,"w":12,"h":14}',
      xs: '{"x":0,"y":8,"w":8,"h":13}',
      xxs: '{"x":0,"y":8,"w":8,"h":13}'
    },
    title: 'Emissions Lineage',
    description: '<p>Some description.</p>',
    unitType: 'NUMBER',
    query: {
      measures: ['emissions.data'],
      timeDimensions: [
        {
          dimension: 'emissions.date',
          granularity: 'year'
        }
      ],
      dimensions: ['emissions.category', 'emissions.subCategory'],
      timezone: 'UTC',
      order: [],
      filters: []
    },
    data: [
      {
        'emissions.date': '2019-10-01T00:00:00.000',
        'emissions.date.year': '2019-10-01T00:00:00.000',
        'emissions.category': 'Petrol',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 220150
      },
      {
        'emissions.date': '2019-10-01T00:00:00.000',
        'emissions.date.year': '2019-10-01T00:00:00.000',
        'emissions.category': 'Diesel',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 215000
      },
      {
        'emissions.date': '2019-10-01T00:00:00.000',
        'emissions.date.year': '2019-10-01T00:00:00.000',
        'emissions.category': 'Refrigirents (R-410)',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 182050
      },
      {
        'emissions.date': '2019-10-01T00:00:00.000',
        'emissions.date.year': '2019-10-01T00:00:00.000',
        'emissions.category': 'Purchased Electricity',
        'emissions.subCategory': 'Scope 2',
        'emissions.data': 308000
      },
      {
        'emissions.date': '2019-10-01T00:00:00.000',
        'emissions.date.year': '2019-10-01T00:00:00.000',
        'emissions.category': 'Purchased Goods',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 250000
      },
      {
        'emissions.date': '2019-10-01T00:00:00.000',
        'emissions.date.year': '2019-10-01T00:00:00.000',
        'emissions.category': 'Downstream Transport',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 285000
      },
      {
        'emissions.date': '2019-10-01T00:00:00.000',
        'emissions.date.year': '2019-10-01T00:00:00.000',
        'emissions.category': 'investments',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 145000
      },
      {
        'emissions.date': '2020-10-01T00:00:00.000',
        'emissions.date.year': '2020-10-01T00:00:00.000',
        'emissions.category': 'Petrol',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 200150
      },
      {
        'emissions.date': '2020-10-01T00:00:00.000',
        'emissions.date.year': '2020-10-01T00:00:00.000',
        'emissions.category': 'Diesel',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 235000
      },
      {
        'emissions.date': '2020-10-01T00:00:00.000',
        'emissions.date.year': '2020-10-01T00:00:00.000',
        'emissions.category': 'Refrigirents (R-410)',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 142050
      },
      {
        'emissions.date': '2020-10-01T00:00:00.000',
        'emissions.date.year': '2020-10-01T00:00:00.000',
        'emissions.category': 'Purchased Electricity',
        'emissions.subCategory': 'Scope 2',
        'emissions.data': 308000
      },
      {
        'emissions.date': '2020-10-01T00:00:00.000',
        'emissions.date.year': '2020-10-01T00:00:00.000',
        'emissions.category': 'Purchased Goods',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 190000
      },
      {
        'emissions.date': '2020-10-01T00:00:00.000',
        'emissions.date.year': '2020-10-01T00:00:00.000',
        'emissions.category': 'Downstream Transport',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 285000
      },
      {
        'emissions.date': '2020-10-01T00:00:00.000',
        'emissions.date.year': '2020-10-01T00:00:00.000',
        'emissions.category': 'investments',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 145000
      },
      {
        'emissions.date': '2020-10-01T00:00:00.000',
        'emissions.date.year': '2020-10-01T00:00:00.000',
        'emissions.category': 'Other',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 350000
      },
      {
        'emissions.date': '2021-10-01T00:00:00.000',
        'emissions.date.year': '2021-10-01T00:00:00.000',
        'emissions.category': 'Petrol',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 302150
      },
      {
        'emissions.date': '2021-10-01T00:00:00.000',
        'emissions.date.year': '2021-10-01T00:00:00.000',
        'emissions.category': 'Diesel',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 206097
      },
      {
        'emissions.date': '2021-10-01T00:00:00.000',
        'emissions.date.year': '2021-10-01T00:00:00.000',
        'emissions.category': 'Refrigirents (R-410)',
        'emissions.subCategory': 'Scope 1',
        'emissions.data': 210000
      },
      {
        'emissions.date': '2021-10-01T00:00:00.000',
        'emissions.date.year': '2021-10-01T00:00:00.000',
        'emissions.category': 'Purchased Electricity',
        'emissions.subCategory': 'Scope 2',
        'emissions.data': 408000
      },
      {
        'emissions.date': '2021-10-01T00:00:00.000',
        'emissions.date.year': '2021-10-01T00:00:00.000',
        'emissions.category': 'Purchased Goods',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 190000
      },
      {
        'emissions.date': '2021-10-01T00:00:00.000',
        'emissions.date.year': '2021-10-01T00:00:00.000',
        'emissions.category': 'Downstream Transport',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 275000
      },
      {
        'emissions.date': '2021-10-01T00:00:00.000',
        'emissions.date.year': '2021-10-01T00:00:00.000',
        'emissions.category': 'investments',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 286000
      },
      {
        'emissions.date': '2021-10-01T00:00:00.000',
        'emissions.date.year': '2021-10-01T00:00:00.000',
        'emissions.category': 'Other',
        'emissions.subCategory': 'Scope 3',
        'emissions.data': 316000
      }
      // {
      //   'esg.indicator': 'Purchased electricity - Location based',
      //   'esg.subCategory': 'scope 2',
      //   'esg.date.year': '2019-01-01T00:00:00.000',
      //   'esg.date': '2019-01-01T00:00:00.000',
      //   'esg.total': '247'
      // }
      // {
      //   'esg.indicator': 'Purchased electricity - Location based',
      //   'esg.subCategory': 'scope 2',
      //   'esg.date.year': '2020-01-01T00:00:00.000',
      //   'esg.date': '2020-01-01T00:00:00.000',
      //   'esg.total': '799'
      // },
      // {
      //   'esg.indicator': 'Purchased electricity - Location based',
      //   'esg.subCategory': 'scope 2',
      //   'esg.date.year': '2021-01-01T00:00:00.000',
      //   'esg.date': '2021-01-01T00:00:00.000',
      //   'esg.total': '327'
      // },
      // {
      //   'esg.indicator': 'Refrigerant (R-410A)',
      //   'esg.subCategory': 'scope 1',
      //   'esg.date.year': '2021-01-01T00:00:00.000',
      //   'esg.date': '2021-01-01T00:00:00.000',
      //   'esg.total': '191.3'
      // },
      // {
      //   'esg.indicator': 'Refrigerant (R-410A)',
      //   'esg.subCategory': 'scope 1',
      //   'esg.date.year': '2019-01-01T00:00:00.000',
      //   'esg.date': '2019-01-01T00:00:00.000',
      //   'esg.total': '144.7'
      // },
      // {
      //   'esg.indicator': 'Natural Gas',
      //   'esg.subCategory': 'scope 1',
      //   'esg.date.year': '2021-01-01T00:00:00.000',
      //   'esg.date': '2021-01-01T00:00:00.000',
      //   'esg.total': '120.3'
      // },
      // {
      //   'esg.indicator': 'Natural Gas',
      //   'esg.subCategory': 'scope 1',
      //   'esg.date.year': '2019-01-01T00:00:00.000',
      //   'esg.date': '2019-01-01T00:00:00.000',
      //   'esg.total': '85.5'
      // },
      // {
      //   'esg.indicator': 'Motor Gasoline (Petrol)',
      //   'esg.subCategory': 'scope 1',
      //   'esg.date.year': '2019-01-01T00:00:00.000',
      //   'esg.date': '2019-01-01T00:00:00.000',
      //   'esg.total': '81.6'
      // },
      // {
      //   'esg.indicator': 'Natural Gas',
      //   'esg.subCategory': 'scope 1',
      //   'esg.date.year': '2020-01-01T00:00:00.000',
      //   'esg.date': '2020-01-01T00:00:00.000',
      //   'esg.total': '48.4'
      // },
      // {
      //   'esg.indicator': 'Motor Gasoline (Petrol)',
      //   'esg.subCategory': 'scope 1',
      //   'esg.date.year': '2020-01-01T00:00:00.000',
      //   'esg.date': '2020-01-01T00:00:00.000',
      //   'esg.total': '12.5'
      // },
      // {
      //   'esg.indicator': 'Motor Gasoline (Petrol)',
      //   'esg.subCategory': 'scope 1',
      //   'esg.date.year': '2021-01-01T00:00:00.000',
      //   'esg.date': '2021-01-01T00:00:00.000',
      //   'esg.total': '12.1'
      // },
      // {
      //   'esg.indicator': 'Refrigerant (R-410A)',
      //   'esg.subCategory': 'scope 1',
      //   'esg.date.year': '2020-01-01T00:00:00.000',
      //   'esg.date': '2020-01-01T00:00:00.000',
      //   'esg.total': '5.9'
      // }
    ],
    chartType: 'emissions_lineage',
    showRefData: false,
    fontSize: 12,
    categoryFontSize: 14,
    colorPalette: 'schemePaired',
    unit: 'mtCO2e'
  },
  {
    pId: 10,
    layouts: {
      lg: '{"x":0,"y":22,"w":24,"h":13}',
      md: '{"x":0,"y":22,"w":24,"h":13}',
      sm: '{"x":0,"y":22,"w":12,"h":13}',
      xs: '{"x":0,"y":0,"w":4,"h":13}',
      xxs: '{"x":0,"y":0,"w":4,"h":13}'
    },
    title: 'Co2 Emissions (tons) with Aspect',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.emission', 'esg.franchise']
    },
    data: [
      {
        'esg.emission': 'Direct Emission',
        'esg.franchise': 'Franchise A',
        'esg.data': 20000
      },
      {
        'esg.emission': 'Direct Emission',
        'esg.franchise': 'Franchise B',
        'esg.data': 35000
      },
      {
        'esg.emission': 'Direct Emission',
        'esg.franchise': 'Franchise C',
        'esg.data': 20000
      },
      {
        'esg.emission': 'Direct Emission',
        'esg.franchise': 'Franchise D',
        'esg.data': 72220
      },
      {
        'esg.emission': 'Direct Emission',
        'esg.franchise': 'Franchise E',
        'esg.data': 53111
      },
      {
        'esg.emission': 'Direct Emission',
        'esg.franchise': 'Franchise F',
        'esg.data': 14222
      },
      {
        'esg.emission': 'Direct Emission',
        'esg.franchise': 'Franchise G',
        'esg.data': 28999
      },
      {
        'esg.emission': 'Other Indirect Emission',
        'esg.franchise': 'Franchise A',
        'esg.data': 230
      },
      {
        'esg.emission': 'Other Indirect Emission',
        'esg.franchise': 'Franchise B',
        'esg.data': 300
      },
      {
        'esg.emission': 'Other Indirect Emission',
        'esg.franchise': 'Franchise C',
        'esg.data': 510
      },
      {
        'esg.emission': 'Other Indirect Emission',
        'esg.franchise': 'Franchise D',
        'esg.data': 72220
      },
      {
        'esg.emission': 'Other Indirect Emission',
        'esg.franchise': 'Franchise E',
        'esg.data': 350
      },
      {
        'esg.emission': 'Other Indirect Emission',
        'esg.franchise': 'Franchise F',
        'esg.data': 217
      },
      {
        'esg.emission': 'Other Indirect Emission',
        'esg.franchise': 'Franchise G',
        'esg.data': 121
      }
    ],
    chartType: 'heatmap',
    chartColor: '#B78B02',
    showRefData: true
  },
  {
    pId: 10,
    layouts: {
      lg: '{"x":0,"y":8,"w":12,"h":13}',
      md: '{"x":0,"y":8,"w":12,"h":13}',
      sm: '{"x":0,"y":8,"w":6,"h":13}',
      xs: '{"x":0,"y":8,"w":4,"h":13}',
      xxs: '{"x":0,"y":8,"w":4,"h":13}'
    },
    title: 'Emissions by the scopes',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: [
        'esg.tonnesCO2e',
        'esg.tonnesCO2',
        'esg.tonnesCH4',
        'esg.tonnesNO2'
      ],
      dimensions: ['esg.kpi']
    },
    data: [
      {
        'esg.kpi': 'Scope-1',
        'esg.tonnesCO2e': 115837.87,
        'esg.tonnesCO2': 104254.09,
        'esg.tonnesCH4': 8108.65,
        'esg.tonnesNO2': 3475.14
      },
      {
        'esg.kpi': 'Scope-2',
        'esg.tonnesCO2e': 36511.17,
        'esg.tonnesCO2': 32860.05,
        'esg.tonnesCH4': 2555.78,
        'esg.tonnesNO2': 1096.33
      },
      {
        'esg.kpi': 'Scope-3',
        'esg.tonnesCO2e': 152417.73,
        'esg.tonnesCO2': 135475.28,
        'esg.tonnesCH4': 12009.83,
        'esg.tonnesNO2': 4932.64
      }
    ],
    chartType: 'bar',
    showRefData: true
  },
  {
    pId: 10,
    layouts: {
      lg: '{"x":12,"y":8,"w":12,"h":13}',
      md: '{"x":12,"y":8,"w":12,"h":13}',
      sm: '{"x":6,"y":8,"w":6,"h":13}',
      xs: '{"x":0,"y":8,"w":4,"h":13}',
      xxs: '{"x":0,"y":8,"w":4,"h":13}'
    },
    title: 'Emission factors',
    description: null,
    unitType: 'PERCENT',
    query: {
      measures: ['esg.data'],
      dimensions: ['esg.kpi']
    },
    data: [
      { 'esg.kpi': 'Business travelling', 'esg.data': 6.9 },
      {
        'esg.kpi': 'Downstream transportation and distribution',
        'esg.data': 8.9
      },
      { 'esg.kpi': 'Employee commuting', 'esg.data': 7.3 },
      { 'esg.kpi': 'End-of-Life Treatment of Sold Products', 'esg.data': 3.1 },
      { 'esg.kpi': 'Fugitive Emissions', 'esg.data': 6.9 },
      { 'esg.kpi': 'Mobile Combustion', 'esg.data': 12.9 },
      { 'esg.kpi': 'Processing of sold products', 'esg.data': 7.4 },
      { 'esg.kpi': 'Purchased Energy', 'esg.data': 12 },
      { 'esg.kpi': 'Purchased goods & services', 'esg.data': 4.5 },
      { 'esg.kpi': 'Others', 'esg.data': 30.1 }
    ],
    chartType: 'pie',
    showRefData: false
  },
  {
    pId: 10,
    layouts: {
      lg: '{"x":0,"y":35,"w":24,"h":13}',
      md: '{"x":0,"y":35,"w":24,"h":13}',
      sm: '{"x":0,"y":35,"w":12,"h":13}',
      xs: '{"x":0,"y":35,"w":8,"h":13}',
      xxs: '{"x":0,"y":35,"w":8,"h":13}'
    },
    title: 'Details',
    description: null,
    unitType: 'NUMBER',
    query: {
      measures: [
        'esg.tonnesCO2e',
        'esg.tonnesCO2',
        'esg.tonnesCH4',
        'esg.tonnesNO2'
      ],
      dimensions: ['esg.emissions', 'esg.franchise', 'esg.aspects']
    },
    data: [
      {
        'esg.emissions': 'Indirect Emission',
        'esg.franchise': 'Franchise A',
        'esg.aspects': 'Electricity',
        'esg.tonnesCO2e': 25000,
        'esg.tonnesCO2': 22500,
        'esg.tonnesNO2': 750,
        'esg.tonnesCH4': 1750
      },
      {
        'esg.emissions': 'Other Indirect Emission',
        'esg.franchise': 'Franchise B',
        'esg.aspects': 'supplier van',
        'esg.tonnesCO2e': 23899,
        'esg.tonnesCO2': 21509.12,
        'esg.tonnesNO2': 716.97,
        'esg.tonnesCH4': 1672.93
      },
      {
        'esg.emissions': 'Other Indirect Emission',
        'esg.franchise': 'Franchise B',
        'esg.aspects': 'products',
        'esg.tonnesCO2e': 21530.65,
        'esg.tonnesCO2': 19377.59,
        'esg.tonnesNO2': 645.92,
        'esg.tonnesCH4': 1507.15
      },
      {
        'esg.emissions': 'Direct Emission',
        'esg.franchise': 'Franchise B',
        'esg.aspects': 'Refrigerants',
        'esg.tonnesCO2e': 19713.14,
        'esg.tonnesCO2': 17741.83,
        'esg.tonnesNO2': 591.39,
        'esg.tonnesCH4': 1379.92
      },
      {
        'esg.emissions': 'Other Indirect Emission',
        'esg.franchise': 'Franchise B',
        'esg.aspects': 'Cars',
        'esg.tonnesCO2e': 19396.98,
        'esg.tonnesCO2': 17457.29,
        'esg.tonnesNO2': 581.91,
        'esg.tonnesCH4': 1357.79
      },
      {
        'esg.emissions': 'Other Indirect Emission',
        'esg.franchise': 'Franchise B',
        'esg.aspects': 'Air travelling',
        'esg.tonnesCO2e': 17474.76,
        'esg.tonnesCO2': 15727.28,
        'esg.tonnesNO2': 524.24,
        'esg.tonnesCH4': 1223.23
      }
    ],
    chartType: 'table',
    showTableHeader: true,
    tableRowCount: 5,
    showRefData: true
  }
];

const ADMIN_PORTAL_PERMISSION_GROUPS = [
  {
    id: `fc68bad5-d430-4033-b8f8-4bc069dc0ba1-${1}`,
    name: 'Report_Admins'
  },
  {
    id: `fc68bad5-d430-4033-b8f8-4bc069dc0ba2-${2}`,
    name: 'Basic_Users'
  },
  {
    id: `fc68bad5-d430-4033-b8f8-4bc069dc0ba3-${3}`,
    name: 'ESG_Managers'
  },
  {
    id: `fc68bad5-d430-4033-b8f8-4bc069dc0ba3-${4}`,
    name: 'Supplier_Managers'
  }
];

const SUMMARY_CHARTS = [
  {
    title: 'Environment',
    descriptionEnabled: true,
    description: 'Short description about row.',
    link: '/insights/reports/bookmarks/2?view=Environment%20-%20Energy',
    linkText: 'View Full Report',
    chartData: {
      props: { chartDescriptionEnabled: true },
      values: [
        {
          id: 6,
          title: 'Reduction in CO2',
          shortDescription: 'MtCO2e',
          unitType: 'PERCENT',
          query: {
            measures: ['esg.data'],
            dimensions: ['esg.kpi']
          },
          data: [
            {
              'esg.kpi': 2017,
              'esg.data': 82.98
            }
          ],
          chartType: 'percentageRadial',
          radialSize: 60,
          // chartHeight: 280,
          fontSize: 10,
          showRefData: false,
          titleEnabled: true,
          valueFontSize: 26,
          // paddingOverrides: { left: 30, right: 30, top: 20, bottom: 20 },
          colorPalette: 'palette4'
        },
        {
          title: 'CO2 Emissions by Department',
          description:
            'A total of ${Math.round(total())} A total of ${Math.round(total())} A total of ${Math.round(total())} A total of ${Math.round(total())} A total of ${Math.round(total())} A total of ${Math.round(total())} A total of ${Math.round(total())} A total of ${Math.round(total())}',
          unitType: 'PERCENT',
          query: {
            measures: ['esg.data', 'esg.subCategory'],
            dimensions: ['esg.category']
          },
          data: [
            {
              'esg.category': 'Marketing',
              'esg.data': 43.8,
              'esg.subCategory': 45
            },
            {
              'esg.category': 'IT',
              'esg.data': 36.8,
              'esg.subCategory': 40
            },
            {
              'esg.category': 'Engineering',
              'esg.data': 33.6,
              'esg.subCategory': 35
            }
          ],
          chartType: 'bar',
          showRefData: true,
          stacked: true,
          hideXAxis: true,
          hideYAxis: true,
          showLegend: false,
          titleEnabled: true,
          showDataLabels: true,
          tooltipsEnabled: true,
          paddingOverrides: { top: 30, bottom: 30 },
          colorPalette: 'palette4',
          barWidth: 65,
          fontSize: 14
        },

        {
          Id: 2,
          title: 'Total electricity used',
          unitType: 'NUMBER',
          query: {
            measures: ['esg.data'],
            dimensions: ['esg.kpi']
          },
          data: [
            {
              'esg.kpi': 2017,
              'esg.data': 4231
            },
            {
              'esg.kpi': 2018,
              'esg.data': 5021
            },
            {
              'esg.kpi': 2019,
              'esg.data': 4710
            },
            {
              'esg.kpi': 2020,
              'esg.data': 4089
            },
            {
              'esg.kpi': 2021,
              'esg.data': 4896
            }
          ],
          chartType: 'bar',
          showRefData: true,
          stacked: true,
          hideXAxis: true,
          hideYAxis: true,
          showLegend: false,
          titleEnabled: true,
          showDataLabels: true,
          tooltipsEnabled: false,
          // paddingOverrides: { left: 40, right: 40, top: 30, bottom: 30 },
          paddingOverrides: { top: 30, bottom: 30 },
          colorPalette: 'palette4',
          barWidth: 75,
          fontSize: 12
        }
      ]
    }
  },
  {
    title: 'Social',
    description: `<div> A common definition of employee turnover is the loss of talent in the workforce over time. This includes any employee departure, including resignations, layoffs, terminations, retirements, location transfers, or even deaths.Businesses often calculate their rate of employee turnover as a means of predicting the impact on productivity, customer service, or even morale. The U.S. Bureau of Labor Statistics (BLS) also calculates employee turnover at a national level. </div>`,
    descriptionEnabled: false,

    link: '/insights/reports/bookmarks/9?view=Social%20-%20Diversity',
    linkText: 'View Full Report',

    chartData: {
      props: { chartDescriptionEnabled: true },
      values: [
        {
          Id: 2,
          title: 'Total Number Of Employees',
          titleEnabled: true,
          unitType: 'NUMBER',
          query: {
            measures: ['esg.data'],
            dimensions: ['esg.kpi']
          },
          data: [{ 'esg.kpi': 'Total Employees', 'esg.data': 4500 }],
          chartType: 'number',
          chartHeight: 150,
          showRefData: false,
          shortDescription: 'Total'
        },
        {
          id: 6,
          title: 'Age Diversity',
          titleEnabled: true,
          unitType: 'PERCENT',
          query: {
            measures: ['esg.data'],
            dimensions: ['esg.kpi']
          },
          data: [
            { 'esg.kpi': '18-25 years', 'esg.data': 28.6 },
            { 'esg.kpi': '25-30 years', 'esg.data': 14.3 },
            { 'esg.kpi': '30-40 years', 'esg.data': 21.4 },
            { 'esg.kpi': '40-50 years', 'esg.data': 21.4 },
            { 'esg.kpi': '50+ years', 'esg.data': 14.3 }
          ],
          chartType: 'pie',
          chartHeight: 220,
          showRefData: false,
          showLegend: false,
          monoChromeColors: true,
          titleEnabled: true
        },
        {
          Id: 5,
          title: 'Employees by Role',
          // description:
          //   'A common definition of employee turnover is the loss of talent in the workforce over time. This includes any employee departure, including resignations, layoffs, terminations, retirements, location transfers, or even deaths.Businesses often calculate their rate of employee turnover as a means of predicting the impact on productivity, customer service, or even morale. The U.S. Bureau of Labor Statistics (BLS) also calculates employee turnover at a national level.',
          titleEnabled: true,
          unitType: 'NUMBER',
          query: {
            measures: ['esg.male', 'esg.female'],
            dimensions: ['esg.kpi']
          },
          data: [
            {
              'esg.kpi': 'Engineering',
              'esg.male': 122,
              'esg.female': 322
            },
            {
              'esg.kpi': 'Warehouse',
              'esg.male': 543,
              'esg.female': 123
            },
            {
              'esg.kpi': 'Accounting',
              'esg.male': 543,
              'esg.female': 123
            },
            {
              'esg.kpi': 'Human Resource',
              'esg.male': 764,
              'esg.female': 423
            },
            {
              'esg.kpi': 'Management',
              'esg.male': 765,
              'esg.female': 343
            }
          ],
          chartType: 'bar',
          showRefData: false,
          titleEnabled: true,
          tooltipsEnabled: false,
          paddingOverrides: { left: 40, right: 40, top: 0, bottom: 0 },
          colorPalette: 'palette2',
          barWidth: 85,
          fontSize: 12,
          hideXAxis: false,
          hideYAxis: true,
          showDataLabels: true,
          skewLabels: true
        }
      ]
    }
  },
  {
    title: 'Governance',
    description: `<div> A common definition of employee turnover is the loss of talent in the workforce over time. This includes any employee departure, including resignations, layoffs, terminations, retirements, location transfers, or even deaths.Businesses often calculate their rate of employee turnover as a means of predicting the impact on productivity, customer service, or even morale. The U.S. Bureau of Labor Statistics (BLS) also calculates employee turnover at a national level. </div>`,
    descriptionEnabled: false,

    link: '/insights/reports/bookmarks/8?view=Governance',
    linkText: 'View Full Report',
    chartData: {
      props: {},
      values: [
        {
          Id: 2,
          title: 'Questionnaire Participation',
          titleEnabled: true,
          unitType: 'NUMBER',
          query: {
            measures: ['questionnaires.participation'],
            dimensions: ['questionnaires.subSection']
          },
          data: [
            {
              'questionnaires.subSection': 'career wellbeing',
              'questionnaires.participation': '112'
            },
            {
              'questionnaires.subSection': 'leadership and culture',
              'questionnaires.participation': '64'
            },
            {
              'questionnaires.subSection': 'mental demands',
              'questionnaires.participation': '63'
            },
            {
              'questionnaires.subSection': 'physical demands',
              'questionnaires.participation': '42'
            },
            {
              'questionnaires.subSection': 'time demands',
              'questionnaires.participation': '35'
            },
            {
              'questionnaires.subSection': 'output demands',
              'questionnaires.participation': '35'
            },
            {
              'questionnaires.subSection': 'physical and emotional wellbeing',
              'questionnaires.participation': '32'
            },
            {
              'questionnaires.subSection': 'environment and ways of working',
              'questionnaires.participation': '24'
            },
            {
              'questionnaires.subSection': 'rewards and benefits',
              'questionnaires.participation': '8'
            },
            {
              'questionnaires.subSection': 'productivity loss',
              'questionnaires.participation': '3'
            }
          ],
          chartType: 'pie',
          chartHeight: 220,
          showRefData: false,
          titleEnabled: true,
          showLegend: false
        },
        {
          Id: 5,
          title: 'Manufacturing (%)',
          titleEnabled: true,
          unitType: 'PERCENT',
          query: {
            measures: ['esg.data'],
            dimensions: ['esg.kpi']
          },
          data: [
            {
              'esg.kpi': 2017,
              'esg.data': 29
            },
            {
              'esg.kpi': 2018,
              'esg.data': 25
            },
            {
              'esg.kpi': 2019,
              'esg.data': 27
            },
            {
              'esg.kpi': 2020,
              'esg.data': 29
            },
            {
              'esg.kpi': 2021,
              'esg.data': 29
            }
          ],
          chartType: 'donut',
          chartHeight: 400,
          showRefData: false,
          titleEnabled: true,
          showLegend: false
        }
        // {
        //   id: 6,
        //   // title: 'Reduction in Scope 1 Emissions in 2021',
        //   titleEnabled: false,
        //   unitType: 'NUMBER',
        //   query: {
        //     measures: ['esg.data', 'esg.refData'],
        //     dimensions: ['esg.kpi']
        //   },
        //   data: [
        //     {
        //       'esg.kpi': 2017,
        //       'esg.data': 63,
        //       'esg.refData': 200
        //     }
        //   ],
        //   chartType: 'percentageRadial',
        //   radialSize: 65,
        //   chartHeight: 250,
        //   fontSize: 12,
        //   valueFontSize: 28,
        //   showRefData: false
        // }

        // {
        //   Id: 2,
        //   title: 'Products Revenue',
        //   titleEnabled: false,
        //   unitType: 'PERCENT',
        //   query: {
        //     measures: ['esg.data'],
        //     dimensions: ['esg.kpi']
        //   },
        //   data: [
        //     {
        //       'esg.kpi': 2017,
        //       'esg.data': 0.82
        //     }
        //   ],
        //   chartType: 'percentageRadial',
        //   chartHeight: 240,
        //   showRefData: false,
        //   titleEnabled: false
        // }
      ]
    }
  }
];

const userPermissionGroups = {
  data: [...ADMIN_PORTAL_PERMISSION_GROUPS]
};

let perspectives = {
  data: { ...PERSPECTIVES }
};

let components = {
  data: [...COMPONENTS]
};

let summaryCharts = {
  data: [...SUMMARY_CHARTS]
};

//------------------------------------------------------------------------------------------

mock.onGet('/api/insights-views/summary').reply(async (config) => {
  try {
    return [200, { ...summaryCharts }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onGet('/api/insights-views').reply(async (config) => {
  await fakeRequest(1000);
  try {
    return [200, { ...perspectives }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

//------------------------------------------------------------------------------------------

mock.onGet(/^\/api\/insights-views\/[^\/]+/).reply(async (request) => {
  await fakeRequest(1000);
  try {
    const perspectiveId = Number(request.url.split('/').slice(-1)[0]);
    const perspective = perspectives.data.views.find(
      (p) => p.viewId == perspectiveId
    );
    const childComponents = components.data.filter(
      (_component) => _component.pId == perspectiveId
    );
    return [
      200,
      {
        data: {
          ...perspective,
          viewCharts: childComponents ? childComponents : []
        }
      }
    ];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

//------------------------------------------------------------------------------------------

mock.onPost('/api/insights-views').reply(async (request) => {
  await fakeRequest(1000);
  try {
    const { viewTitle, viewDescription, userGroups } = JSON.parse(request.data);
    const perspective = {
      viewId: perspectives.data.length + 1,
      viewTitle,
      viewDescription,
      userGroups
    };

    perspectives.data.views = [...perspectives.data, perspective];
    return [200, { perspective: perspective }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

//------------------------------------------------------------------------------------------

mock.onDelete(/^\/api\/insights-views\/[^\/]+/).reply(async (request) => {
  await fakeRequest(1000);
  try {
    const Id = Number(request.url.split('/').slice(-1)[0]);
    let viewId = parseInt(Id);
    perspectives.data.views = reject(perspectives.data, {
      viewId: viewId
    });
    return [200, { viewId: viewId }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock
  .onPut(/^\/api\/insights-views\/bookmark\/[^\/]+/)
  .reply(async (request) => {
    await fakeRequest(1000);
    return [200, { data: [] }];
  });

mock.onPut(/^\/api\/insights-views\/pin\/[^\/]+/).reply(async (request) => {
  await fakeRequest(1000);
  return [200, { data: [] }];
});

mock.onPut(/^\/api\/insights-views\/[^\/]+/).reply(async (request) => {
  await fakeRequest(1000);
  return [200, { data: [] }];
});

//------------------------------------------------------------------------------------------

mock.onGet('/api/suggest').reply(async (config) => {
  await fakeRequest(1000);
  try {
    return [200, { data: SEARCH_RESPONSE }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onGet('/api/user/groups').reply(async (config) => {
  try {
    return [200, { ...userPermissionGroups }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});
