import faker from 'faker';
import { map, assign, reject } from 'lodash';
// utils
import mock from '../utils/mock';
import { mockImgCover, mockImgFeed } from '../utils/mockImages';
import { sample } from 'lodash';
import fakeRequest from '../utils/fakeRequest';

const TYPES = ['VIDEO', 'TEXT'];

const LINK = [
  [{ type: 'interest-group', value: 'Physical and emotional wellbeing' }],
  [{ type: 'interest-group', value: 'Financial wellbeing' }],
  [{ type: 'interest-group', value: 'Environment and ways of working' }],
  [{ type: 'interest-group', value: 'Technology' }],
  [{ type: 'interest-group', value: 'Leadership and culture' }],
  [],
  [],
  []
];

let news = [...Array(10)].map((news, index) => {
  const setIndex = index + 1;
  let type = sample(TYPES);
  return {
    id: faker.datatype.number(),
    imageUrl: mockImgCover(setIndex),
    title: faker.name.title(),
    shortDescription: faker.lorem.sentences(2),
    date: faker.date.past(),
    description: `<p>${faker.lorem.paragraph(50)}</p>`,
    type: type,
    videoUrl:
      type === 'VIDEO'
        ? 'https://media.experienz.co.uk/mitrainnovation/digital_content/23_image_8b86c0c7-86de-47d2-be40-be2bdca2bff0.mp4'
        : '',
    link: sample(LINK)
  };
});

//-----------------------------------------------------------------

mock.onGet('/api/news').reply(async () => {
  await fakeRequest(1000);
  return [200, { data: [...news] }];
});

mock.onPost('/api/news').reply(async (request) => {
  await fakeRequest(1000);
  try {
    const {
      type,
      title,
      shortDescription,
      description,
      image,
      mediaContentType,
      date,
      link
    } = JSON.parse(request.data);
    const newNews = {
      id: faker.datatype.number(),
      type,
      title,
      shortDescription,
      description,
      mediaContentType,
      link,
      image,
      date: faker.date.recent()
    };
    news = [...news, newNews];
    return [
      200,
      {
        data: {
          id: newNews.id,
          signedUrl:
            'https://66170bfe-fa85-4266-b792-8ff7c09c2aec.mock.pstmn.io/api/digital-content/media'
        }
      }
    ];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onPut(/^\/api\/news\/[^\/]+/).reply(async (request) => {
  await fakeRequest(1000);
  try {
    const newsId = Number(request.url.split('/').slice(-1)[0]);
    const { updatedNews } = JSON.parse(request.data);
    let singleNews = null;
    news = map(news, (_newsItem) => {
      if (_newsItem.id === newsId) {
        assign(_newsItem, { ...updatedNews });
        singleNews = _newsItem;
      }
      return _newsItem;
    });
    return [
      200,
      {
        data: {
          id: singleNews.id,
          signedUrl:
            'https://66170bfe-fa85-4266-b792-8ff7c09c2aec.mock.pstmn.io/api/digital-content/media'
        }
      }
    ];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onGet(/^\/api\/news\/[^\/]+/).reply(async (request) => {
  await fakeRequest(1000);
  try {
    const newsId = Number(request.url.split('/').slice(-1)[0]);
    const editNews = news.find((_newsItem) => _newsItem.id === newsId);
    if (!editNews) {
      return [404, { ...{ data: { message: 'news not found' } } }];
    }
    return [200, { ...{ data: editNews } }];
  } catch (error) {
    return [500, { message: 'Internal server error' }];
  }
});

mock.onDelete(/^\/api\/news\/[^\/]+/).reply(async (request) => {
  await fakeRequest(1000);
  try {
    const newsId = Number(request.url.split('/').slice(-1)[0]);
    news = reject(news, { id: newsId });
    return [200, { newsId }];
  } catch (error) {
    return [500, { message: 'Internal server error' }];
  }
});

mock.onPost(/^\/api\/news\/[^\/]+\/video/).reply(async (request) => {
  return [200, { status: 'success' }];
});
