// routes
import { PATH_DASHBOARD, PATH_PAGE, PATH_AUTH } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/shared/SvgIconStyle';

import {
  ROLE_PULSE,
  ROLE_PRODUCTIVITY,
  ROLE_ORGANIZATIONAL_HEALTH,
  ROLE_FINANCIAL_OUTCOMES,
  ROLE_APPLICATION_USAGE,
  ROLE_INTEREST_GROUPS,
  ROLE_CONTENT,
  ROLE_COMMUNICATION,
  ROLE_SURVEYS,
  ROLE_SETTINGS,
  ROLE_USERS,
  ROLE_REPORT_READ,
  ROLE_INTEGRATED_REPORTS_READ,
  ROLE_MANAGE_SUPPLIERS,
  ROLE_MANAGE_EVENTS,
  ROLE_INSIGHTS_SUPPLIER,
  ROLE_DATA_SETS_READ,
  ROLE_ENVIRONMENT_VIEW,
  ROLE_SOCIAL_VIEW,
  ROLE_GOVERNANCE_VIEW,
  ROLE_SUPPLIER_VIEW,
  ROLE_MANAGE_BETA,
  ROLE_DASHBOARD_VIEW,
  ROLE_MARKETPLACE_VIEW
} from '../../utils/constants';
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

const getBookmarkIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: 22, height: 13 }}
  />
);

const ICONS = {
  map: getIcon('ic_map'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  page: getIcon('ic_page'),
  user: getIcon('ic_user'),
  copy: getIcon('ic_copy'),
  error: getIcon('ic_error'),
  charts: getIcon('ic_charts'),
  editor: getIcon('ic_editor'),
  upload: getIcon('ic_upload'),
  animate: getIcon('ic_animate'),
  calendar: getIcon('ic_calendar'),
  elements: getIcon('ic_elements'),
  carousel: getIcon('ic_carousel'),
  language: getIcon('ic_language'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  components: getIcon('ic_components'),
  authenticator: getIcon('ic_authenticator'),
  settings: getIcon('ic_settings'),
  dataSetManagement: getIcon('ic_copy'),
  intergratedReports: getIcon('ic_page'),
  bookmark: getBookmarkIcon('ic_bookmark'),
  suppliers: getIcon('ic_editor'),
  events: getIcon('ic_calendar')
};

const getBookmarkedItems = (bookmarks) => {
  return bookmarks.map((bookmark) => {
    return { ...bookmark };
  });
};

function NavbarConfig(bookmarks, supplierEventsView) {
  const MenuLinks = [
    // DASHBOARD
    // ----------------------------------------------------------------------
    // {
    //   rolePrefix: 'insights_',
    //   items: [
    //     {
    //       title: 'dashboard',
    //       href: PATH_DASHBOARD.general.dashboard,
    //       icon: ICONS.dashboard,
    //       role: 'dashboard'
    //     }
    //   ]
    // },
    // REPORTS
    // ----------------------------------------------------------------------
    {
      //   subheader: 'insights',
      rolePrefix: 'insights_',
      items: [
        {
          title: 'Dashboard',
          href: PATH_DASHBOARD.general.dashboard.root,
          role: ROLE_DASHBOARD_VIEW
        },
        {
          title: 'Environment',
          href: PATH_DASHBOARD.general.environment.root,
          role: ROLE_ENVIRONMENT_VIEW
        },
        {
          title: 'Social',
          href: PATH_DASHBOARD.general.social.root,
          role: ROLE_SOCIAL_VIEW
        },
        {
          title: 'Governance',
          href: PATH_DASHBOARD.general.governance.root,
          role: ROLE_GOVERNANCE_VIEW
        },
        {
          title: 'Suppliers',
          href: PATH_DASHBOARD.general.suppliers.root,
          role: ROLE_SUPPLIER_VIEW
        },
        {
          title: 'Marketplace',
          href: PATH_DASHBOARD.general.marketplace.root,
          role: ROLE_MARKETPLACE_VIEW
        }
      ]
    },

    // MANAGEMENT CORE FUNCTIONALITIES
    // ----------------------------------------------------------------------
    {
      subheader: 'Management Studio',
      rolePrefix: 'configuration_',
      items: [
        {
          title: 'Users',
          href: PATH_DASHBOARD.management.users,
          icon: ICONS.user,
          role: ROLE_USERS
        },
        {
          title: 'Dashboards',
          href: PATH_DASHBOARD.management.reports,
          role: ROLE_REPORT_READ,
          icon: ICONS.components
        },
        {
          title: 'Data',
          href: PATH_DASHBOARD.management.dataSetManagement,
          icon: ICONS.dataSetManagement,
          role: ROLE_DATA_SETS_READ
        },
        {
          title: 'Events',
          href: PATH_DASHBOARD.management.events,
          icon: ICONS.events,
          role: ROLE_MANAGE_EVENTS
        }
      ]
    },

    // MANAGEMENT COMMS
    // ----------------------------------------------------------------------
    {
      subheader: 'Social Studio',
      rolePrefix: 'comms_',
      items: [
        {
          title: 'Content',
          href: PATH_DASHBOARD.management.content,
          icon: ICONS.page,
          role: ROLE_CONTENT
        },
        {
          title: 'Interest Groups',
          href: PATH_DASHBOARD.management.interestGroups,
          icon: ICONS.elements,
          role: ROLE_INTEREST_GROUPS
        },
        {
          title: 'Channels',
          href: PATH_DASHBOARD.management.communication,
          icon: ICONS.chat,
          role: ROLE_COMMUNICATION
        },
        // {
        //   title: 'Surveys',
        //   href: PATH_DASHBOARD.management.surveys,
        //   icon: ICONS.charts,
        //   role: ROLE_SURVEYS
        // },
        {
          title: 'Platform Settings',
          href: PATH_DASHBOARD.management.platformSettings,
          icon: ICONS.settings,
          role: ROLE_SETTINGS
        }
      ]
    },

    // MANAGEMENT BETA FEATURES
    // ----------------------------------------------------------------------
    {
      subheader: 'Beta',
      rolePrefix: 'beta_',
      items: [
        {
          title: 'Data',
          href: PATH_DASHBOARD.beta.dataSetManagement.root,
          icon: ICONS.dataSetManagement,
          role: ROLE_MANAGE_BETA
        }
      ]
    }
  ];

  return { MenuLinks };
}

export default NavbarConfig;
