import faker from 'faker';
import fakeRequest from '../utils/fakeRequest';
import { map, assign, reject } from 'lodash';
import { add, sub } from 'date-fns';
// utils
import mock from '../utils/mock';
import { mockImgCover } from '../utils/mockImages';
import { COLOR_OPTIONS } from '../../src/utils/constants';

// ----------------------------------------------------------------------

const getRandomFollowers = () =>
  faker.datatype.number({ min: 10, max: 49, precision: 1 });
const getRandomTrendingPosts = () =>
  faker.datatype.number({ min: 5, max: 12, precision: 1 });
const getRandomGeneratedIdeas = () =>
  faker.datatype.number({ min: 2, max: 10, precision: 1 });
const getRandomLikes = () =>
  faker.datatype.number({ min: 2, max: 10, precision: 1 });
const getRandomComments = () =>
  faker.datatype.number({ min: 2, max: 10, precision: 1 });

const INTEREST_GROUPS = [
  {
    id: 1,
    title: 'Physical and emotional wellbeing',
    description:
      'The effect my physical and mental health has on my wellbeing and performance.',
    coverImage:
      'https://media.experienz.co.uk/mitrainnovation/interest-groups/wellbeing.jpg',
    followers: getRandomFollowers(),
    likes: getRandomLikes(),
    trendingPosts: getRandomTrendingPosts(),
    ideasGenerated: getRandomGeneratedIdeas(),
    clientPillarId: 1
  },
  {
    id: 2,
    title: 'Financial wellbeing',
    description:
      'My personal finances as they stand today, and my ability to plan and control my future financial situation.',
    coverImage:
      'https://media.experienz.co.uk/mitrainnovation/interest-groups/financial.jpg',
    followers: getRandomFollowers(),
    likes: getRandomLikes(),
    trendingPosts: getRandomTrendingPosts(),
    ideasGenerated: getRandomGeneratedIdeas(),
    clientPillarId: 2
  },
  {
    id: 3,
    title: 'Environment and ways of working',
    description:
      'The surrounding in the place where I choose to work, and how I interact with others.',
    coverImage:
      'https://media.experienz.co.uk/mitrainnovation/interest-groups/environment.jpg',
    followers: getRandomFollowers(),
    likes: getRandomLikes(),
    trendingPosts: getRandomTrendingPosts(),
    ideasGenerated: getRandomGeneratedIdeas(),
    clientPillarId: 3
  },
  {
    id: 4,
    title: 'Technology',
    description:
      'The technology and communication channels which I use in my role.',
    coverImage:
      'https://media.experienz.co.uk/mitrainnovation/interest-groups/technology.jpg',
    followers: getRandomFollowers(),
    likes: getRandomLikes(),
    trendingPosts: getRandomTrendingPosts(),
    ideasGenerated: getRandomGeneratedIdeas(),
    clientPillarId: 4
  },
  {
    id: 5,
    title: 'Leadership and culture',
    description:
      'The climate that exists within the organization; how I am led and managed.',
    coverImage:
      'https://media.experienz.co.uk/mitrainnovation/interest-groups/leadership.jpg',
    followers: getRandomFollowers(),
    likes: getRandomLikes(),
    trendingPosts: getRandomTrendingPosts(),
    ideasGenerated: getRandomGeneratedIdeas(),
    clientPillarId: 5
  },
  {
    id: 6,
    title: 'Strategy and change management',
    description:
      'My organisation’s purpose, and how change is communicated to me.',
    coverImage:
      'https://media.experienz.co.uk/mitrainnovation/interest-groups/change.jpg',
    followers: getRandomFollowers(),
    likes: getRandomLikes(),
    trendingPosts: getRandomTrendingPosts(),
    ideasGenerated: getRandomGeneratedIdeas(),
    clientPillarId: 6
  },
  {
    id: 7,
    title: 'Career wellbeing',
    description:
      'My skills, my development and the career opportunities in my organization.',
    coverImage:
      'https://media.experienz.co.uk/mitrainnovation/interest-groups/career.jpg',
    followers: getRandomFollowers(),
    likes: getRandomLikes(),
    trendingPosts: getRandomTrendingPosts(),
    ideasGenerated: getRandomGeneratedIdeas(),
    clientPillarId: 7
  },
  {
    id: 8,
    title: 'Reward and benefits',
    description: 'My salary, my rewards and my total benefits package.',
    coverImage:
      'https://media.experienz.co.uk/mitrainnovation/interest-groups/benefits.jpg',
    followers: getRandomFollowers(),
    likes: getRandomLikes(),
    trendingPosts: getRandomTrendingPosts(),
    ideasGenerated: getRandomGeneratedIdeas(),
    clientPillarId: 8
  }
];

let interestGroups = {
  data: [...INTEREST_GROUPS]
};

let organizationalPillars = [...Array(8)].map((organizationalPillar, index) => {
  return {
    id: index + 1,
    name: faker.name.title(),
    description: faker.lorem.sentences(2),
    pillarId: faker.datatype.number()
  };
});

let topPosts = [...Array(5)].map((topPost, index) => {
  return {
    groupId: faker.datatype.number(),
    owner: 'Mahela',
    datePublished: faker.date.past(),
    id: faker.datatype.number(),
    likes: faker.datatype.number(10),
    comments: getRandomComments(),
    views: faker.datatype.number(),
    content: {
      title: faker.lorem.sentences(2),
      description: faker.lorem.paragraph(10),
      coverImage: mockImgCover(index + 1)
    }
  };
});

let reportedPosts = [...Array(15)].map((event, index) => {
  return {
    owner: 'Mahela',
    datePublished: faker.date.past(),
    id: faker.datatype.number(),
    likes: faker.datatype.number(10),
    comments: getRandomComments(),
    reason: 'Inappropriate Content',
    views: faker.datatype.number(),
    content: {
      title: faker.lorem.sentences(2),
      description: faker.lorem.paragraph(10),
      coverImage: mockImgCover(20 - index)
    }
  };
});

//--------------------------------------------------------------------------------

const EVENTS = [
  {
    id: 1,
    name: "Rusiru's Birthday Party",
    description: 'Happy Birthday Rusiru !!.',
    start: sub(new Date(), { days: 6, hours: 6, minutes: 30 }),
    endDate: sub(new Date(), { days: 6, hours: 4, minutes: 30 }),
    colour: COLOR_OPTIONS[2]
  },
  {
    id: 2,
    name: 'TownHall Event',
    description:
      'My organisation’s purpose, and how change is communicated to me.',
    colour: COLOR_OPTIONS[3],
    startDate: add(new Date(), { days: 4, hours: 6, minutes: 30 }),
    endDate: add(new Date(), { days: 4, hours: 4, minutes: 30 })
  },
  {
    id: 3,
    name: 'Accelarator Program',
    description:
      'My organisation’s purpose, and how change is communicated to me.',
    colour: COLOR_OPTIONS[5],
    startDate: add(new Date(), { days: 1, hours: 6, minutes: 30 }),
    endDate: add(new Date(), { days: 1, hours: 4, minutes: 30 })
  },
  {
    id: 4,
    name: 'Sinhala and Tamil New year',
    description:
      'My organisation’s purpose, and how change is communicated to me.',
    colour: COLOR_OPTIONS[3],
    startDate: sub(new Date(), { days: 1, hours: 6, minutes: 30 }),
    endDate: sub(new Date(), { days: 1, hours: 4, minutes: 30 })
  },
  {
    id: 5,
    name: 'Onboarding Session',
    description:
      'My organisation’s purpose, and how change is communicated to me.',
    startDate: add(new Date(), { days: 6, hours: 6, minutes: 30 }),
    endDate: add(new Date(), { days: 6, hours: 4, minutes: 30 }),
    colour: COLOR_OPTIONS[2]
  },
  {
    id: 6,
    name: 'Spiritual Wellbeing event',
    description:
      'My organisation’s purpose, and how change is communicated to me.',
    startDate: add(new Date(), { days: 9, hours: 6, minutes: 30 }),
    endDate: add(new Date(), { days: 7, hours: 4, minutes: 30 }),
    colour: COLOR_OPTIONS[6]
  }
];

//Events
let events = {
  data: [...EVENTS]
};

const REPORTING_REASONS = [
  {
    raisedBy: 'Brendon',
    remarks: 'False content,please improve the content'
  },
  {
    raisedBy: ' Jack',
    remarks: 'False content,please improve the content'
  },
  {
    raisedBy: 'Smith',
    remarks: 'False content,please improve the content'
  },
  {
    raisedBy: 'James',
    remarks: 'False content,please improve the content'
  },
  {
    raisedBy: 'Sam',
    remarks: 'False content,please improve the content'
  },
  {
    raisedBy: 'Rishi',
    remarks: 'Innappropriate content very bad '
  }
];

let reportingReasons = {
  data: [...REPORTING_REASONS]
};

//---------------------------------------------------------------------------------

mock.onGet('/api/interest-groups').reply(async (config) => {
  try {
    return [200, { ...interestGroups }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

//GET A SINGLE INTEREST GROUP
let id = 1;
mock.onGet(`/api/interest-groups/${id}`).reply(async (request) => {
  await fakeRequest(2000);
  try {
    let interestGroup = interestGroups.data.find((group) => group.id === id);

    if (!interestGroup) {
      return [404, { message: 'Interest Group not found' }];
    }
    return [200, { data: interestGroup }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

//GET TOP POSTS
mock.onGet(/^\/api\/interest-groups\/[^\/]+\/posts/).reply(async (request) => {
  await fakeRequest(1000);
  return [200, { data: [...topPosts] }];
});

//GET REPORTED POSTS
mock
  .onGet(/^\/api\/interest-groups\/[^\/]+\/reported-posts/)
  .reply(async (request) => {
    try {
      return [200, { data: [...reportedPosts] }];
    } catch (error) {
      return [500, { message: 'Internal server error' }];
    }
  });

mock
  .onDelete(/^\/api\/interest-groups\/reported-post\/[^\/]+/)
  .reply(async (request) => {
    await fakeRequest(1000);
    try {
      const postId = Number(request.url.split('/').slice(-1)[0]);
      reportedPosts = reject(reportedPosts, { id: postId });
      return [200, { ...postId }];
    } catch (error) {
      return [500, { message: 'Internal server error' }];
    }
  });

mock.onGet('/api/settings/pillars').reply(async () => {
  await fakeRequest(1000);
  return [200, { data: [...organizationalPillars] }];
});

mock.onPost('/api/interest-groups').reply(async (request) => {
  await fakeRequest(2000);
  return [200, { data: [] }];
});
//check
mock.onPut(`/api/interest-groups/${id}`).reply(async (request) => {
  await fakeRequest(2000);
  return [200, { data: [] }];
});

mock.onDelete(/^\/api\/interest-groups\/[^\/]+/).reply(async (request) => {
  await fakeRequest(1000);
  try {
    const interestGroupId = request.url.split('/').slice(-1)[0];
    interestGroups.data = reject(interestGroups.data, { id: interestGroupId });
    return [200, { interestGroupId }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

// ----------------------------------------------------------------------

mock.onGet(/^\/api\/interest-groups\/[^\/]+\/event/).reply(async (config) => {
  await fakeRequest(2000);
  try {
    return [200, { ...events }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

// ----------------------------------------------------------------------

mock.onPost(/^\/api\/interest-groups\/[^\/]+\/event/).reply((request) => {
  try {
    const { name, description, colour, startDate, endDate } = JSON.parse(
      request.data
    );

    const event = {
      id: faker.datatype.number(),
      name,
      description,
      colour,
      startDate: new Date(startDate),
      endDate: new Date(endDate)
    };

    events.data = [...events.data, event];
    return [200, { data: event }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

// ----------------------------------------------------------------------

mock.onPut(/^\/api\/interest-groups\/event\/[^\/]+/).reply((request) => {
  try {
    const eventId = Number(request.url.split('/').slice(-1)[0]);

    const { name, description, colour, startDate, endDate } = JSON.parse(
      request.data
    );
    const updatedEvent = {
      id: eventId,
      name,
      description,
      colour,
      startDate: new Date(startDate),
      endDate: new Date(endDate)
    };
    let event = null;
    events.data = map(events.data, (_event) => {
      if (_event.id === eventId) {
        assign(_event, { ...updatedEvent });
        event = _event;
      }
      return _event;
    });
    return [200, { data: event }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

// ----------------------------------------------------------------------

mock.onDelete(/^\/api\/interest-groups\/event\/[^\/]+/).reply((request) => {
  try {
    const Id = Number(request.url.split('/').slice(-1)[0]);
    let postId = parseInt(Id);
    events.data = reject(events.data, { id: postId });
    return [200, { postId }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock
  .onGet(/^\/api\/interest-groups\/reported-remarks\/[^\/]+/)
  .reply(async () => {
    try {
      return [200, { ...reportingReasons }];
    } catch (error) {
      console.error(error);
      return [500, { message: 'Internal server error' }];
    }
  });
